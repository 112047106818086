
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Points from './pages/Points'
import Patient from './pages/Patient'
import DoctorList from './pages/DoctorList';
import DoctorDetails from './pages/DoctorDetails'
import DoctorAllSlot from './pages/DoctorAllSlot'
import PatientList from './pages/PatientList'
import UpcomingAppointment from './pages/UpcomingAppointment'
import PatientDetails from './pages/PatientDetails'
import History from './pages/History'
import AllPatient from './pages/AllPatient'
import Excersice from './pages/Excersice'
import ExcersiceList from './pages/ExcersiceList'
import PersonalInformation from './pages/PersonalInformation'
import OperationalInformation from './pages/OperationalInformation'
import ContactInformation from './pages/ContactInformation'
import DoctorDetailsForm from './pages/DoctorDetailsForm'
import MyAppointment from './pages/MyAppointment'
import Settings from './pages/Settings'
import PatientContactDetails from './pages/PatientContactDetails'
import Chat from './pages/Chat';
import AppointSetting from './pages/AppointSetting';
import PatientContactInformation from './pages/PatientContactInformation';
import Foot from './pages/Foot';
import ApplicationSettingCalendar from './pages/ApplicationSettingCalendar';
import './assets/css/responisve.css';
import MyProfile from './pages/MyProfile/MyProfile';
import LoginPage from './pages/LoginPage/Login';
import Email from './pages/Login/Email';
import AddProfileData from './pages/AddProfileData/AddProfileData';
import ExerciseLab from './pages/ExerciseLib/ExerciseLab';
import CreateExercise from './pages/ExerciseLib/CreateExercise';
import WorkoutList from './pages/ExcersiceList/WorkoutList';
import ExerciseEdit from './pages/ExcersiceList/ExerciseEdit';
import SelectedExercise from './pages/ExerciseLib/SelectedExercise';
import SavedExercise from './pages/ExerciseLib/SavedExercise';
import PatientPoints from './pages/Points/PatientPoint';
import EditPatient from './pages/PersonalInformation/EditPatient';
import SurgeryTemplateList from './pages/Points/SurgeryTemplateList';
import Community from './pages/Community/Community';
import CommunityListing from './pages/Community/CommunityListing';
import CommunityCardDetails from './pages/Community/CommunityCardDetails';
import TermsCondition from './pages/TermsCondition';
import ExerciseForm from './pages/ExerciseForm';
import MyExercise from './pages/ExerciseLib/MyExercise';
import Payment from './pages/Payment';
import PaymentForm from './pages/PaymentForm';
import PaymentFormStripe from './pages/PaymentFormStripe';
import PaymentFormCredit from './pages/PaymentFormCredit';

import MyPlan from './pages/MyPlan';
import Activity from './pages/Activity/Activity';
import Home from './pages/Signup/SignupHome';
import SignupHomeComb from './pages/Signup/SignupHomeComb';
import ForgotPassword from './pages/LoginPage/ForgotPassword';
import Calendar from './pages/Calendar/CalendarView';
import { useEffect } from 'react';
import AppointmentPatient from './pages/UpcomingAppointment/AppointmentPatient';
import CustomizeCredits from './pages/CustomizeCredits';
import CustomizeCreditsStripe from './pages/CustomizeCreditsStripe';

import FilteredPatientList from './pages/AllPatient/FilteredPatientList';
import PaymentStripe from './pages/PaymentStripe';
import PaymentCompleted from './pages/PaymentCompleted';
import PaymentCompletedCredit from './pages/PaymentCompletedCredit';
import PaymentQuaterly from './pages/PaymentQuaterly';
import Integration from './pages/Integration';
import Support from './pages/Support';
import Ticket from './pages/Ticket';
import TicketReply from './pages/TicketReply';
import TicketDetail from './pages/TicketDetail';
function App() {
  // useEffect(() => {
  //   // Define the function to handle the context menu
  //   const handleRightClick = (event) => {
  //     event.preventDefault(); // Prevent the context menu from appearing
  //   };

  //   // Add event listener for right-click (contextmenu)
  //   document.addEventListener('contextmenu', handleRightClick);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     document.removeEventListener('contextmenu', handleRightClick);
  //   };
  // }, []); // Empty dependency array means this effect runs once on mount

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/signup' element={<SignupHomeComb />} />
        <Route path='/profileData' element={<AddProfileData /> } />
        <Route path='/login' element={<Home />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/patient' element={<Patient />} />
        <Route path='/points' element={<Points />} />
        <Route path='/surgeryTemplate' element={<SurgeryTemplateList />} />
        <Route path='/doctorList' element={<DoctorList />} />
        <Route path='/doctorDetails' element={<DoctorDetails/>} />
        <Route path='/doctorAllSlot' element={<DoctorAllSlot/>} />
        <Route path='/patientList' element={<PatientList/>}/>
        <Route path='/upcomingAppointment' element={<UpcomingAppointment/>}/>
        <Route path='/patientDetails' element={<PatientDetails/>} />
        <Route path='/patientpoints' element={<PatientPoints />} />
        <Route path='/history' element={<History/>}/>
        <Route path="/EditPatient" element={<EditPatient />} />
        <Route path='/allPatient' element={<FilteredPatientList/>}/>
        <Route path='/patientListing' element={<FilteredPatientList/>}/>
        <Route path='/excersice' element={<Excersice/>}/>
        <Route path='/excersiceList' element={<ExcersiceList />}/>
        <Route path='/workoutList' element={<WorkoutList />}/>
        <Route path="/exerciseEdit" element={<ExerciseEdit/>} />
        <Route path='/personalInformation' element={<PersonalInformation/>}/>
        <Route path='/operationalInformation' element={<OperationalInformation/>}/>
        <Route path='/contactInformation' element={<ContactInformation/>}/>
        <Route path='/doctorDetailsForm' element={<DoctorDetailsForm/>}/>
        <Route path='/myAppointment' element={<MyAppointment/>}/>
        <Route path='/settings' element={<Settings />}/>
        <Route path='/patientContactDetails' element={<PatientContactDetails />}/>
        <Route path='/chat' element={<Chat />}/>
        <Route path='/appointSetting' element={<AppointSetting />}/>
        <Route path='/patientContactInformation' element={<PatientContactInformation />}/>
        <Route path='/foot' element={<Foot />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/applicationSettingCalendar' element={<ApplicationSettingCalendar />} />
        <Route path='/exerciseLab' element={<ExerciseLab />} />
        <Route path='/createExercise' element={<CreateExercise />} />
        <Route path='/selectedExercise' element={<SelectedExercise />} />
        <Route path='/savedExercise' element={<SavedExercise />} />
        <Route path='/AddBlog' element={<Community /> } />
        <Route path='/community' element={<CommunityListing/>} />
        <Route path='/communityDetails' element={<CommunityCardDetails/>} />
        <Route path='/terms' element={<TermsCondition/>} />
        <Route path='/exerciseAdd' element={<ExerciseForm/>} />
        <Route path='/myExercise'  element={<MyExercise/>} />
        <Route path='/myPlanDetails' element={<MyPlan />} />
        <Route path='/payment'  element={<Payment/>} />
        <Route path='/paymentQuateryl'  element={<PaymentQuaterly/>} />
        <Route path='/paymentStripe'  element={<PaymentStripe/>} />
        <Route path='/paymentFormStripe'  element={<PaymentFormStripe/>} />
        <Route path='/paymentForm'  element={<PaymentForm/>} />
        <Route path='/paymentCompleted'  element={<PaymentCompleted/>} />
        <Route path='/paymentCompletedCredit'  element={<PaymentCompletedCredit/>} />
        <Route path='/creditPurchase'  element={<CustomizeCredits/>} />
        <Route path='/creditPurchaseStripe'  element={<CustomizeCreditsStripe/>} />
        <Route path='/activity'  element={<Activity/>} />
        <Route path='/paymentFormCredit'  element={<PaymentFormCredit/>} />
        <Route path='/appointmentCalendar'  element={<Calendar/>} />
        <Route path='/AppointmentPatient'  element={<AppointmentPatient/>} />
        <Route path='/api'  element={<Integration/>} />
        <Route path='/support'  element={<Support/>} />
        <Route path='/generate-ticket'  element={<Ticket/>} />
        <Route path='/ticket'  element={<TicketReply/>} />
        <Route path='/ticketDetail'  element={<TicketDetail/>} />
        {/* <Route path="*" element={<Navigate to="/dashboard" />}  /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
