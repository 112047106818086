import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Layout from "../layout";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import doneImg from "../assets/images/doneImg.png";
import Modal from "react-bootstrap/Modal";
const stripePromise = loadStripe(
  "pk_test_51PCKtqG8OCISy64iHClrCU03T9LHYmn4yepxb2W1woE6JqkD5Pfffz6L9aZu5zEPMRrdgeTm5GWRXDMsX129aQYZ00LZDbH5lo"
);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      padding: "16px",
      color: "#32325d",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#DC3545",
      iconColor: "#DC3545",
    },
  },
};
const CheckoutForm = () => {
  const user = Auth.getCurrentUser();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const nowTime = new Date();
  const date = nowTime.getTime();
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userData = {
        paymentDate: date,
        priceId: location?.state?.plan?.id,
        plans: location?.state?.plan,
        isPremiumUser: false,
        credits: 10,
        //tokenId: token.id, // Include the token ID in the user data
        email: user?.email,
        name: user?.name,
      };

      // Call your backend to create the checkout session
      const response = await post(
        "/create-checkout-session",
        userData,
        getAuthConfig()
      );
      if (response?.error) {
        setError(response?.error?.message);
      } else {
        // Redirect to the checkout page
        window.location.href = response?.data?.session?.url;
      }
    } catch (error) {
      setError(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };
  const [contactInformation, setContactInformation] = useState({
    emergencyNumber: "",
    address: "",
    state: "",
    city: "",
    country: "",
    pincode: "",
  });
  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  const [useResidenceAddress, setUseResidenceAddress] = useState(false);
  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const checkFormValidity = () => {
      const isBillingAddressComplete = useResidenceAddress
        ? data?.address?.street &&
          data?.address?.city &&
          data?.address?.state &&
          data?.address?.country &&
          data?.address?.pincode
        : contactInformation.street &&
          contactInformation.city &&
          contactInformation.state &&
          contactInformation.country &&
          contactInformation.pincode;
      setIsFormValid(isBillingAddressComplete);
    };

    checkFormValidity();
  }, [useResidenceAddress, contactInformation, data]);

  async function getCountrie() {
    if (countriesFetched) {
      return; // Countries have already been fetched
    }
    try {
      setCountryLoading(true);
      const data = await get("/country", getAuthConfig());
      setCountries(data?.data?.data);
      setCountriesFetched(true); // Update the state to indicate countries have been fetched
    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }

  async function handleCountryChange(event) {
    const countryCode = event.target.value;
    setCountry(countryCode);

    if (countryCode) {
      try {
        setStateLoading(true);
        const response = await get(
          `/state?countryCode=${countryCode}`,
          getAuthConfig()
        );
        setStates(response?.data?.data);
        setstate("");
        setContactInformation((p) => ({ ...p, state: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setStateLoading(false);
      }
    } else {
      setStates([]);
      setstate("");
    }
  }

  async function handleStateChange(event) {
    const stateCode = event.target.value;
    setstate(stateCode);

    if (country && stateCode) {
      try {
        setCityLoading(true);
        const response = await get(
          `/city?countryCode=${country}&stateCode=${stateCode}`,
          getAuthConfig()
        );
        setCities(response?.data?.data);
        setContactInformation((p) => ({ ...p, city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCities([]);
    }
  }

  return (
    <>
      <Layout>
        <div className="payment">
          <div className="text-center mt-5">
            <h5 className="title">Enter Payment Information</h5>
            <p>One more step before closing more revenue with RecureMe.</p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="card shadow m-4 w-75 bg-white border-0 p-3 mb-5">
              <form onSubmit={handleSubmit}>
                <div className="form-data p-2" style={{ maxWidth: "100%" }}>
                  <h5 className="text-start  py-3">Billing address</h5>

                  <Row className="g-4">
                    <Col lg="6">
                      <div className="filed">
                        <label>
                          Your Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          disabled
                          value={data?.name}
                        />
                      </div>
                    </Col>

                    <Col lg="12">
                      <div
                        className="w-100 d-flex align-items-center"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="checkbox"
                          id="useResidenceAddress"
                          checked={useResidenceAddress}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setUseResidenceAddress(isChecked);
                            if (isChecked) {
                              // Populate the contactInformation state with the residence address
                              setContactInformation({
                                ...contactInformation,
                                street: data?.address?.street || "",
                                city: data?.address?.city || "",
                                state: data?.address?.state || "",
                                country: data?.address?.country || "",
                                pincode: data?.address?.pincode || "",
                              });
                            } else {
                              // Clear the billing address fields
                              setContactInformation({
                                ...contactInformation,
                                street: "",
                                city: "",
                                state: "",
                                country: "",
                                pincode: "",
                              });
                            }
                          }}
                          style={{
                            marginRight: "8px",
                            alignSelf: "center",
                            transform: "scale(1.5)",
                            WebkitTransform: "scale(1.5)",
                          }}
                        />
                        <label
                          htmlFor="useResidenceAddress"
                          style={{ marginBottom: "0", alignSelf: "center" }}
                        >
                          Use Profile address as billing address
                        </label>
                      </div>
                    </Col>

                    <Col lg="12">
                      <div className="filed">
                        <label>
                          Address <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Address"
                          className="form-control"
                          value={contactInformation.street}
                          onChange={(e) =>
                            setContactInformation({
                              ...contactInformation,
                              street: e.target.value,
                            })
                          }
                          disabled={useResidenceAddress}
                        />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="filed">
                        <label>
                          Country <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control form-select  "
                          value={contactInformation?.country}
                          onChange={(e) => {
                            handleCountryChange(e);
                            const selectedCountry = countries.find(
                              (c) => c.isoCode === e.target.value
                            );
                            if (selectedCountry) {
                              setContactInformation((prevData) => ({
                                ...prevData,
                                country: selectedCountry.name,
                              }));
                            }
                          }}
                          onClick={(e) => {
                            getCountrie();
                          }}
                          disabled={useResidenceAddress}
                        >
                          <option value="">
                            {contactInformation?.country
                              ? contactInformation?.country
                              : "Select Country"}
                          </option>
                          {countryLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            countries.map((country) => (
                              <option
                                key={country?.code}
                                value={country?.isoCode}
                              >
                                {country?.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="filed">
                        <label>
                          State <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control form-select "
                          value={contactInformation?.state}
                          onChange={(e) => {
                            handleStateChange(e);
                            const selectedStates = states.find(
                              (c) => c.isoCode === e.target.value
                            );
                            if (selectedStates) {
                              setContactInformation((prevData) => ({
                                ...prevData,
                                state: selectedStates.name,
                              }));
                            }
                          }}
                          disabled={useResidenceAddress}
                        >
                          <option value="">
                            {contactInformation?.state
                              ? contactInformation?.state
                              : "Select State"}
                          </option>
                          {stateLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            states.map((state) => (
                              <option key={state?.code} value={state?.isoCode}>
                                {state?.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="filed">
                        <label>
                          City <span className="text-danger">*</span>
                        </label>

                        <select
                          className="form-control form-select"
                          value={contactInformation?.city}
                          onChange={(e) => {
                            setContactInformation((p) => ({
                              ...p,
                              city: e?.target?.value,
                            }));
                          }}
                          disabled={useResidenceAddress}
                        >
                          <option value="">
                            {contactInformation?.city
                              ? contactInformation?.city
                              : "Select City"}
                          </option>
                          {cityLoading ? (
                            <option disabled>Loading...</option>
                          ) : (
                            cities.map((city) => (
                              <option key={city?.code} value={city?.code}>
                                {city?.name}
                              </option>
                            ))
                          )}
                        </select>
                        {/* <GeoLocation
                locationTitle="City"
                onChange={(e) =>{
                  setContactInformation((p) =>({...p,city:e?.lable}))
                }}
                geoId={state}
              /> */}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="filed">
                        <label>
                          Pincode <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="input-field form-control"
                          placeholder="Pincode"
                          name="zip"
                          value={contactInformation?.pincode || ""}
                          onChange={(e) => {
                            setContactInformation((p) => ({
                              ...p,
                              pincode: e?.target?.value,
                            }));
                          }}
                          disabled={useResidenceAddress}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                {error && (
                  <div className="text-danger text-center mb-5">{error}</div>
                )}

                <div className="buttonPart mt-3 d-flex justify-content-center mb-5">
                  <button
                    className="theme-button text-white border-0"
                    disabled={!isFormValid || loading}
                  >
                    Pay
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteWorkout(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
              <img src={doneImg} height={350} width={350} alt="modal-video" />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Payment Done</p>

                <span>Thank you for Choosing us</span>

                <button className="btn btn-outline-secondary">
                  Order Id:{" "}
                </button>

                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
};

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default App;
