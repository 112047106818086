import React, { useEffect, useState } from "react";
import Layout from "../layout";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import Modal from "react-bootstrap/Modal";
import doneImg from "../assets/images/doneImg.png";
import portrait1 from "../assets/images/portrait1.png";
import doctorPayment from "../assets/images/jhnavi.png";
import doctorPayment1 from "../assets/images/gaytri.jpeg";
import doctorPayment2 from "../assets/images/hiral.jpg";
import Animation from "../Animation/Animation";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

function PaymentQuaterly() {
  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [subscribeId, setSubscribeId] = useState("");

  async function displayRazorpay(plan) {
    setLoading(true);  // Start loading before the script loads
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      setLoading(false);
      return;
    }

    const userData = { baseAmount: plan.price };
    try {
      const data = await post('/quaterly/api/create-order', userData, getAuthConfig());
      const result = data.data;

      if (!result.order) {
        alert('Failed to create order. Please try again.');
        return;
      }

      const options = {
        key: 'rzp_live_opK2gM7NYmZGRs', // Enter the Key ID generated from the Razorpay dashboard
        order_id: result.order.id,
        amount: result.order.amount,
        currency: result.order.currency,
        name: "RecureMe",
        description: "Subscription for Quaterly Plan",
        image: "https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/RecureMe.png",
        handler: async function (response) {
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
          // Call your backend to verify the payment and activate the subscription
          setSubscribeId(razorpay_payment_id);
          const verifyUrl = '/quaterly/api/verify-payment';
          const verifyData = {
            orderId: razorpay_order_id,
            paymentId: razorpay_payment_id,
            signature: razorpay_signature,
            patientCountToAdd: plan.patientCount,
            plan:plan.plan
          };
          const verifyResponse = await post(verifyUrl, verifyData, getAuthConfig());
          const verifyResult = verifyResponse?.data;
          if (verifyResult.valid) {
            setCompleteWorkout(true);
          } else {
            alert('Payment verification failed');
          }
        },
        prefill: {
          name: user?.name,
          email: user?.email,
          contact: user?.mobile_number
        },
        theme: {
          color: "#23bee3"
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Failed to process your request. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
        <div
          className="main-content-part foot-page p-0 "
          defaultActiveKey="first"
          style={{ backgroundColor: "#fff9f7a8" }}
        >
          <div className="container py-5">
            <div className="text-center">
              <h6
                className="m-3"
                style={{
                  color: "#FF4817",
                  textTransform: "uppercase",
                  fontSize: "16px",
                }}
              >
                our best features
              </h6>
              <h2 className="m-3">
                Choose Your Simple <br />
                Pricing Plan
              </h2>
              <span className="m-3">Choose a plan that’s right for you.</span>
            </div>
            <div className="row row-cols-1 row-cols-md-4 g-4 mt-1 d-flex justify-content-center ">
              <div className="col-md-4 col-lg-4 ">
                <div className="card cardFromPrice shadow-lg rounded">
                  <div className="card-body">
                    <h4 className="priceTitle">Core Mobility</h4>
                    <div className="d-flex align-items-center">
                      <h2 className="price">₹ 1799 </h2>
                      <h2 className="priceSub">&nbsp; / 3 Month</h2>
                    </div>
                    <hr />
                    <ul className="features list-unstyled">
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        <strong>15 Patient</strong>&nbsp; Access
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Web & Mobile App
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Browse a library of over &nbsp;
                        <strong>1,600 exercises</strong>
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Access more than &nbsp;
                        <strong>250 protocols</strong>
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Print and email workout plans (up to 50 times)
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Add patients to your panel
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Create your own exercises and workout protocols
                      </li>
                    </ul>
                    <div className="w-100 text-center">
                      <button
                        className="select-btn"
                        onClick={() => displayRazorpay({ price: 1799, patientCount: 15,plan:{
                            nickName:"Core Mobility",
                            amount:"1799",
                            currency:'inr'
                        } })}
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 ">
                <div className="card cardFromPrice shadow-lg rounded">
                  <div className="card-body">
                    <h4 className="priceTitle">Advanced Therapy</h4>
                    <div className="d-flex align-items-center">
                      <h2 className="price">₹ 4800 </h2>
                      <h2 className="priceSub">&nbsp; / 3 Month</h2>
                    </div>
                    <hr />
                    <ul className="features list-unstyled">
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        <strong>30 patient</strong>&nbsp; Access
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Web & Mobile App
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Browse a library of over &nbsp;
                        <strong>1,600 exercises</strong>
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Access more than &nbsp;
                        <strong>250 protocols</strong>
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Print and email workout plans (up to 50 times)
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Add patients to your panel
                      </li>
                      <li>
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                        Create your own exercises and workout protocols
                      </li>
                    </ul>
                    <div className="w-100 text-center">
                      <button
                        className="select-btn"
                        onClick={() => displayRazorpay({ price: 4800, patientCount: 30, 
                        
                        
                            plan:{
                                nickName:"Advanced Therapy",
                                amount:"4800",
                                currency:'inr'
                            } 


                        })}
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteWorkout(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
                <Animation />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingTop: "25px",
                }}
              >
                <p>Payment Done</p>
                <span>Thank you for Choosing us</span>
                <button className="btn btn-outline-secondary my-3">
                  Order Id : {subscribeId}
                </button>
                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
}

export default PaymentQuaterly;
