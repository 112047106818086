import React, { useEffect, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import { Row, Col } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import AppointmentCard from '../../components/AppointmentCard'
import AssignDoctor from '../../components/AssignDoctor'
import '../../assets/css/commonComponent.css';
import Auth from "../../libs/auth"
import { get, getAuthConfig } from '../../libs/http-hydrate'
import {UseEffectOnce} from "../../Hook/UseEffectOnce"
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import moment from 'moment';
import LikeComment from "../../assets/images/LikeCommunity.svg"
import LikeCommentFill from "../../assets/images/FillLikeDoctor.svg"
import CommunityImg from '../../assets/images/community.png'
import Comment from '../../assets/images/CommentCommunity.svg'
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
const Dashboard = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [day , setDay] = useState("");
  const [currentUser,setCurrentUser]= useState({});
  const [HomeData, setHomeData] = useState();
  const [loading , setLoading] = useState(false)
  const [communitiesList, setcommunitiesList] = useState({
    items: [],
    has_more: false
})
  useEffect(() =>{
    var today = new Date()
    var curHr = today.getHours()
    
    if (curHr < 12) {
     setDay('Good Morning')
    } else if (curHr < 18) {
      setDay('Good Afternoon')
    } else {
      setDay('Good Evening')
    }


    if(Auth.getCurrentUser())
    {
      setCurrentUser(Auth.getCurrentUser());
    }
  
    getHomeData();
  },[])




  async function getHomeData() {

    try {
      setLoading(true)
        const data = await get("/homeDoc", getAuthConfig());
        if (data?.status === 200) {
          setLoading(false)

            setHomeData(data?.data)
            setcommunitiesList((p) =>({...p,items:data?.data?.latestCommunity}))
        }
        else{
          setLoading(false)


        }


    } catch (error) {
      setLoading(false)

        if (error?.response?.status === 401) {
            Auth.logout();
            navigate("/login")

        }

    }
}  

async function likeCommunity(id, unlike) {
  try {
      let url;
      if (unlike) {
          url = `/likeCommunity?communityId=${id}&unlike=true`
      }
      else {
          url = `/likeCommunity?communityId=${id}`;
      }
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
          if (unlike) {
              const updatedItems = communitiesList?.items.map((item) => {
                  if (item?._id === id) {
                      return { ...item, likeByYou: false, likeCount: item?.likeCount - 1 }
                  }
                  return item
              })
              setcommunitiesList({ items: updatedItems })
          }
          else {
              const updatedItems = communitiesList?.items.map((item) => {
                  if (item?._id === id) {
                      return { ...item, likeByYou: true, likeCount: item?.likeCount + 1 }
                  }
                  return item
              })
              setcommunitiesList({ items: updatedItems })
          }

      }
  } catch (error) {
  }
}
  return (
    <Layout>
      { loading ?   <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div> : 
        <div className='main-home-part'>
          <div className='head-data d-flex'>
            <h6>{day} , </h6>
            <h5>&nbsp; {currentUser?.name}</h5>
          </div>
          <div className='slider-part'>
          <div className='slider-title-block'>
            <h5 className='slider-title'>Upcoming Appointment</h5>
            {HomeData?.Appointment && HomeData?.Appointment.length > 0  &&   <Link to="/upcomingAppointment">
              View All
            </Link> }
           
          </div>

          <div className='slider-item mt-1'>
            {HomeData?.Appointment  ?             <AppointmentCard  Appointment={HomeData?.Appointment} /> : "" }
          </div>

          </div>

          
          <div className='slider-part'>
          <div className='slider-title-block'>
            <h5 className='slider-title'>Community</h5>
            {communitiesList?.items && communitiesList?.items.length > 0  &&   <Link to={"/community"}>
              View All
            </Link> }
          </div>
          <div className='slider-item mt-4'>
          {/* {HomeData?.physiotherapist ?             <AssignDoctor physiotherapist={HomeData?.physiotherapist}/> : "" } */}

          <Row className='g-4'>
                                        {communitiesList?.items && communitiesList?.items === 0 && <div className='text-center'>No Blog Found</div>}
                                        {communitiesList?.items && communitiesList?.items?.map((item, index) => {
                                            return <>
                                                <Col xl="4" md="6" lg="12"  key={index}>
                                                    <div className='community-card'>
                                                        <div className='community-card-content'>
                                                            <div className='image-part d-flex justify-content-center align-content-center' style={{ cursor: "pointer" }} onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate("/communityDetails", { state: { comunity: item } })
                                                            }}>
                                                                {item?.community_image  && 
                                                                <img src={item?.community_image ? item?.community_image : CommunityImg} height={250} width={100} alt='CommunityImg' />
                                                                }
                                                                </div>
                                                            <div className='text-part'>
                                                                <div className='img-date-part' style={{ cursor: "pointer" }} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate("/communityDetails", { state: { comunity: item } })
                                                                }}>
                                                                    <div className='prodile-img-name'>
                                                                        <img src={item?.posted_By?.profile_image ? item?.posted_By?.profile_image : item?.posted_By?.gender === "female" ? DoctorFeMale : item?.posted_By?.gender === "male" ? DoctorMale : DoctorCommon} height={30} width={30} style={{borderRadius:"50%"}} />
                                                                        <p>{item?.posted_By?.name}</p>
                                                                    </div>
                                                                    <p className='gray'> {moment(item?.created_at).format('MMM DD YYYY')} </p>
                                                                </div>
                                                                <h6 style={{ cursor: "pointer" }} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate("/communityDetails", { state: { comunity: item } })
                                                                }}>{item?.title}</h6>
                                                                {!item?.community_image && (
    <>
        <p style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        }} dangerouslySetInnerHTML={{ 
            __html: item?.description?.split(/\s+/).slice(0, 50).join(' ') + (item?.description?.split(/\s+/).length > 50 ? '...' : '')
        }} />
        {/* {item?.description?.split(/\s+/).length > 50 && ( */}
            <span 
                style={{"cursor": "pointer", "color": "#FF6036", "fontWeight": "500" ,"fontSize":"12px"}}
                onClick={(e) => { 
                    e.preventDefault(); 
                    navigate("/communityDetails", { state: { comunity: item } }); 
                }}>
                Read More
            </span>
        {/* )} */}
    </>
)}
                                                                <div className='like-comment-data'>
                                                                    <div className='like-comment-text ms-1'>
                                                                        <p className='gray'>{item?.commentCount} Comments</p>
                                                                        <p className='gray ms-3' style={{ cursor: 'pointer' }}>{item?.likeCount} Likes</p>
                                                                    </div>
                                                                    <div className='like-commnet-icon'>
                                                                        {item?.likeByYou === true ? 

<img src={LikeCommentFill} alt='chat' onClick={(e) => {
    e.preventDefault();
    likeCommunity(item?._id, "unlike")
}} />
                                                                            :
                                                                            <img src={LikeComment} alt='chat' onClick={(e) => {
                                                                                e.preventDefault();
                                                                                likeCommunity(item?._id)
                                                                            }} />}
                                                                        <img src={Comment} alt='chat' className='ms-2' style={{cursor:"pointer"}}  onClick={(e) => { 
                    e.preventDefault(); 
                    navigate("/communityDetails", { state: { comunity: item } }); 
                }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </>
                                        })}
                                    </Row>
                      </div>
          </div>
          
        </div> }
    </Layout>
  )
}

export default Dashboard
