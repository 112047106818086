import React from 'react'
import '../assets/css/commonComponent.css'
import Patient from '../assets/images/patient.png'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import moment from "moment";
import DoctorDummy from "../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../assets/images/defaultpatient@3x.png"
import DoctorCommon from "../assets/images/Docotr.png";
import DoctorMale from "../assets/images/Doctor Male.png";
import DoctorFeMale from "../assets/images/Doctor Female.png";
import PatientCommon from "../assets/images/Patint.png";
import PatientFemale from "../assets/images/Patient Female.png";
import PatientMale from "../assets/images/Patient male.png";
const options = {
    loop: false,
    nav: false,
    dots: false,
    margin: 0,
    items:4,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1.5,
        },
        700: {
            items: 1.5,
        },
        1000: {
            items: 2.2,
        },
        1366: {
            items: 3.5,
        },
        1520: {
            items: 4.5,
        }
    },
}
const AppointmentCard = (props) => {
    return (
        <>
            {props?.Appointment && props?.Appointment.length <= 0 && 
                <div className='head-data'>
                    <h6 style={{fontSize:"14px"}}>No Appointment For the day</h6>
                </div> 
            }
            <div className='' key={props?._id}>
                    <OwlCarousel {...options}
                        className="owl-theme">

                        {props?.Appointment && props?.Appointment.length > 0 &&
                            props?.Appointment.map((item, index) => {

                                const istTimestamp = moment(item?.patient_id?.added_date).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss');

                                return (

                                    <>
                                    <div key={index} className={`appointment-card ${!item?.timeSlot ? "fromAdd"  : ""}`} >
                                    <Link to={"/patientDetails"} state={{patient:item?.patient_id}}>

                                            <div className='doctor-card-content'>
                                                <div className='img-time-partr text-center'>
                                                <div className='time-part d-flex justify-content-end'>

                                                <p className="mb-0" style={{fontSize:"12px",fontWeight:"500"}}> { item?.timeSlot ? moment(item?.timeSlot,"HH:mm:ss").format("h:mm A") : moment(istTimestamp).format('h:mm A') } </p>
                                                </div>

                                                    <div className='image-part d-flex justify-content-center'>
                                                        <img src={item?.patient_id?.profile_image ? item?.patient_id?.profile_image : item?.patient_id?.gender === "female" ? PatientFemale :  item?.patient_id?.gender === "male" ? PatientMale : PatientCommon} height={"100px"} width={"100px"} className='appPatient' style={{borderRadius:"50%"}} alt='Patient' />
                                                    </div>
                                                    {/* <div className='time-part'> */}
                                                        {/* <p className='gray'> {moment(item?.date).format("MMM DD")}</p> */}
                                                    {/* </div> */}
                                                </div>
                                                <div className='text-part text-center'>
                                                    <h5 className='name'>{item?.patient_id?.name}</h5>
                                                    <h5 className='name'></h5>
                                                    <h6>{item?.appoinmentFor ? item?.appoinmentFor : item?.patient_id?.inguredBodyPart }</h6>
                                                    <p className='date mb-0'>{moment(item?.date).format("DD MMM YYYY")}</p>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>

                                    </>
                                )
                            })}


                    </OwlCarousel>

                

            </div>
        </>
    )
}

export default AppointmentCard