import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Alert, Button } from "react-bootstrap";
import Layout from "../layout";
import TopBarNavigation from "../components/TopBarNavigation";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../assets/css/ticket.css";

function Support() {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openTicketsVisible, setOpenTicketsVisible] = useState(true); // State to toggle open tickets section
  const [closedTicketsVisible, setClosedTicketsVisible] = useState(true); // State to toggle closed tickets section
  const navigate = useNavigate();

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await get(`/tickets`, getAuthConfig());
      setTickets(response.data.tickets);
    } catch (err) {
      setError(err.response?.data?.error || "Error fetching tickets");
    } finally {
      setLoading(false);
    }
  };

  const toggleClosedTickets = () => {
    setClosedTicketsVisible(!closedTicketsVisible);
  };

  const openTickets = tickets.filter((ticket) => ticket.status === "open");
  const closedTickets = tickets.filter((ticket) => ticket.status === "closed");

  return (
    <>
      <Layout>
        <TopBarNavigation
          title={"Support"}
          tab={"Tickets"}
          tabChild={"Support"}
        />
        <div className="main-content-part container mt-4 mb-5 w-75">
         
        {loading ? (
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            ) : tickets.length === 0 ? (
          <div className="text-center mt-5">
            <p>There is no ticket generated.</p>
            <p>If you have any queries then generate a ticket.</p>
            <Button
              className="theme-button mt-3"
              style={{ backgroundColor: "#FF6347", border: "none" }}
              onClick={() => navigate("/generate-ticket")}
            >
              Generate Ticket
            </Button>
          </div>
        ) :    <>
      
          {/* Open Tickets Section */}
          <div className="d-flex justify-content-between align-items-center">
          <label className="">
            Open Tickets ({openTickets.length})
          </label>
          <Button
                className="theme-outline-btn p-2 "
                // style={{ backgroundColor: "#FF6347", border: "none" }}
                onClick={() => navigate("/generate-ticket")}
              >
                + Raise New Ticket
              </Button>
          </div>
         
          <div className="session-card-content m-4">
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            ) : error ? (
              <Alert variant="danger">{error}</Alert>
            ) : (
              <>
                {openTickets.length <= 0 && (
                  <div className="text-center">No Open Tickets Found</div>
                )}

                {openTickets.map((ticket) => (
                  <div
                    key={ticket._id}
                    className="ticket-card card mb-3"
                    onClick={() =>
                      navigate("/ticketDetail", { state: { ticket: ticket } })
                    }
                    style={{
                      border: "1px solid #E5E5E5",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="card-body">
                      <Row>
                        <Col xs={9}>
                          <h6 className="ticket-title">
                            {ticket.title}{" "}
                            <span className="dot">•</span>{" "}
                            {ticket.issueType}
                          </h6>
                          <small>
                            Ticket # {ticket._id} • Raised{" "}
                            {moment(ticket.createdAt).fromNow()}
                          </small>
                          <p className="ticket-description mt-2">
                            {ticket.description}
                          </p>
                        </Col>
                        <Col xs={3} className="text-end">
                          <span
                            className="badge"
                            style={{
                              backgroundColor:
                                ticket.status === "open"
                                  ? "#28A745"
                                  : "#FF6347",
                              color: "white",
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                          >
                            {ticket.status === "open"
                              ? "IN PROGRESS"
                              : "CLOSED"}
                          </span>
                        </Col>
                      </Row>
                      {/* <hr />
                      <Row>
                        <Col xs={12} className="mt-3">
                          <span
                            style={{
                              color: "#FF6347",
                              cursor: "pointer",
                            }}
                          >
                            Call is requested on this ticket.{" "}
                            <u>View Details</u>
                          </span>
                        </Col>
                      </Row> */}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          {/* Closed Tickets Section */}
          <label
            htmlFor="closed-tickets"
            className="toggle"
            onClick={toggleClosedTickets}
          >
            {closedTicketsVisible ? (
              <>
                Closed Tickets ({closedTickets.length}) <i className="fa fa-chevron-up"></i>
              </>
            ) : (
              <>
                Closed Tickets ({closedTickets.length}) <i className="fa fa-chevron-down"></i>
              </>
            )}
          </label>
          <input
            type="checkbox"
            name="closed-tickets"
            id="closed-tickets"
            className="hide-input"
            checked={closedTicketsVisible}
            readOnly
          />
          <div className="toggle-el">
              <div className="session-card-content m-4">
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : error ? (
                  <Alert variant="danger">{error}</Alert>
                ) : (
                  <>
                    {closedTickets.length <= 0 && (
                      <div className="text-center">No Closed Tickets Found</div>
                    )}

                    {closedTickets.map((ticket) => (
                      <div
                        key={ticket._id}
                        className="ticket-card card mb-3"
                        onClick={() =>
                          navigate("/ticket", { state: { ticket: ticket } })
                        }
                        style={{
                          border: "1px solid #E5E5E5",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="card-body">
                          <Row>
                            <Col xs={9}>
                              <h6 className="ticket-title">
                                {ticket.title}{" "}
                                <span className="dot">•</span>{" "}
                                {ticket.issueType}
                              </h6>
                              <small>
                                Ticket # {ticket._id} • Raised{" "}
                                {moment(ticket.createdAt).fromNow()}
                              </small>
                              <p className="ticket-description mt-2">
                                {ticket.description}
                              </p>
                            </Col>
                            <Col xs={3} className="text-end">
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: "#FF6347",
                                  color: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                }}
                              >
                                CLOSED
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
          </div>
          </>}
        </div>
      </Layout>
    </>
  );
}

export default Support;
