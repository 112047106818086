import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import UpcomingPatientsCard from "../../components/UpcomingPatientsCard";
import { Row, Col, Button, ProgressBar, Tabs, Tab } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/images/search.svg";
import FilterIcon from "../../assets/images/filter.svg";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import moment from "moment";
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png";
import PatientDummy from "../../assets/images/defaultpatient@3x.png";
import checkIcon from "../../assets/images/check (1).png";
import Modal from "react-bootstrap/Modal";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const FilteredPatientList = () => {
  const user = Auth.getCurrentUser();
  const [key, setKey] = useState("history");
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState({
    items: [],
    has_more: false,
    totalItems: 0,
  });
  const [IntialpatientList, setIntialPatientList] = useState({
    items: [],
  });
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    searchTerm: "",
    title: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);
  const [loading, setLoading] = useState(true);

  const [bodyPartData, setBodyPart] = useState({
    has_more: false,
    items: [
      {
        title: "Today",
      },
      {
        title: "Upcoming",
      },
      {
        title: "Monthly",
      },
      {
        title: "Weekly",
      },
    ],
  });

  useEffect(() => {
    if (key) {
      getPhysiotherapistLit();
    }
  }, [key, page, filter]);

  async function getPhysiotherapistLit() {
    try {
      setLoading(true);

      let queryParams = `pageNumber=${page}`;

      if (filter?.searchTerm) {
        queryParams += `&searchTerm=${filter?.searchTerm}`;
      }
      if (filter?.title) {
        queryParams += `&appointmentType=${filter?.title.toLowerCase()}`;
      }
      if (key) {
        queryParams += `&type=${key}`;
      }
      const data = await get(
        `/patientListFiltered?${queryParams}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setPatientList((p) => ({
          ...p,
          items: data?.data?.data?.data,
          has_more: data?.data?.data?.has_more,
          totalItems: data?.data?.data?.totalItems,
        }));

        setIntialPatientList((p) =>({
          ...p,
          items: data?.data?.data?.data,
        }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  }

  const representativeBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2 activity">
        {/* <button className="theme-outline-btn btn btn-primary"> */}
        {rowData?.lastAppointmentFor
          ? rowData?.lastAppointmentFor
          : rowData?.inguredBodyPart}
        {/* </button> */}
      </div>
    );
  };


  const representativePaymentBy = (rowData) => {
    return (
      <div className="flex align-items-center gap-2 activity">
        {rowData?.transactionType}
      </div>
    );
  };
  const representativeCreated_At = (rowData) => {
    const istTimestamp = moment(rowData?.created_at)
      .utcOffset("+05:30")
      .format("YYYY-MM-DD HH:mm:ss");
    return (
     
      <div className="flex align-items-center gap-2 activity">
          {moment(istTimestamp).format("h:mm A")}{" "}
          <span> , {moment(rowData?.created_at).format("MMM DD , YYYY")}</span>
</div>    );
  };

  const representativeAppointment = (rowData) => {
    return (
      <span className="">
        {" "}
        <p>
          {moment(rowData?.lastDate).format("h:mm A")}{" "}
          <span> , {moment(rowData?.lastDate).format("MMM DD , YYYY")}</span>
        </p>
      </span>
    );
  };

  const representativeProgressPercentage = (rowData) => {
    return (
      <div className="d-flex align-items-center mt-2">
        <p className="w-100">
          {" "}
          <ProgressBar now={rowData?.progressPercentage} />
        </p>{" "}
        &nbsp; <p>{rowData?.progressPercentage?.toFixed(2)}% </p>
      </div>
    );
  };
  const onPage = (event) => {
    // PrimeReact pages are zero-based; add 1 to match your backend API expectations
    const newPage = event.page;

    setlazyState((prevState) => ({
      ...prevState,
      first: event.first,
      rows: event.rows,
      page: newPage,
    }));

    // Fetch the new page of data using the updated lazyState
    setPage(newPage);
  };

  const patientNameBodyTemplate = (rowData) => {
    return (
      <span>
        <img
          src={
            rowData?.profile_image
              ? rowData?.profile_image
              : rowData?.gender === "female"
              ? PatientFemale
              : rowData?.gender === "male"
              ? PatientMale
              : PatientCommon
          }
          height={40}
          width={40}
          alt="Patient"
          className="rounded-circle"
        />
        &nbsp; {rowData.name}
      </span>
    );
  };

  const onSort = (event) => {
    const { sortField, sortOrder } = event;

    const sortedItems = [...patientList.items].sort((data1, data2) => {
      let value1 = data1[sortField];
      let value2 = data2[sortField];
      let result = 0;

      if (typeof value1 === "string" && typeof value2 === "string") {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return sortOrder * result;
    });

    setPatientList((prevState) => ({
      ...prevState,
      items: sortedItems,
    }));
    setlazyState((prevState) => ({
      ...prevState,
      sortField,
      sortOrder,
    }));
    if(sortField === null){
      setPatientList((prevState) => ({
        ...prevState,
        items: IntialpatientList?.items,
      }));
    }
  };
  console.log(lazyState,"lazyState")

  return (
    <Layout>
      <TopBar title={"All Patient"} tab={"Patient"} tabChild={"All Patient"} />
      <div className="main-content-part">
        <div className="d-flex justify-content-between">
          <div className="serchPart">
            <div className="searchInput w-100 mb-0">
              <div className="searchIcon" style={{ top: "6px", left: "10px" }}>
                <img src={SearchIcon} alt="searchIcon" height={17} width={17} />
              </div>
              <input
                type="text"
                placeholder="Search patient"
                value={filter?.searchTerm}
                style={{
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  fontSize: "14px",
                  paddingLeft: "35px",
                }}
                onChange={(e) => {
                  e.preventDefault();
                  setFilter((p) => ({ ...p, searchTerm: e?.target?.value }));
                }}
              />
              {/* <div
                className="filterIcon"
                onClick={(e) => {
                  e.preventDefault();
                  handleShowFilter();
                }}
                style={{top:"7px",right:"10px"}}
              >
                <img src={FilterIcon} alt="filterIcon"  height={20} width={20}/>
              </div> */}
            </div>
          </div>
          <div className="exercise-tab-part">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => {
                setlazyState((p) => ({ ...p, first: 0, page: 1 })); // Update lazyState page to 1
                setPage(0);
                setKey(k);
              }}
              className="mb-3 mt-0  d-flex justify-content-center"
            >
              <Tab eventKey="history" title="Workout History"></Tab>
              <Tab eventKey="log" title="Registered Patients"></Tab>
              <Tab eventKey="appoinment" title="Scheduled Appointment"></Tab>
              <Tab eventKey="shared" title="Shared Patients"></Tab>
            </Tabs>
          </div>
        </div>
        <div className="shadow doctor-card  bg-body border-0 bg-white p-3">
          <DataTable
            value={patientList.items}
            paginator
            lazy
            first={lazyState.first}
            onPage={onPage}
            onSort={onSort}
            removableSort
            sortField={lazyState.sortField}
            sortOrder={lazyState.sortOrder}
            rows={10}
            totalRecords={patientList.totalItems}
            loading={loading}
            onRowClick={(e) => {
              navigate("/patientDetails" ,{ state: { patient: e.data } })
              //setCurrentActivity(e.data);
              //setshowHistoryModal(true);
            }}
            rowClassName={() => "datatable-row"}
          >
            <Column
              field="name"
              header="Patient Name"
              sortable
              sortField="name"
              body={patientNameBodyTemplate}
            ></Column>
            <Column
              field="created_at"
              header="Date Log"
              sortable
              sortField="created_at"
              body={representativeCreated_At}
            ></Column>
            {key === "appoinment" && (
              <Column
                field="lastDate"
                header="Appointment"
                sortable
                sortField="lastDate"
                body={representativeAppointment}
              ></Column>
            )}
            <Column
              field="inguredBodyPart"
              header="Body Part"
              sortField="inguredBodyPart"
              sortable
              body={representativeBodyTemplate}
            ></Column>
            {key === "history" && (
              <Column
                field="transactionType"
                header="Payment By"
                sortable
                sortField="transactionType"
                body={representativePaymentBy}
              ></Column>
            )}
            <Column
              field="progressPercentage"
              header="Progress"
              sortField="progressPercentage"
              style={{ width: '20%' }}
              body={representativeProgressPercentage}
            ></Column>
          </DataTable>
        </div>
      </div>

      <Modal show={showFilter} onHide={handleCloseFilter} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="body-content">
            <ul className="filterList">
              {bodyPartData?.items &&
                bodyPartData?.items.length > 0 &&
                bodyPartData.items.map((item, index) => (
                  <li
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        title: item?.title,
                      }));
                    }}
                  >
                    <a>
                      {item?.title}
                      {filter?.title === item?.title && (
                        <div className="select-img">
                          <img
                            src={checkIcon}
                            alt="checkIcon"
                            style={{ display: "block" }}
                          />
                        </div>
                      )}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="theme-button"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFilter();
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default FilteredPatientList;
