import { useState, React, useEffect } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import "../../assets/css/doctorInfo.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Ex1 from "../../assets/images/ex-1.png";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getAuthConfig,
  getAuthConfigImage,
  post,
  put,
} from "../../libs/http-hydrate";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import UploadIcon from "../../assets/images/upload.svg";
import doneImg from "../../assets/images/doneImg.png";
import addMore from "../../assets/images/addMore.png";
import Auth from "../../libs/auth";
import { Col, Row } from "react-bootstrap";
import FieldValidationError from "../../common/FieldValidation";
import Credit from "../../components/Credit";
import Animation from "../../Animation/Animation";

const ExcersiceList = () => {
  const user = Auth.getCurrentUser();
  const [credit, setCredit] = useState(0);
  const [paymentType,setPaymentType] = useState({
    payment:"",
    paymentTypeError:false
  })
  const [startDate, setStartDate] = useState("");
  const [days, setDays] = useState("");
  const [months, setMonths] = useState(""); // Default to 7 as per your given code
  const [note, setNote] = useState("");
  const [validationError, setValidationError] = useState({
    date: false,
    month: false,
  });
  const [startDateError, setStartDateError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [ExerciseData, setExrerciseeData] = useState();
  const [updateData, setUpdateData] = useState();
  const [showNote, setShowNote] = useState(false);
  const handleCloseNote = () => setShowNote(false);
  const handleShowNote = () => setShowNote(true);
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [updating, setupdating] = useState(false);

  const [Addmore, setAddMore] = useState(false);
  const defaultActiveTab = () => {
    if (ExerciseData?.morning?.length > 0) {
      return "morning";
    } else if (ExerciseData?.afternoon?.length > 0) {
      return "afternoon";
    } else if (ExerciseData?.evening?.length > 0) {
      return "evening";
    } else if (ExerciseData?.night?.length > 0) {
      return "night";
    } else {
      // If none of the sessions have data, set a default tab
      return "morning"; // or "afternoon", "evening", "night" as desired
    }
  };
  const [activeTab, setActiveTab] = useState(defaultActiveTab());
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };
  useEffect(() => {
    setActiveTab(defaultActiveTab());
  }, [ExerciseData]);

  useEffect(() => {
    if (location?.state?.workout) {
      setExrerciseeData(location?.state?.workout);
    }
  }, [location?.state]);
  const [key, setKey] = useState("morning");
  async function saveWorkout() {
    if (!validateDateAndMonths()) {
      // If validation fails, stop execution
      return;
    }

    if(location?.state?.patientDetail?.isAssignedExercise === false){
      if(!paymentType?.payment)
      {
        setPaymentType((p)=>({...p,paymentTypeError:true}))
        return false;
      }
    }

    if(location?.state?.patientDetail?.isAssignedExercise === true && location?.state?.patientDetail?.workoutGivenBy != user?._id )
    {
      if (!paymentType?.payment) {
        setPaymentType((p) => ({ ...p, paymentTypeError: true }));
        return false;
      }
    }
   
    // Prepare ExerciseData with startDate and totalDays
    prepareExerciseDataWithDates();
    try {
      setupdating(true);
      // Create a new FormData object
      const formData = new FormData();

      function appendToFormData(formData, obj, prefix = "") {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          // Skip undefined values and empty strings
          if (value === undefined || value === "") return;

          const formKey = prefix ? `${prefix}[${key}]` : key;

          if (value instanceof File) {
            formData.append(formKey, value);
          } else if (Array.isArray(value)) {
            if (value.length === 0) {
              // Optionally handle empty arrays differently, if needed
              return;
            } else {
              value.forEach((item, index) => {
                appendToFormData(formData, item, `${formKey}[${index}]`);
              });
            }
          } else if (typeof value === "object" && value !== null) {
            if (Object.keys(value).length === 0) return; // Skip empty objects
            appendToFormData(formData, value, formKey);
          } else {
            formData.append(formKey, value);
          }
        });
      }

      appendToFormData(formData, ExerciseData);

      // Add the isRenewWorkout property if it exists in location.state
      formData.append("isRenewWorkout", location?.state?.isRenew || false);
      formData.append("report", ExerciseData?.report);
      // A helper function to update notes in a given session array
      const updateNotes = (session) =>
        session.map((item) => ({ ...item, note: note }));

      // Update notes for all sessions
      const updatedMorning = ExerciseData.morning
        ? updateNotes(ExerciseData.morning)
        : [];
      const updatedAfternoon = ExerciseData.afternoon
        ? updateNotes(ExerciseData.afternoon)
        : [];
      const updatedEvening = ExerciseData.evening
        ? updateNotes(ExerciseData.evening)
        : [];
      const updatedNight = ExerciseData.night
        ? updateNotes(ExerciseData.night)
        : [];

      const response = await post(
        "/workout",
        {
          morning: updatedMorning,
          afternoon: updatedAfternoon,
          evening: updatedEvening,
          night: updatedNight,
          patient_id: ExerciseData?.patient_id,
          note: ExerciseData?.note,
          isRenewWorkout: location?.state?.isRenew || false,
          paymentType: paymentType?.payment
        },
        getAuthConfig()
      )
        .then((data) => {
          if (data?.status === 200) {
            setShowNote(false);
            setCompleteWorkout(true);
            setupdating(false);
          }
        })
        .catch((err) => {
          setupdating(false);

          toast.error(err?.response?.data?.err);
        });
    } catch (error) {
      setupdating(false);

      toast.error(error);
    }
  }
  async function updateWorkout() {
    try {
      const updateData = {
        workoutId: location?.state?.workoutId,
        session: location?.state?.session,
        workoutItemId: location?.state?.workoutItemId,
        workoutUpdateObject:
          location?.state?.workout?.[location?.state?.session][0],
      };

      setupdating(true);

      const response = await post(
        "/update-workout",
        updateData,
        getAuthConfig()
      )
        .then((data) => {
          setCompleteWorkout(true);
          // toast.success("Patient workout updated successfully");

          // setTimeout(() => {
          //     setupdating(false)

          //   navigate("/allPatient", { state: { patient_id: location?.state?.patient_id , patientDetail:location?.state?.patientDetail } })

          // }, 2000);
        })
        .catch((err) => {
          setupdating(false);

          toast.error(err?.response?.data?.err);
        });
    } catch (error) {
      setupdating(false);
      toast.error(error);
    }
  }

  const validateDateAndMonths = () => {
    const inputDate = new Date(startDate);
    const today = new Date(getTodayDate());
    today.setHours(0, 0, 0, 0); // Normalize today's date to the start of the day for comparison

    if (!startDate) {
      setValidationError((prev) => ({ ...prev, date: true }));
      return false;
    } else if (inputDate < today) {
      setStartDateError("Start date cannot be before today's date.");
      return false;
    }

    if (!months && !days) {
      setValidationError((prev) => ({ ...prev, month: true }));
      return false;
    }

    // Clear any previous errors if validation passes
    setValidationError({ date: false, month: false });
    setStartDateError("");
    return true;
  };
  const prepareExerciseDataWithDates = () => {
    // Calculate totalDays once here to avoid recalculating it for each session
    const totalDays = calculateTotalDays();

    // Iterate over each session in ExerciseData and add/update startDate and daysToComplete
    const sessions = ["morning", "afternoon", "evening", "night"];
    sessions.forEach((session) => {
      if (ExerciseData[session] && ExerciseData[session].length > 0) {
        ExerciseData[session].forEach((workoutSession) => {
          workoutSession.startDate = startDate;
          workoutSession.daysToComplete = totalDays;
        });
      }
    });
  };

  const calculateTotalDays = () => {
    // Parse days and months, defaulting days to 0 if not provided or selected as 0
    const parsedDays = parseInt(days, 10) || 0;
    const parsedMonths = parseInt(months, 10) || 0;

    // Start with calculating total days based on months, assuming 30 days per month
    let totalDays = parsedDays + parsedMonths * 30;

    // Special handling for February
    if (parsedMonths === 2) {
      // If days is more than 15 in February, adjust totalDays considering February typically has 28 days
      // Since we are adding days separately, we don't add them again for February
      totalDays = (parsedDays > 15 ? 28 : parsedDays) + (parsedMonths - 1) * 30;
    }

    return totalDays;
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    // Month is 0-indexed in JavaScript, so add 1; ensure it's two digits with .padStart()
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (e) => {
    // Clear previous error message
    setStartDateError("");
    setValidationError((p) => ({ ...p, date: false }));
    setStartDate(e.target.value);
  };

  const handleDaysChange = (e) => {
    setDays(e.target.value);
    setValidationError((p) => ({ ...p, month: false }));
  };

  const handleMonthsChange = (e) => {
    setMonths(e.target.value);
    setValidationError((p) => ({ ...p, month: false }));
  };
  return (
    <>
      <ToastContainer />
      <Layout>
        <TopBar
          title={"Exercises List"}
          tab={"Add Exercises"}
          tabChild={"Exercises List"}
        />{" "}
        <div className="main-content-part excersiceList">
          <div className="exercise-tab-part">
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={handleTabSelect}
              className="mb-3"
            >
              {ExerciseData?.morning.length > 0 && (
                <Tab eventKey="morning" title="Morning">
                  <div className="exercise-content">
                    {ExerciseData?.morning &&
                      ExerciseData?.morning.length <= 0 && (
                        <div className="text-centet"> No Exercise</div>
                      )}

                    {ExerciseData?.morning.map((session) =>
                      session.workout.map((exercise, index) => (
                        <div className="exercise-card mb-3">
                          <div className="img-text-part">
                            <div className="img-block">
                              <img
                                src={exercise?.exerciseImage}
                                height={60}
                                width={70}
                                alt="ex-1"
                              />
                            </div>
                            <div className="text-part ps-3">
                              <h6>{exercise?.title}</h6>
                              <p>{Number(exercise?.set)} Reps</p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Tab>
              )}

              {ExerciseData?.afternoon.length > 0 && (
                <Tab eventKey="afternoon" title="Afternoon">
                  <div className="exercise-content">
                    {ExerciseData?.afternoon &&
                      ExerciseData?.afternoon.length <= 0 && (
                        <div className="text-centet"> No Exercise</div>
                      )}

                    {ExerciseData?.afternoon.map((session) =>
                      session.workout.map((exercise, index) => (
                        <div className="exercise-card mb-3">
                          <div className="img-text-part">
                            <div className="img-block">
                              <img
                                src={exercise?.exerciseImage}
                                height={60}
                                width={70}
                                alt="ex-1"
                              />
                            </div>
                            <div className="text-part ps-3">
                              <h6>{exercise?.title}</h6>
                              <p>{Number(exercise?.set)} Reps</p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Tab>
              )}
              {ExerciseData?.evening.length > 0 && (
                <Tab eventKey="evening" title="Evening">
                  <div className="exercise-content">
                    {ExerciseData?.evening &&
                      ExerciseData?.evening.length <= 0 && (
                        <div className="text-centet"> No Exercise</div>
                      )}

                    {ExerciseData?.evening.map((session) =>
                      session.workout.map((exercise, index) => (
                        <div className="exercise-card mb-3">
                          <div className="img-text-part">
                            <div className="img-block">
                              <img
                                src={exercise?.exerciseImage}
                                height={60}
                                width={70}
                                alt="ex-1"
                              />
                            </div>
                            <div className="text-part ps-3">
                              <h6>{exercise?.title}</h6>
                              <p>{Number(exercise?.set)} Reps</p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Tab>
              )}

              {ExerciseData?.night.length > 0 && (
                <Tab eventKey="night" title="Night">
                  <div className="exercise-content">
                    {ExerciseData?.night && ExerciseData?.night.length <= 0 && (
                      <div className="text-centet"> No Exercise</div>
                    )}
                    {ExerciseData?.night.map((session) =>
                      session.workout.map((exercise, index) => (
                        <div className="exercise-card mb-3">
                          <div className="img-text-part">
                            <div className="img-block">
                              <img
                                src={exercise?.exerciseImage}
                                height={60}
                                width={70}
                                alt="ex-1"
                              />
                            </div>
                            <div className="text-part ps-3">
                              <h6>{exercise?.title}</h6>
                              <p>{Number(exercise?.set)} Reps</p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>

          {ExerciseData?.note && (
            <div className="note-part">
              <h6>Note</h6>
              <p>{ExerciseData?.note}</p>
            </div>
          )}
          <div className="button-part mt-3">
            <Link
              to={"/patientpoints"}
              state={{
                workout: location?.state?.workout,
                patient_id: location?.state?.patient_id,
                patientDetail: location?.state?.patientDetail,
              }}
            >
              <Button className="theme-outline-btn mb-3">
                Change Exercise
              </Button>
            </Link>

            {location?.state?.update === true ? (
              <Button
                className="theme-button mt-3"
                disabled={updating}
                onClick={(e) => {
                  e.preventDefault();
                  updateWorkout();
                }}
              >
                {updating
                  ? " Updating Exercise List..."
                  : "Update Exercise List"}{" "}
              </Button>
            ) : (
              <Button
                className="theme-button"
                onClick={(e) => {
                  e.preventDefault();
                  handleShowNote();
                }}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </Layout>

      <Modal show={showNote} onHide={handleCloseNote} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="body-title">Information Note</p>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Write text…"
                value={note} // Bind the value to the note state
                onChange={(e) => {
                  setNote(e.target.value);
                  //setExrerciseeData((p) => ({ ...p, note: e.target.value }))
                }}
              ></textarea>
            </div>
            {/* <p className='body-title'>Add Reports</p>
            <div className='upload-part'>
              <div className="form-group file-area">
                <input type="file" name="images" id="images" required="required"   onChange={(e) =>{
                  e.preventDefault();
                  // setExrerciseeData((p) => ({ ...p, report: e.target.files[0] }))
                }}/>
                <div className="file-dummy">
                  <div className="success">Great, your files are selected. Keep on.</div>
                  <div className="default"><div className='image-block mb-2'>
                    <img src={UploadIcon} alt='icon' />
                  </div>Add report here (png, jpg, pdf)</div>
                </div>
              </div>

            </div> */}

            <div className="d-flex justify-content-between w-100">
              <Row className="g-4 w-100 mt-2">
                <Col lg="6">
                  <div className={`filed`}>
                    <label for="start-date">Start Date</label>
                    <input
                      type="date"
                      id="start-date"
                      name="start-date"
                      className="form-control"
                      min={getTodayDate()} // Set the min attribute to today's date
                      onChange={handleDateChange}
                      value={startDate} // Assuming `selectedDate` is the state variable holding the selected date value
                    />

                    {validationError?.date && (
                      <FieldValidationError
                        message={"please select valid date"}
                      />
                    )}
                    {startDateError && (
                      <FieldValidationError message={startDateError} />
                    )}
                  </div>
                </Col>
                <Col lg="6" className=" p-0">
                  <div className={`filed`}>
                    <label for="start-date">Exercise Duration</label>

                    <div className="d-flex justify-content-between">
                      <div class="dropdown-component w-50 ml-3">
                        <select
                          className="dropdownSelect form-select"
                          id="months-dropdown"
                          name="months"
                          value={months}
                          onChange={handleMonthsChange}
                        >
                          <option value="0">00</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        <span class="dropdown-label">Months</span>
                      </div>

                      <div class="dropdown-component" style={{ width: "47%" }}>
                        <select
                          className="dropdownSelect form-select"
                          id="months-dropdown"
                          name="months"
                          onChange={handleDaysChange}
                          value={days}
                        >
                          {Array.from({ length: 30 }, (_, i) => i).map(
                            (day) => (
                              <option key={day} value={day}>
                                {day < 10 ? `0${day}` : day}
                              </option>
                            )
                          )}
                        </select>
                        <span class="dropdown-label">Days</span>
                      </div>
                    </div>
                  </div>

                  {validationError?.month && (
                    <FieldValidationError
                      message={"please select valid Month / Day"}
                    />
                  )}
                </Col>

{location?.state?.patientDetail?.isAssignedExercise === false &&
<>
                <Col>
                  <div className="mb-1 filed">
                    <label className="form-label">Type of Payment Mode</label>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <label
                        className="radio-container"
                        style={{
                          fontSize: "12px",
                          marginRight: "10px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="radio"
                          name="typeOfExercise"
                          value="CreditUsage"
                          checked={paymentType?.payment === "CreditUsage"}
                          onChange={(e) =>{
                            //e.preventDefault();
                            setPaymentType((p) =>({...p,payment:"CreditUsage",paymentTypeError:false}))
                          } }
                        />
                        <span className="checkmark"></span>{" "}
                        {/* Moved after input for CSS sibling selector */}
                        &nbsp; Credit Deduct
                      </label>

                      <label
                        className="radio-container"
                        style={{
                          fontSize: "12px",
                          marginRight: "30px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="radio"
                          name="typeOfExercise" // Corrected to match the first radio button
                          value="DirectPayment"
                          checked={paymentType?.payment === "DirectPayment"}
                          onChange={(e) =>{
                            //e.preventDefault();
                            setPaymentType((p) =>({...p,payment:"DirectPayment",paymentTypeError:false}))
                          } }                        />
                        <span className="checkmark"></span>
                        &nbsp; Take From Patient
                      </label>
                    </div>

                    {paymentType.paymentTypeError && (
                      <FieldValidationError message={"Choose payment type"}/>
                  )}
                  </div>
                </Col>

              
                </>
}


{location?.state?.patientDetail?.isAssignedExercise === true && location?.state?.patientDetail?.workoutGivenBy != user?._id && (
                    <>
                      <Col>
                        <div className="mb-1 filed">
                          <label className="form-label">
                            Type of Payment Mode
                          </label>
                          <div style={{ whiteSpace: "nowrap" }}>
                            <label
                              className="radio-container"
                              style={{
                                fontSize: "12px",
                                marginRight: "10px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="typeOfExercise"
                                value="CreditUsage"
                                checked={paymentType?.payment === "CreditUsage"}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setPaymentType((p) => ({
                                    ...p,
                                    payment: "CreditUsage",
                                    paymentTypeError: false,
                                  }));
                                }}
                              />
                              <span className="checkmark"></span>{" "}
                              {/* Moved after input for CSS sibling selector */}
                              &nbsp; Credit Deduct
                            </label>

                            <label
                              className="radio-container"
                              style={{
                                fontSize: "12px",
                                marginRight: "30px",
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="typeOfExercise" // Corrected to match the first radio button
                                value="DirectPayment"
                                checked={
                                  paymentType?.payment === "DirectPayment"
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  setPaymentType((p) => ({
                                    ...p,
                                    payment: "DirectPayment",
                                    paymentTypeError: false,
                                  }));
                                }}
                              />
                              <span className="checkmark"></span>
                              &nbsp; Take From Patient
                            </label>
                          </div>

                          {paymentType.paymentTypeError && (
                            <FieldValidationError
                              message={"Choose payment type"}
                            />
                          )}
                        </div>
                      </Col>
                    </>
                  )}

<Col>

                  <Credit setCredit={setCredit}  />
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={updating || (location?.state?.patientDetail?.workoutGivenBy != user?._id &&  credit === 0) || (location?.state?.patientDetail?.isAssignedExercise === false &&  credit === 0)}
            className="theme-button"
            onClick={(e) => {
              e.preventDefault();
              saveWorkout();
            }}
          >
            {updating ? "Adding exercise..." : "Done"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={completeWorkout}
        onHide={() => setCompleteWorkout(false)}
        size="md"
        className="complete-modal"
      >
        <Modal.Body>
          <div className="content-block text-center">
              <Animation />
          </div>
          <div className="bottom-block text-center">
            <div
              className="workout-desc body-content"
              style={{
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                "flex-direction": "column",
                "padding-top": "25px",
              }}
            >
              <p>Workout Assigned successfully</p>

              <button
                className="theme-outline-btn mb-3 btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  // navigate("/allPatient", { state: { patient_id: location?.state?.patient_id , patientDetail:location?.state?.patientDetail } })

                  navigate("/patientpoints", {
                    state: {
                      patient_id: location?.state?.patient_id,
                      patientDetail: location?.state?.patientDetail,
                    },
                  });
                }}
              >
                Add More +{" "}
              </button>
              <button
                className="theme-button btn btn-primary"
                style={{ minWidth: "300px", padding: "14px" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (location?.state?.workoutId) {
                    navigate("/allPatient", {
                      state: {
                        patient_id: location?.state?.patient_id,
                        patientDetail: location?.state?.patientDetail,
                      },
                    });
                  } else {
                    setAddMore(true);
                  }
                  setCompleteWorkout(false);
                  //   navigate("/difficult", { state: { workout: location?.state?.workout, session: location?.state?.session } })
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={Addmore}
        onHide={() => setAddMore(false)}
        size="md"
        className="complete-modal"
      >
        <Modal.Body>
          <div className="top-heading text-end">
            <button
              className="btn btn-transpatent"
              onClick={() => setAddMore(false)}
            >
              X
            </button>
          </div>
          <div className="content-block text-center">
            <img src={addMore} height={300} width={350} alt="modal-video" />
          </div>
          <div className="bottom-block text-center">
            <div
              className="workout-desc body-content"
              style={{
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                "flex-direction": "column",
                "padding-top": "25px",
              }}
            >
              <p>
                Exercise Assigned To <br /> {}{" "}
              </p>
              <span className="modal-text">
                Email:{" "}
                {location?.state?.patientDetail?.email
                  ? location?.state?.patientDetail?.email
                  : "N/A"}
              </span>

              <span className="modal-text">
                Mobile Number:{" "}
                {location?.state?.patientDetail?.phone
                  ? location?.state?.patientDetail?.phone
                  : "N/A"}
              </span>

              <button
                className="theme-outline-btn mb-3 btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  // navigate("/points")
                }}
              >
                Share{" "}
              </button>
              <button
                className="theme-button btn btn-primary"
                style={{ minWidth: "300px", padding: "14px" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/allPatient", {
                    state: {
                      patient_id: location?.state?.patient_id,
                      patientDetail: location?.state?.patientDetail,
                    },
                  });
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExcersiceList;
