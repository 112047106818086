import React, { useEffect, useRef, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import UploadIcon from '../../assets/images/upload.svg'
import { Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import PDFIcon from '../../assets/images/pdf.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import FieldValidationError from '../../common/FieldValidation'
const OperationalInformation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [operationalInformation,setOpeationInformatiton] = useState({
    inguredBodyPart:"",
    isOperation:false,
    operationDate:"",
    injuryInfo:"",
    report:null

  })
  const [error,setError]= useState({
    inguredBodyPart:false
  })
  const  inguredBodyPart = useRef(null);
  useEffect(() => {
    if (location?.state?.operationalInformation) {
      setOpeationInformatiton(location?.state?.operationalInformation);
    }
    // You might also want to handle operationalInformation if needed
  }, [location.state]);
  return (
    <Layout>
      <TopBar  backLinkData={{
    personalInformation: location?.state?.personalInformation,
    operationalInformation: operationalInformation,
    contactInformation: location?.state?.operationalInformation // Assuming this is a variable with the current state
      }} backlink={"/personalInformation"} title={"Operation Information"} tab={"Add Exercises"} tabChild={"Add Patient"} />
      <div className='main-content-part'>
        <div className='form-data full-width-data'>
          <h5 className='title'>Surgery Information</h5>
          <Row className='g-4'>
            <Col lg="6">
              <div className='filed' >
                <label>Injury Body Part <span className='text-danger'>*</span></label>
                <input type='text' ref={inguredBodyPart} className={`input-field ${error?.inguredBodyPart ? "field-error" :""}`} placeholder='Knee' value={operationalInformation?.inguredBodyPart} onChange={(e) =>{
                  e.preventDefault();
                  setOpeationInformatiton((p) =>({...p,inguredBodyPart:e.target.value}))
                }}/>
                {error?.inguredBodyPart ? <FieldValidationError message="Enter injured Body Part" /> :""}
              </div>
                <div className='filed'>
                  <label>Did you get surgery?</label>
                  <div className='yes-no-radio'>
                    <label>
                      <input type="radio" name="radio" checked={operationalInformation?.isOperation}  />
                      <div className="front-end box" onClick={(e) =>{
                        e.preventDefault();
                        setOpeationInformatiton((p)=>({...p,isOperation:true}))
                      }}>
                        <span>Yes</span>
                      </div>

                    </label>

                    <label>
                      <input type="radio" name="radio" checked={!operationalInformation?.isOperation} />
                      <div className="back-end box" onClick={(e) =>{
                        e.preventDefault();
                        setOpeationInformatiton((p)=>({...p,isOperation:false}))
                      }}>
                        <span>No</span>
                      </div>
                    </label>
                  </div>
                </div>
                {operationalInformation?.isOperation  &&
                <div className='filed'>
                  <label>Surgery Date</label>
                  <input type='date' className='input-field' value={operationalInformation?.operationDate} defaultValue={'2023-02-22'} onChange={(e) =>{
                    e.preventDefault();
                    setOpeationInformatiton((p) =>({...p,operationDate:e.target.value}))
                  }} />
                </div>
}
              <div className='filed'>
                <label>Injury information</label>
                <div className="mb-3">
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Write here…' value={operationalInformation?.injuryInfo} onChange={(e) =>{
                  e.preventDefault();
                  setOpeationInformatiton((p) =>({...p,injuryInfo:e.target.value}))
                }}></textarea>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className='filed'>

                <div className='block'>
                  <p style={{ fontWeight: '500' }}>Reports</p>

                  {operationalInformation?.report?.length > 0 && 
                  <div className='report-card'>
                    <div className='image-block'>
                      <img src={PDFIcon} alt='icon' />
                    </div>
                    <div className='text-block ms-3'>
                      <p className='fileName mb-0'>General Check</p>
                      <p className='fileNo mb-0'>{operationalInformation?.report?.length} files</p>
                    </div>
                  </div>
}
                </div>
              </div>
              <div className='filed'>
                <div className='upload-part mt-2'>
                  <div className="form-group file-area">
                    <input type="file" name="images" id="images" required="required" multiple="multiple"  accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                          onChange={(e) => {
                            if (
                              e?.target?.files[0] && 
                              !(
                                e?.target?.files[0]?.type === "image/png" ||
                                e?.target?.files[0]?.type === "image/jpg" ||
                                e?.target?.files[0]?.type === "image/jpeg" ||
                                e?.target?.files[0]?.type === "image/gif" ||
                                e?.target?.files[0]?.type === "application/pdf" ||
                                e?.target?.files[0]?.type ===
                                  "application/msword" ||
                                e?.target?.files[0]?.type ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              )
                            ) {
                              alert("Only images,pdf,doc,docx allowded");
                              return false;
                            }
                            // if (e.target.files[0].size > 2097152) {
                            //   alert("Not More than 2 MB is allowded");
                            //   return false;
                            // }
                            setOpeationInformatiton((p) =>({...p,report: e.target.files[0]}));
                          }} />
                    <div className="file-dummy">
                      <div className="success">Great, your files are selected. Keep on.</div>
                      <div className="default"><div className='image-block mb-2'>
                        <img src={UploadIcon} alt='icon' />
                      </div>Add report here (png, jpg, pdf)</div>
                    </div>
                  </div>

                </div>
              </div>
            </Col>
          </Row>
          <div className='buttonPart mt-5 d-flex justify-content-center'>

            {/* <Link to={"/contactInformation"} state={{personalInformation:location?.state?.personalInformation ,operationalInformation: operationalInformation}} > */}
              
              <Button className='theme-button' onClick={(e) =>{
                if(operationalInformation?.inguredBodyPart?.trim() === ""){
                  setError((p)=>({...p,inguredBodyPart:true}))
                  inguredBodyPart?.current?.focus()
                }
                else{
                  navigate("/contactInformation", { state:{personalInformation:location?.state?.personalInformation ,operationalInformation: operationalInformation,contactInformation:location?.state?.contactInformation}})
                }
              }}>Continue</Button>

       
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OperationalInformation
