import React from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import doneImg from "../assets/images/Congratulations.png"; // Import the image
import { useNavigate } from "react-router-dom";

const DaysTrailModal = ({ show, onHide, From }) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="complete-modal"
      centered
    >
      {/* <Modal.Header>
        <div className="top-heading w-100 d-flex justify-content-end text-end">
          <button className="btn btn-transpatent" onClick={onHide}>
            X
          </button>
        </div>
      </Modal.Header> */}
      <Modal.Body>
        <div className="content-block text-center">
          <img src={doneImg} height={60} width={60} alt="modal-video" />
        </div>
        <div className="bottom-block text-center">
          <div
            className="workout-desc body-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "25px",
            }}
          >
            <p  className="m-2" style={{fontWeight:"600"}}>Congratulations !</p>
            <span className="m-2" style={{fontWeight:"500"}}>Your 7 days <span style={{cursor:"pointer",color:"#ff6036"}}> free trial </span> started </span>
            <span className="m-2" >
              Complete your profile to access all exercise , <br />
               Explore all the <span style={{fontWeight:"500"}}> premium </span> features with our <span style={{fontWeight:"500"}}>free trial</span>
            </span>

            <span className="m-2" >No Credit card required</span>

            <Button className='theme-button m-2'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/profileData")
                            }}>Complete Profile </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DaysTrailModal;
