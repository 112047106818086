import React, { useEffect, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import PatientCard from '../../components/PatientCard'
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import SearchIcon from '../../assets/images/search.svg'
import CardEx1 from '../../assets/images/cardEX.png'
import CardEx2 from '../../assets/images/cardEX2.png'
import CardEx3 from '../../assets/images/cardEX3.png'
import CardEx4 from '../../assets/images/cardEX4.png'
import CardEx5 from '../../assets/images/cardEX5.png'
import CardEx6 from '../../assets/images/cardEX6.png'
import CardEx7 from '../../assets/images/cardEX7.png'
import CardEx8 from '../../assets/images/cardEX8.png'
import { UseEffectOnce } from '../../Hook/UseEffectOnce'
import { get, getAuthConfig } from '../../libs/http-hydrate'

const PatientPoints = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [key, setKey] = useState('General');
  const [bodyPartData, setBodyPart] = useState(
    {
      has_more: false,
      items: [],
    });
    const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  UseEffectOnce(() => {
    getBodyPartData()
  }, [])
  useEffect(()=>{
    getBodyPartData()
  },[key])
  async function getBodyPartData() {
    setLoading(true);
    try {
      let url ;
      if(key === "General"){
        url = `/bodyPartList?pageNumber=${page}&isTemplate=true`
      }
      else{
        url = `/bodyPartList?pageNumber=${page}`
      }
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
        setBodyPart((p) => ({ ...p, items: data?.data?.data }))
        setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }))

      }

    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login")
      }
    }
    finally {
      setLoading(false);
  }
  }
  return (
    <Layout>
      <TopBar backlink={"/goback"} title={"Library"} tab={"Physiotherapist"} tabChild={"Library"} />


      <div className='main-content-part'>
        <div className='exercise-tab-part'>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => { setKey(k) }}
            className="mb-3 mt-0  d-flex justify-content-center"
          >
            <Tab eventKey="General" title="Protocol">
            </Tab>
            <Tab eventKey="Custom" title="Exercise">
            </Tab>

          </Tabs>
        </div>
        <Row className='g-4'>

        {loading && page === 0 ?  (
                <div className="preloader-whirlpool text-center align-items-center">
                    <div className="whirlpool"></div>
                </div>
            ) : ( <>
          {bodyPartData?.items && bodyPartData?.items.length > 0 &&

            bodyPartData?.items.map((item, index) => {
              return (
                <>
                  <Col xl="3" lg="4" md="6" sm="12">
                    <Link 
                    to={key === "Custom" ? "/foot" : "/surgeryTemplate"}

                    // to={"/foot"}
                    
                    state={{ patient_id: location?.state?.patient_id, patientDetail: location?.state?.patientDetail, bodypartId: item?._id, bodyPart: item?.title, bodyPartImage: item?.image, workout: location?.state?.workout ,isRenew:location?.state?.isRenew }}>
                      <div className='ex-card'>
                        <div className='ex-image'>
                          <img src={item?.image} alt='CardEx1' height={130} />
                        </div>
                        <h6>{item?.title}</h6>
                      </div>
                    </Link>
                  </Col>
                </>
              )
            })
          }  </>)}
        </Row>
      </div>
    </Layout>
  )
}

export default PatientPoints;
