import React from 'react'
import '../assets/css/commonComponent.css'
import FillStar from '../assets/images/star.svg'
import BlanckStar from '../assets/images/star-blanck.svg'
import Patient from '../assets/images/patient.png'
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import Auth from '../libs/auth'
import PatientDummy from "../assets/images/defaultpatient@3x.png"
import DoctorCommon from "../assets/images/Docotr.png";
import DoctorMale from "../assets/images/Doctor Male.png";
import DoctorFeMale from "../assets/images/Doctor Female.png";
import PatientCommon from "../assets/images/Patint.png";
import PatientFemale from "../assets/images/Patient Female.png";
import PatientMale from "../assets/images/Patient male.png";
const UpcomingPatientsCard = (props) => {

    const user = Auth.getCurrentUser();
  return (
    <Link to={"/patientDetails"} state={{patient:props?.Appointment?.patient_id}}>
   <div className={`doctor-card upcomingPatientsCard ${!props?.Appointment?.date && "fromAdd"}`}>
    <div className='doctor-card-content'>
        <div className='image-part d-flex align-items-center'>
            <img src={props?.Appointment?.patient_id?.profile_image ? props?.Appointment?.patient_id?.profile_image : props?.Appointment?.patient_id?.gender === "female" ? PatientFemale :  props?.Appointment?.patient_id?.gender === "male" ? PatientMale : PatientCommon } alt='Patient' style={{borderRadius:"50%"}} height={100} width={100} className='w-100'/>
        </div>
        <div className='text-part w-100'>
            <h5>{props?.Appointment?.patient_id?.name}</h5>
            <p className='gray'>{props?.Appointment?.appoinmentFor ? props?.Appointment?.appoinmentFor :props?.Appointment?.patient_id?.inguredBodyPart  }</p>
            {props?.Appointment?.timeSlot && 
            
            (<><p>{ moment(props?.Appointment?.timeSlot, ["hh:mm:ss"]).format("HH:mm A")} <span style={{ color: '#ADADAD'}}>{moment(props?.Appointment?.date).format("MMM DD")}</span></p></>)}
            <Button className="componentBtn green-btn mt-3">{moment(props?.Appointment?.date).format("DD MMM YYYY")}</Button>
        </div>
    </div>
    <div className='progressBar'>
        <div className='progressText'>
            <p>Progress</p>
            <p>{props?.Appointment?.patient_id?.progressPercentage?.toFixed(2)}%</p>
        </div>
        <ProgressBar now={props?.Appointment?.patient_id?.progressPercentage} />
    </div>
   <div className='bottom-bar'>
        <p>{user?.name}</p>
   </div>
   </div>
   </Link>
  )
}

export default UpcomingPatientsCard