import { useState, React, useEffect } from 'react';
import '../../assets/css/chat.css';
import BackIcon from '../../assets/images/left.svg';
import CallBlack from '../../assets/images/call-2.svg';
import Button from 'react-bootstrap/Button';
import Person from '../../assets/images/onlineUser.png';
import Clip from '../../assets/images/clip.svg';
import Picture from '../../assets/images/picture.svg';
import Sent from '../../assets/images/sent.svg';
import SeacrchIcon from '../../assets/images/search.svg';
import Spinner from 'react-bootstrap/Spinner';
import io from 'socket.io-client';

import Auth from '../../libs/auth';
import { get, getAuthConfig, post } from '../../libs/http-hydrate';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";

const Chat = () => {
  const location = useLocation();
  const [key, setKey] = useState('patient');
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(location?.state?.patient);
  const [chatList, setChatList] = useState({
    page: 1,
    chats: [],
    has_more: false
  });
  const [loadingUsers, setLoadingUsers] = useState(true); // Add loading state for users
  const [loadingChats, setLoadingChats] = useState(false); // Add loading state for chats
  const user = Auth.getCurrentUser();
  const socket = io('http://localhost:1337'); // Update with your server address

  useEffect(() => {
    fetchUserList(currentPage);
    const userId = user?._id; // Replace with the actual user ID
    socket.emit('join', userId);

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('message', (message) => {
      console.log("messageReceived", message);
      if (message?.sender === selectedUser?._id) {
        setChatList(prevState => ({
          ...prevState,
          chats: [...prevState.chats, message]
        }));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser?._id) {
      getChatList(1, selectedUser?._id);
    }
  }, [selectedUser]);

  async function fetchUserList(page) {
    setLoadingUsers(true); // Set loading to true before fetching users
    try {
      const data = await get(`/chat/getUserList?page=${page}`, getAuthConfig());
      if (data?.status === 200) {
        console.log(data, "data");
        setUsers(data?.data?.users);
        setTotalPages(data?.data?.totalPages);
      }
    } catch (error) {
      console.error('Error fetching user list:', error);
    } finally {
      setLoadingUsers(false); // Set loading to false after fetching users
    }
  }

  async function getChatList(page, otherUserId) {
    setLoadingChats(true); // Set loading to true before fetching chats
    try {
      const data = await get(`/chat/getMessages?page=${page}&otherUserId=${otherUserId}`, getAuthConfig());
      if (data?.status === 200) {
        console.log(data, "data");
        
        // Combine old and new messages and sort them by date
        const newChats = page === 1 ? data.data.messages : [...data.data.messages, ...chatList.chats];
        newChats.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  
        setChatList(prevState => ({
          page,
          chats: newChats,
          has_more: data.data.has_more,
        }));
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoadingChats(false); // Set loading to false after fetching chats
    }
  }

  const loadMoreMessages = () => {
    if (chatList.has_more) {
      getChatList(chatList.page + 1, selectedUser?._id);
    }
  };

  async function sendMessage() {
    const payload = {
      senderId: user?._id,
      senderModel: "Physiotherapist",
      receiverId: selectedUser?._id,
      receiverModel: location?.state?.patient ? "Patient" : "Physiotherapist",
      message: message
    };
    try {
      socket.emit('sendMessage', payload); // Emit the event to the server
      const data = await post(`/chat/sendMessage`, payload, getAuthConfig());
      if (data?.status === 200) {
        console.log(data.data.message, "data");
        setChatList(prevState => ({
          ...prevState,
          chats: [...prevState.chats, data.data.message[0]]
        }));
        setMessage(""); // Clear the message input after sending
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  console.log(chatList, "selectedUser");
  return (
    <>
      <div className='chat-page'>
        <div className='header-part'>
          <div className='back-button'>
            <Button className="back">
              <img src={BackIcon} alt='back' />
            </Button>
          </div>
          {selectedUser && 
          <div className='name-call'>
            <div className='profile'>
              <div className='image'>
                <img src={selectedUser?.profile_image
                  ? selectedUser?.profile_image
                  : selectedUser?.gender === "female"
                    ? PatientFemale
                    : selectedUser?.gender === "male"
                      ? PatientMale
                      : PatientCommon} alt='Person' height={50} width={50} />
              </div>
              <div className='user-name ms-3'>
                <p>{selectedUser?.name}</p>
              </div>
            </div>
            <Button className="call">
              <img src={CallBlack} alt='back' />
            </Button>
          </div>}
        </div>
        <div className='main-chat-part'>
          <div className='chat-side-part'>
            <div className='chat-person'>
              <div className='tab-part-data exercise-tab-part mt-0'>
                <div className='tab-ans-part'>
                  <div className='search-box'>
                    <input className='searchInput' placeholder='Search' />
                    <div className='searchIcon'>
                      <img src={SeacrchIcon} alt='icon' />
                    </div>
                  </div>
                  <div className='chatting-person'>
                    {loadingUsers ? (
                      <div className="spinner-container">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        {users?.length <= 0 && "No chats found"}
                        {users?.length > 0 && users?.map((user, index) => (
                          <div key={index} className='chat-card' style={{ cursor: "pointer" }} onClick={(e) => {
                            setSelectedUser(user);
                          }}>
                            <div className='image-blovk rounded'>
                              <img src={user?.profile_image ? user?.profile_image : Person} alt='user' height={50} width={50} />
                            </div>
                            <div className='text-block'>
                              <div className='left'>
                                <p>{user?.name}</p>
                                <small>{user?.lastMessage}</small>
                              </div>
                              <div className='right'>
                                <p className='time'>9:11</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='messages-part'>
            {loadingChats ? (
              <div className="spinner-container">
                <Spinner animation="border" />
              </div>
            ) : selectedUser ? (
              <>
                <div className='chatting'>
                  {chatList?.chats.map((chat, index) => (
                    <div key={index} className={`messages w-75 ${chat?.sender === user?._id ? 'sender' : 'reciver'}`}>
                      <div className='message-card'>
                        <p>{chat?.message}</p>
                      </div>
                      <p className='timer'>{moment(chat.created_at).format('hh:mm A')}</p>
                    </div>
                  ))}
                  {chatList?.has_more && (
                    <Button onClick={loadMoreMessages}>
                      {loadingChats ? <Spinner animation="border" size="sm" /> : "Load More"}
                    </Button>
                  )}
                </div>

                <div className='message-typing-block'>
                  <div className='input-box'>
                    <input type='text' className='msg-type' placeholder='Type…' value={message} onChange={(e) => {
                      setMessage(e.target.value);
                    }} />
                    <Button className='file-attach'>
                      <img src={Clip} alt='clip' />
                    </Button>
                    <Button className='image-attach'>
                      <img src={Picture} alt='clip' />
                    </Button>
                  </div>
                  <div className='sent-btn'>
                    <Button className='sent' onClick={(e) => {
                      e.preventDefault();
                      if (message?.trim() !== "") {
                        sendMessage();
                      }
                    }}>
                      <img src={Sent} alt='sent' />
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className='start-chat'>
                <p>Select a user to start chatting</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
