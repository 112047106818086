import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Layout from "../../layout";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import "../../assets/css/calendar.css";
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Example using react-icons for icons
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
        
function CalendarView() {
  const [page, setPage] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]); // Renamed for clarity
  const [currentView, setCurrentView] = useState('month'); // Initialize view as 'month'
  const localizer = momentLocalizer(moment);
  const [appointmentList, setAppointmentList] = useState([]); // Renamed for clarity
  const todayDateTime = moment(); // Gets the current date and time
  const [todayAppointments,setTodayAppointments]=useState([])
  useEffect(() => {
    getAppointmentData(currentDate);
  }, [currentDate]); // Fetch data on initial load and when date changes

    // Function to handle view change
    const handleViewChange = (newView) => {
      setCurrentView(newView);
    };
  
  async function getAppointmentData(currentViewDate) {
    try {
      setIsLoading(true);
      const formattedDate = moment(currentViewDate).format("YYYY-MM-DD");
      let url = `/appointmentList?pageNumber=${page}&date=${formattedDate}&month=1`;
      const data = await get(url, getAuthConfig());
      
      if (data?.status === 200) {

        setAppointmentList(data?.data?.data)
        const todayDateStr = moment().format("YYYY-MM-DD");
        const todayAppointments = [];

        const transformedAppointments = data.data.data.map((appt) => {
          const [hours, minutes, seconds] = appt.timeSlot.split(':').map(Number);
          const startDate = new Date(appt.date);
          startDate.setHours(hours, minutes, seconds);

          const duration = appt.duration || 5; // Replace 'appt.duration' with the actual path to the duration in your data
          const endDate = new Date(startDate.getTime() + duration * 60000); // Convert duration to milliseconds
  

            // Check if the appointment date is today
        if(moment(appt.date).format("YYYY-MM-DD") === todayDateStr) {
          todayAppointments.push(appt);
        }
          return {
            title: `${appt.patient_id.name}, ${appt.appoinmentFor}`,
            start: startDate,
            end: endDate,
            desc: `${appt.patient_id.name}, ${appt.model_type}`,
            image:appt.patient_id.profile_image ? appt.patient_id.profile_image : PatientDummy
          };
        });
        setTodayAppointments(todayAppointments); // Set today's appointments in a separate state
        setAppointments(transformedAppointments);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
  };
  

  const CustomToolbar = ({ onNavigate, label, view, onView, date }) => {
    const [viewButtonLabel, setViewButtonLabel] = useState('');
  
    useEffect(() => {
      updateViewButtonLabel(date, view);
    }, [date, view]);
  
    const updateViewButtonLabel = (currentDate, view) => {
      const now = moment(); // Current date
      const selectedDate = moment(currentDate);
  
      if (view === 'month') {
        if (now.isSame(selectedDate, 'month')) {
          setViewButtonLabel('This Month');
        } else {
          setViewButtonLabel(selectedDate.format('MMMM')); // Show month name
        }
      } else if (view === 'week') {
        if (now.isSame(selectedDate, 'week')) {
          setViewButtonLabel('This Week');
        } else {
          setViewButtonLabel('Week'); // Show "Week" if it's not the current week
        }
      } else if (view === 'day') {
        if (now.isSame(selectedDate, 'day')) {
          setViewButtonLabel('Today');
        } else {
          setViewButtonLabel(selectedDate.format('MMMM D')); // Show the exact date
        }
      }
    };
  
    const handleTodayClick = () => {
      onNavigate('TODAY');
      updateViewButtonLabel(moment(), view); // Pass current date to update label correctly
    };
  
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={() => onNavigate('PREV')}><FaArrowLeft /></button>
          <button type="button" style={{ fontSize: "14px", fontWeight: "500", padding: "8px" }} onClick={handleTodayClick}>{viewButtonLabel}</button>
          <button type="button" onClick={() => onNavigate('NEXT')}><FaArrowRight /></button>
        </span>
        <span className="rbc-toolbar-label">{label}</span>
        <span className="rbc-btn-group">
          <button type="button" style={{ fontSize: "14px", fontWeight: "500" }} onClick={() => onView('month')} className={view === 'month' ? 'rbc-active' : ''}>Month</button>
          <button type="button" style={{ fontSize: "14px", fontWeight: "500" }} onClick={() => onView('week')} className={view === 'week' ? 'rbc-active' : ''}>Week</button>
          <button type="button" style={{ fontSize: "14px", fontWeight: "500" }} onClick={() => onView('day')} className={view === 'day' ? 'rbc-active' : ''}>Day</button>
        </span>
      </div>
    );
  };
  
  function Event({ event }) {
    return (
      <div>
        {event.image && <img src={event.image} className="rounded" alt="" style={{ height: '20px' }} />}
        &nbsp;
        <strong style={{fontSize:"12px"}}>{event.title}</strong>
      </div>
    );
  }
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2 bg-white">
        <span className="text-xl text-900 font-bold">Upcoming Appointments</span>
    </div>
);


const representativeBody = (rowData) => {
  return (
    <span className="textGry">
      { rowData.timeSlot ? moment(rowData.timeSlot, 'HH:mm:ss').format('h:mm A') + " , " + moment(rowData.date).format('MMM DD') : moment(rowData.patient_id.added_date, 'HH:mm:ss').format('h:mm A') + " , " + moment(rowData.patient_id.added_date).format('MMM DD')}
    </span>
  );
};
const patientNameBodyTemplate = (rowData) => {
  return (
    <div className="patient-infoCaard">
      <img src={rowData?.patient_id?.profile_image || PatientDummy} alt="Profile" className="patient-avatar" />
      <div>
        <span>{rowData?.patient_id?.name}</span>
        <br />
        <span className="textGry">{rowData?.patient_id?.email || rowData?.patient_id?.phone}</span>
      </div>
    </div>
  );
};



const injuryTemplate = (rowData) => {
  return <span>{rowData?.appoinmentFor ? rowData?.appoinmentFor : rowData?.patient_id?.inguredBodyPart ? rowData?.patient_id?.inguredBodyPart : "N/A"  }</span>;
};

const durationTemplate = (rowData) => {
  return <span>{rowData?.duration ? rowData?.duration + " Mins" : "N/A"}</span>;
};
const statusTemplate = (rowData) => {
  // Combine date and timeSlot into a single moment object
  const appointmentDateTime = moment(rowData.date).set({
    hour: moment(rowData.timeSlot, 'HH:mm:ss').get('hour'),
    minute: moment(rowData.timeSlot, 'HH:mm:ss').get('minute'),
    second: moment(rowData.timeSlot, 'HH:mm:ss').get('second')
  });

  // Compare appointmentDateTime with the current date and time
  return <span>{ rowData?.appoinmentFor ?   appointmentDateTime.isAfter(todayDateTime) ? "Upcoming" : "Completed" :"Doctor Added"}</span>;
};

  return (
    <>
      <Layout>
        <div className='main-home-part'>
        {isLoading && (
              <div className="preloader-whirlpool text-center align-items-center">
                <div className="whirlpool"></div>
              </div>)}
          

          
     {!isLoading   &&   <div class="">
  <div class="horizontal-scroller">
    <div class="d-flex flex-row flex-nowrap">
      {todayAppointments && todayAppointments.map((appointment, index) => (
        <div key={index} className="pill-card mx-2">
          <img src={appointment.patient_id?.profile_image || PatientDummy} alt="Avatar" className="rounded-circle avatar" />
          <div className="card-info">
            <h6 className="card-title mb-0" style={{ fontSize: "12px" }}>{appointment.patient_id?.name}</h6>
            <small className="text-muted" style={{ fontSize: "11px" }}>{appointment.appoinmentFor}</small>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
}

{!isLoading   &&   <div className="container-fluid card p-3 border-0 " style={{"box-shadow":"0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)","borderRadius":"15px"}}>
       
              <Calendar
              localizer={localizer}
              date={currentDate} // Pass the currentDate directly
              events={appointments}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 700 }}
                onNavigate={handleNavigate} // Add the onNavigate event handler
                views={['month', 'week', 'day']}
                view={currentView} // Current view state
                onView={handleViewChange} // Function to update the view state
               
  components={{
    event: Event, // Use the custom Event component
    toolbar: CustomToolbar, // Use your custom toolbar component
  }}
              />

            
          </div> }
{!isLoading   && 
  <div className="card mb-5 mt-3 rounded border-0 p-2" style={{"box-shadow":"0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)","borderRadius":"15px"}}>

  <DataTable value={appointmentList} header={header}  tableStyle={{ minWidth: '50rem' }}>
                <Column field="title" header="Patient"  body={patientNameBodyTemplate}></Column>
                <Column field="name" header="Date / Time"  body={representativeBody}></Column>
                <Column field="category" header="Injury"  body={injuryTemplate}></Column>
                <Column field="duration" header="Duration"  body={durationTemplate}></Column>
                <Column field="status" header="Status"  body={statusTemplate}></Column>
            </DataTable>
  </div>
}

        </div>     
      </Layout>  
    </>
  );
}

export default CalendarView;
