import React from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import doneImg from "../assets/images/Opps.svg"; // Import the image
import { useNavigate } from "react-router-dom";

const Over7DaysTrialModal = ({ show, onHide, data }) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="complete-modal"
      centered
    >
      <Modal.Body>
        <div className="content-block text-center">
          <img src={doneImg} height={60} width={60} alt="modal-video" />
        </div>
        <div className="bottom-block text-center">
          <div
            className="workout-desc body-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "25px",
            }}
          >
            <p className="m-2">Opps!</p>
            <span className="m-2" style={{fontSize:"20px"}}>
              Your 7 days  <span style={{cursor:"pointer",color:"#ff6036",fontWeight:"600"}}>free trial</span> is over.
            </span>
            <span className="m-2">Upgrade your premium plan to continue using Recureme.</span>

            <Button className='theme-button m-2'
                            onClick={(e) => {
                                e.preventDefault();

                                if(data?.address?.country !== "India")
                                {
                                    navigate("/paymentStripe")
                                }
                                else{
                                    navigate("/payment")
                                }
                            }}>Upgrade Plan </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Over7DaysTrialModal;
