import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { getAuthConfig, post } from "../libs/http-hydrate";
import Layout from "../layout";
import TopBarNavigation from "../components/TopBarNavigation";
import Auth from "../libs/auth";

const Integration = () => {
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const user = Auth.getCurrentUser();
  const handleGenerateApiKey = async () => {
    setLoading(true);
    try {
      const response = await post("/admin/generateToken", {userId:user?._id}, getAuthConfig());

      if (response.status === 201) {
        setApiKey(response.data.token);
      } else {
        alert(response.data.message || "Error generating API key");
      }
    } catch (error) {
      console.error("Error generating API key:", error);
      alert(error.response?.data?.message || "Internal server error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Layout>
        <TopBarNavigation
          title={"Integration"}
          tab={"Profile"}
          tabChild={"Settings"}
        />
        <div className="main-content-part container">
          <div className="form-data container mt-5">
            <div className="filed">
              <label>API Key</label>
              <input type="text" value={apiKey} readOnly />
            </div>

            <div className="buttonPart mt-5 d-flex justify-content-center">
              <Button
                className="theme-button"
                onClick={handleGenerateApiKey}
                disabled={loading}
              >
                {loading ? "Generating..." : "Generate API"}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Integration;
