import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import { Row, Col, Button } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { UseEffectOnce } from '../../Hook/UseEffectOnce'
import { get, getAuthConfig } from '../../libs/http-hydrate'
import Comment from '../../assets/images/CommentCommunity.svg'
import Like from '../../assets/images/like.svg'
import CommunityImg from '../../assets/images/community.png'
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Tabs, Tab } from "react-bootstrap";
import LikeComment from "../../assets/images/LikeCommunity.svg"
import LikeCommentFill from "../../assets/images/FillLikeDoctor.svg"
import ProfileCompletionModal from '../AddProfileData/ProfileCompletionModal';
import Auth from '../../libs/auth';
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
const CommunityListing = () => {
    const [key, setKey] = useState("anytime");
    const navigate = useNavigate()
    const [communitiesList, setcommunitiesList] = useState({
        items: [],
        has_more: false
    })
    const user = Auth.getCurrentUser()
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedCommunity, setSelectedCommunity] = useState(0)
    const [selectedCommunityLikeCount, setselectedCommunityLikeCount] = useState(1)
    const [likepage, setLikePage] = useState(1);
    const [communitiesLikeList, setcommunitiesLikeList] = useState({
        items: [],
        has_more: false
    })
    UseEffectOnce(() => {
        getCommunitiesList();
    })
    useEffect(() => {
        getCommunitiesList()
    }, [key])

    useEffect(() => {
        if (page >= 2) {
            getCommunitiesList()
        }
    }, [page])

    const observer = useRef();
    const lastBookElementRef = useCallback(
        (node) => {
            // if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && communitiesList?.has_more) {
                    setPage((prevPageNumber) => prevPageNumber + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [communitiesList?.has_more]
    );

    async function getCommunitiesList() {
        try {
            let url;
            if (key === "my") {
                url = `/communities?pageNumber=${page}&myCommunity=true`;
            }
            else {
                url = `/communities?pageNumber=${page}`;
            }
            const data = await get(url, getAuthConfig());
            if (data?.status === 200) {

                if (page > 1) {
                    setcommunitiesList((p) => ({ ...p, items: communitiesList?.items.concat(data?.data?.communities) }));
                } else {
                    setcommunitiesList((p) => ({ ...p, items: data?.data?.communities }));
                }
                // setcommunitiesList((e) => ({ ...e, items: data?.data?.data }))
                setcommunitiesList((e) => ({ ...e, has_more: data?.data?.has_more }))
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        if (selectedCommunity && show === true) {
            getCommunitiesLikes()
        }
    }, [selectedCommunity, likepage])

    async function getCommunitiesLikes() {
        try {
            const data = await get(`/like?communityId=${selectedCommunity}&pageNumber=${likepage}`, getAuthConfig());
            if (data?.status === 200) {

                if (likepage > 1) {
                    setcommunitiesLikeList((p) => ({ ...p, items: communitiesLikeList?.items.concat(data?.data?.likes) }));
                } else {
                    setcommunitiesLikeList((p) => ({ ...p, items: data?.data?.likes }));
                }
                // setcommunitiesList((e) => ({ ...e, items: data?.data?.data }))
                setcommunitiesLikeList((e) => ({ ...e, has_more: data?.data?.has_more }))
            }
        } catch (error) {
        }
    }
    async function likeCommunity(id, unlike) {
        try {
            let url;
            if (unlike) {
                url = `/likeCommunity?communityId=${id}&unlike=true`
            }
            else {
                url = `/likeCommunity?communityId=${id}`;
            }
            const data = await get(url, getAuthConfig());
            if (data?.status === 200) {
                if (unlike) {
                    const updatedItems = communitiesList?.items.map((item) => {
                        if (item?._id === id) {
                            return { ...item, likeByYou: false, likeCount: item?.likeCount - 1 }
                        }
                        return item
                    })
                    setcommunitiesList({ items: updatedItems })
                }
                else {
                    const updatedItems = communitiesList?.items.map((item) => {
                        if (item?._id === id) {
                            return { ...item, likeByYou: true, likeCount: item?.likeCount + 1 }
                        }
                        return item
                    })
                    setcommunitiesList({ items: updatedItems })
                }

            }
        } catch (error) {
        }
    }
    const [showProfileCompletionModal, setShowProfileCompletionModal] = useState(false);

    return (
        <>


            <Layout>
                <div className='main-content-part'>
                    <div className='buttonPart d-flex justify-content-end'>

                        <Button className='theme-button'
                            onClick={(e) => {
                                e.preventDefault();
                                // if (user?.profileCompleted === false) {
                                //     setShowProfileCompletionModal(true);
                                //   } else {
                                navigate("/AddBlog")
                                //   }
                            }}>Add Blog </Button>
                    </div>
                    <div className="content-block">
                        <div className="exercise-tab-part">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => {
                                    setPage(1);
                                    setKey(k)
                                }}
                                className="mb-3"
                            >
                                <Tab eventKey="anytime" title="All" key={"anytime"}>
                                    <Row className='g-4'>
                                        {communitiesList?.items && communitiesList?.items?.length === 0 && <div className='text-center'>No Blog Found</div>}
                                        {communitiesList?.items && communitiesList?.items?.map((item, index) => {
                                            return <>
                                                <Col xl="4" md="6" lg="12" ref={lastBookElementRef} key={index}>
                                                    <div className='community-card'>
                                                        <div className='community-card-content'>
                                                            <div className='image-part d-flex justify-content-center align-content-center' style={{ cursor: "pointer" }} onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate("/communityDetails", { state: { comunity: item } })
                                                            }}>
                                                                {item?.community_image  && 
                                                                <img src={item?.community_image ? item?.community_image : CommunityImg} height={250} width={100} alt='CommunityImg' />
                                                                }
                                                                </div>
                                                            <div className='text-part'>
                                                                <div className='img-date-part' style={{ cursor: "pointer" }} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate("/communityDetails", { state: { comunity: item } })
                                                                }}>
                                                                    <div className='prodile-img-name'>
                                                                    <img src={item?.posted_By?.profile_image ? item?.posted_By?.profile_image : item?.posted_By?.gender === "female" ? DoctorFeMale : item?.posted_By?.gender === "male" ? DoctorMale : DoctorCommon} height={30} width={30} style={{borderRadius:"50%"}}/>
                                                                        <p>{item?.posted_By?.name}</p>
                                                                    </div>
                                                                    <p className='gray'> {moment(item?.created_at).format('MMM DD YYYY')} </p>
                                                                </div>
                                                                <h6 style={{ cursor: "pointer" }} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate("/communityDetails", { state: { comunity: item } })
                                                                }}>{item?.title}</h6>
                                                                {!item?.community_image && (
    <>
        <p style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        }} dangerouslySetInnerHTML={{ 
            __html: item?.description?.split(/\s+/).slice(0, 50).join(' ') + (item?.description?.split(/\s+/).length > 50 ? '...' : '')
        }} />
        {/* {item?.description?.split(/\s+/).length > 50 && ( */}
            <span 
                style={{"cursor": "pointer", "color": "#FF6036", "fontWeight": "500" ,"fontSize":"12px"}}
                onClick={(e) => { 
                    e.preventDefault(); 
                    navigate("/communityDetails", { state: { comunity: item } }); 
                }}>
                Read More
            </span>
        {/* )} */}
    </>
)}
                                                                <div className='like-comment-data'>
                                                                    <div className='like-comment-text ms-1'>
                                                                        <p className='gray'>{item?.commentCount} Comments</p>
                                                                        <p className='gray ms-3' onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (Number((item?.likeCount)) > 0) {
                                                                                setSelectedCommunity(item?._id)
                                                                                handleShow()
                                                                                setselectedCommunityLikeCount(item?.likeCount)
                                                                            }
                                                                        }} style={{ cursor: 'pointer' }}>{item?.likeCount} Likes</p>
                                                                    </div>
                                                                    <div className='like-commnet-icon'>
                                                                        {item?.likeByYou === true ? 

<img src={LikeCommentFill} alt='chat' onClick={(e) => {
    e.preventDefault();
    likeCommunity(item?._id, "unlike")
}} />
                                                                            :
                                                                            <img src={LikeComment} alt='chat' onClick={(e) => {
                                                                                e.preventDefault();
                                                                                likeCommunity(item?._id)
                                                                            }} />}
                                                                        <img src={Comment} alt='chat' className='ms-2' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </>
                                        })}
                                    </Row>
                                </Tab>
                                <Tab eventKey="my" title="My Blog" key={"my"}>
                                    <Row className='g-4'>
                                        {communitiesList?.items && communitiesList?.items?.length === 0 && <div className='text-center'>No Blog Found</div>}
                                        {communitiesList?.items && communitiesList?.items?.map((item, index) => {
                                            return <>
                                                <Col xl="4" md="6" lg="12" ref={lastBookElementRef} key={index}>
                                                    <div className='community-card'>
                                                        <div className='community-card-content'>
                                                            <div className='image-part d-flex justify-content-center align-content-center' style={{ cursor: "pointer" }} onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate("/communityDetails", { state: { comunity: item } })
                                                            }}>
 {item?.community_image  && 
                                                                <img src={item?.community_image ? item?.community_image : CommunityImg} height={250} width={100} alt='CommunityImg' />
                                                                }                                                            </div>
                                                            <div className='text-part'>
                                                                <div className='img-date-part' style={{ cursor: "pointer" }} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate("/communityDetails", { state: { comunity: item } })
                                                                }}>
                                                                    <div className='prodile-img-name'>
                                                                        <img src={item?.posted_By?.profile_image ? item?.posted_By?.profile_image : item?.posted_By?.gender === "female" ? DoctorFeMale : item?.posted_By?.gender === "male" ? DoctorMale : DoctorCommon} height={30} width={30} style={{borderRadius:"50%"}}/>
                                                                        <p>{item?.posted_By?.name}</p>
                                                                    </div>
                                                                    <p className='gray'> {moment(item?.created_at).format('MMM DD YYYY')} </p>
                                                                </div>
                                                                <h6 style={{ cursor: "pointer" }} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate("/communityDetails", { state: { comunity: item } })
                                                                }}>{item?.title}</h6>

{!item?.community_image && (
    <>
        <p style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        }} dangerouslySetInnerHTML={{ 
            __html: item?.description?.split(/\s+/).slice(0, 50).join(' ') + (item?.description?.split(/\s+/).length > 50 ? '...' : '')
        }} />
        {/* {item?.description?.split(/\s+/).length > 50 && ( */}
            <span 
                style={{"cursor": "pointer", "color": "#FF6036", "fontWeight": "500","fontSize":"12px"}}
                onClick={(e) => { 
                    e.preventDefault(); 
                    navigate("/communityDetails", { state: { comunity: item } }); 
                }}>
                Read More
            </span>
        {/* )} */}
    </>
)}

                                                                <div className='like-comment-data'>
                                                                    <div className='like-comment-text ms-1'>
                                                                        <p className='gray'>{item?.commentCount} Comments</p>
                                                                        <p className='gray ms-3' onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (Number((item?.likeCount)) > 0) {
                                                                                setSelectedCommunity(item?._id)
                                                                                handleShow()
                                                                                setselectedCommunityLikeCount(item?.likeCount)
                                                                            }
                                                                        }} style={{ cursor: 'pointer' }}>{item?.likeCount} Likes</p>
                                                                    </div>
                                                                    <div className='like-commnet-icon'>
                                                                    {item?.likeByYou === true ? 

<img src={LikeCommentFill} alt='chat' onClick={(e) => {
    e.preventDefault();
    likeCommunity(item?._id, "unlike")
}} />
                                                                            :
                                                                            <img src={LikeComment} alt='chat' onClick={(e) => {
                                                                                e.preventDefault();
                                                                                likeCommunity(item?._id)
                                                                            }} />}
                                                                        <img src={Comment} alt='chat' className='ms-2' style={{cursor:"pointer"}}   onClick={(e) => { 
                    e.preventDefault(); 
                    navigate("/communityDetails", { state: { comunity: item } }); 
                }}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </>
                                        })}
                                    </Row>
                                </Tab>
                            </Tabs>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </Layout>
            <ProfileCompletionModal
        show={showProfileCompletionModal}
        onHide={() => setShowProfileCompletionModal(false)}
        From={"Add Blog"}
      />
            <Modal show={show} onHide={handleClose} centered size="md">
                <Modal.Header closeButton className='justify-content-start'>
                    <Modal.Title className='text-center'>Likes ({selectedCommunityLikeCount})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='body-content'>
                        <ul className='like-person-list'>
                            {communitiesLikeList?.items && communitiesLikeList?.items?.length === 0 && <div className='text-center'>No Records Found</div>}
                            {communitiesLikeList?.items && communitiesLikeList?.items?.map((item, index) => {
                                return (
                                    <>
                                        <li className='like-person'>
                                            <img src={item?.user?.profile ? item?.user?.profile : PatientDummy} style={{ borderRadius: "55%" }} height={"50px"} width={"60px"} alt="person" />
                                            <p>{item?.user?.name}</p>
                                        </li>
                                    </>
                                )
                            })}
                        </ul>
                        {communitiesLikeList?.has_more && <div className='text-center m-4' style={{ cursor: "pointer" }} onClick={(e) => {
                            e.preventDefault();
                            setLikePage((prevPageNumber) => prevPageNumber + 1)
                        }}> Load More</div>}
                    </div>
                </Modal.Body>

            </Modal></>
    )
}

export default CommunityListing;
