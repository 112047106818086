import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./payment.css";
import PaymentRazorpay from "./PaymentRazorpay";
import PaymentQuaterly from "./PaymentQuaterly";
import Layout from "../layout";

function Payment() {
  const [key, setKey] = useState("Monthly");

  return (
    <>
      <Layout>
        {/* <div className="tabs-container-wrapper">
          <div className="tabs-containerss mt-4 mx-5">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            
            >
              <Tab eventKey="Monthly" title={"Subcription"}>
              </Tab>
              <Tab eventKey="yearly" title={"Monthly Plan"}>
              </Tab>
            </Tabs>
          </div>
        </div> */}
        <PaymentRazorpay ></PaymentRazorpay> 
        {/* {key === 'Monthly' ? <PaymentRazorpay ></PaymentRazorpay> :<PaymentQuaterly></PaymentQuaterly>} */}

      </Layout>
    </>
  );
}

export default Payment;
