import React, { useState, useEffect } from 'react'
// import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import { Row, Col, Button } from 'react-bootstrap';
import Doctor from '../../assets/images/doctorInfo.png'
import Star from '../../assets/images/star.svg'
import Location from '../../assets/images/location.svg'
import Call from '../../assets/images/call.svg'
import Chat from '../../assets/images/chat.svg'
import User from '../../assets/images/user.svg'
import Verify from '../../assets/images/verify.svg'
import Rating from '../../assets/images/rating.svg'
import '../../assets/css/doctorInfo.css'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { UseEffectOnce } from '../../Hook/UseEffectOnce';
import { get, getAuthConfig } from '../../libs/http-hydrate';
import Auth from '../../libs/auth';
import moment from "moment";
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const DoctorDetails = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [doctorDetails, setDoctorDetails] = useState();
  const [SlotsDetail, setSlotsDetail] = useState();
  const [morningSlots, setMorningSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [BlockedList, setBlockedList] = useState();
  // Separate slots into morning, evening, and night arrays
  const [eveningSlots, setEveningSlots] = useState([]);
  const [nightSlots, setNightSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({
    appoinmentFor: "",
    slot: ""
  });
  useEffect(() => {
    if (location?.state?.doctor) {
      setDoctorDetails(location?.state?.doctor)
      getSlotDetails()
    }
  }, [location?.state?.doctor])

  // UseEffectOnce(() => {
  //   getSlotDetails()
  // })
  useEffect(() => {
    if (SlotsDetail?.Time && selectedDate) {
      const allSlots = [];
      const now = moment();

      // Generate slots based on startTime, endTime, and duration
      SlotsDetail?.Time?.forEach((slot) => {
        const { startTime, endTime, duration } = slot;
        const startMoment = moment(startTime, 'HH:mm:ss');
        const endMoment = moment(endTime, 'HH:mm:ss');

        while (startMoment.add(duration, 'minutes').isSameOrBefore(endMoment)) {
          allSlots.push({
            start: startMoment.clone().subtract(duration, 'minutes'),
            end: startMoment.clone(),
          });
        }
      });

      // Filter slots for the selected date and future dates
      const filteredSlots = allSlots.filter((slot) => {
        // Check if the slot is for the selected date or a future date
        const isSameOrAfterSelectedDate = moment(slot.start).isSameOrAfter(selectedDate, 'day');

        // Check if the slot is booked
        const isBooked = BlockedList.some((bookedSlot) => {
          return (
            moment(bookedSlot.date).isSame(selectedDate, 'day') &&
            bookedSlot.timeSlot === slot.start.format('HH:mm:ss')
          );
        });

        // Check if the slot is before the current time
        const isBeforeCurrentTime = slot.start.isBefore(now);

        // Include slots for the selected date and future dates
        // Disable slots that are booked or before the current time
        return isSameOrAfterSelectedDate && (!isBooked || !isBeforeCurrentTime);
      });

      // Classify filtered slots into morning, evening, and night
      const morning = [];
      const evening = [];
      const night = [];
      filteredSlots.forEach((slot) => {
        const startHour = slot.start.hour();
        if (startHour >= 6 && startHour < 12) {
          morning.push(slot);
        } else if (startHour >= 12 && startHour < 18) {
          evening.push(slot);
        } else {
          night.push(slot);
        }
      });

      setMorningSlots(morning);
      setEveningSlots(evening);
      setNightSlots(night);
    } else {
      // If there are no slots for the selected date, set the slot states to empty arrays
      setMorningSlots([]);
      setEveningSlots([]);
      setNightSlots([]);
    }
  }, [SlotsDetail, selectedDate, BlockedList]);


  async function getSlotDetails() {
    try {
      const data = await get(`/Slots?doctor_id=${location?.state?.doctor?._id}`, getAuthConfig());
      if (data?.status === 200) {
        setSlotsDetail(data?.data?.msg[0]);
        setBlockedList(data?.data?.bookedList)
      }

    } catch (error) {
      if (error?.status === 401) {
        Auth.logout()
        navigate("/login")
      }

      if (error?.response?.status === 400) {
        setSlotsDetail()
      }
    }
  }


  const handleSlotSelect = (slot) => {
    const selectedTime = slot.start.format("HH:mm:ss");
    setSelectedSlot({
      appoinmentFor: selectedSlot.appoinmentFor,
      slot: selectedTime,
    });
    // setError((p) => ({ ...p, timeSlot: false }))
  };

  const isSlotDisabled = (slot) => {
    const startMoment = moment(slot.start);
    const isBooked = BlockedList.some((bookedSlot) => {
      return (
        moment(bookedSlot.date).isSame(selectedDate, "day") &&
        bookedSlot.timeSlot === startMoment.format("HH:mm:ss")
      );
    });
    const isBeforeCurrentTime = startMoment.isBefore(moment());
    return isBooked || isBeforeCurrentTime;
  };
  return (
    <Layout>
      <ToastContainer />

      <TopBar backlink={"/appointment"} title={doctorDetails?.name} tab={"Book Appointment"} tabChild={"Doctor Profile"} />      <div className="main-content-part topBox">
        <div className="top-orange-box"></div>
        <div className="innerInfo">
          <Row>
            <Col lg={5}>
              <div className="doctorDetailsCard">
                <div className="doctorContent">
                  <div className="imnage-part">
                    <img src={doctorDetails?.profile_image ? doctorDetails?.profile_image : DoctorDummy} style={{ borderRadius: "50%" }} height={"230px"} width={"230px"} alt="doctor" />
                    <div className="rating">
                      <p>
                      <img src={Star} alt="star" /> {doctorDetails?.averageRating?.toFixed(2)} ({doctorDetails?.totalRatingCount})
                        {/* <img src={Star} alt="star" /> 4.5 (60) */}
                      </p>
                    </div>
                  </div>
                  <div className="doctorInfo">
                    <h4 className="name d-flex justify-content-center">{doctorDetails?.name}      {doctorDetails?.associatedEntity?.verified === true && ( // Check if the user is verified
                      <div
                        className='px-1'
                      // style={{
                      //     position: 'absolute',
                      //     top: '-2px',
                      //     right: '-5px',
                      // }}
                      >

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <circle cx="10" cy="10" r="10" fill="green" />
                          <path d="M7 10 L9 12 L14 7" stroke="white" stroke-width="2" fill="transparent" />
                        </svg>

                        {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                      </div>
                    )}</h4>
                    <h5>{doctorDetails?.designation}</h5>
                    {doctorDetails?.address?.state && doctorDetails?.address?.country &&
                      <h6>
                        <img src={Location} alt="location" /> {doctorDetails?.address?.state}, {doctorDetails?.address?.country}
                      </h6>
                    }
                  </div>
                  <div className="logInfo">
                    {doctorDetails?.mobile ?
                      <CopyToClipboard text={doctorDetails?.mobile}
                        onCopy={() => {
                          toast.success(
                            "Mobile number copied to clipboard!"
                          );
                        }} >
                        <div className='badge call' style={{ cursor: "pointer" }}>
                          <img src={Call} alt='call' />
                        </div>
                      </CopyToClipboard> : ""}
                    <div className="badge email">
                      <img src={Chat} alt="email" />
                    </div>
                  </div>
                  <div className="expData">
                    <div className="part">
                      <img src={User} alt="user" />
                      <h6>{doctorDetails?.patientCount}</h6>
                      <p>Patient</p>
                    </div>
                    <div className="part">
                      <img src={Verify} alt="verified" />
                      <h6>{doctorDetails?.years_of_experience} years</h6>
                      <p>Experience</p>
                    </div>
                    <div className="part">
                      <img src={Rating} alt="timer" />
                      <h6>4.5</h6>
                      <p>Till 7:00 PM</p>
                    </div>
                  </div>
                  <div className="aboutInfo">
                    <h6>About Physiotherapist</h6>
                    <p>
                      {doctorDetails?.brief_detail}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="doctorDetailsCard startdata">
                <div className="rightPartData">
                  {/* {doctorDetails?.hospital_fees && 
                  <div className="infoField filed">
                    <h6>In Clinic </h6>
                    <h5>${doctorDetails?.hospital_fees} Fees</h5>
                  </div>}  */}


                  {doctorDetails?.hospital_fees && <div className="locationTimeField filed">
                    <div className="block">
                      <h6>In Clinic </h6>
                      <h5>${doctorDetails?.hospital_fees} Fees</h5>
                    </div>
                    <div className="block">
                      <p>Walk In </p>
                      <p className="badge">Yes</p>
                    </div>
                  </div>}




                  {doctorDetails?.associatedEntity === "Individual" && <div className="locationTimeField filed">
                    <div className="block">
                      <p>{"Individual"}</p>
                      <p>{doctorDetails?.address?.state}, {doctorDetails?.address?.country}</p>
                    </div>
                    <div className="block">
                      <p>Wait Time</p>
                      <p className="badge">{doctorDetails?.wait_Time} min</p>
                    </div>
                  </div>}
                  {doctorDetails?.associatedEntity?.name &&
                    <div className="locationTimeField filed">
                      <div className="block">
                        <p>{doctorDetails?.associatedEntity?.name}</p>
                        <p>{doctorDetails?.associatedEntity?.address?.state}, {doctorDetails?.associatedEntity?.address?.country}</p>
                      </div>
                      <div className="block">
                        <p>Wait Time</p>
                        <p className="badge">{doctorDetails?.wait_Time} min</p>
                      </div>
                    </div>}
                  <div className="infoField filed">
                    <p>
                      Today, {moment(selectedDate).format("DD MMM")}

                      {morningSlots?.length > 0 &&

                        <span> {morningSlots?.length + eveningSlots?.length + nightSlots?.length} Slots</span>}

                    </p>
                    <div className="radio-badge-part">
                      <form>

                        {morningSlots.length === 0 && eveningSlots.length === 0 && nightSlots.length === 0 && <div className="text-center">Physiotherapist   is not available</div>}
                        {morningSlots.length > 0 && (
                          <div className="filter-box">
                            <label htmlFor="appointment-input">Morning</label>
                            <div className="radio-badge-part mt-0">
                              <form>


                                {morningSlots?.map((slot, index) => (
                                  <label key={index}>
                                    <input type="radio" name="radio" disabled={isSlotDisabled(slot)} // Disable slots using the function
                                      onClick={() => handleSlotSelect(slot)}
                                    />
                                    <span className={isSlotDisabled(slot) ? "disabled-slot" : ""}>{slot.start.format("h:mm A")}</span>
                                  </label>
                                ))}

                              </form>
                            </div>
                          </div>

                        )}
                        {eveningSlots.length > 0 && (

                          <div className="filter-box">
                            <label htmlFor="appointment-input">Evening</label>
                            <div className="radio-badge-part mt-0">
                              <form>
                                {eveningSlots?.map((slot, index) => (
                                  <label key={index}>
                                    <input type="radio" name="radio" disabled={isSlotDisabled(slot)}
                                      onClick={() => handleSlotSelect(slot)} />
                                    <span className={isSlotDisabled(slot) ? "disabled-slot" : ""}>{slot.start.format("h:mm A")}</span>
                                  </label>
                                ))}
                              </form>
                            </div>
                          </div>)}

                        {nightSlots.length > 0 && (

                          <div className="filter-box">
                            <label htmlFor="appointment-input">Night</label>
                            <div className="radio-badge-part mt-0">
                              <form>
                                {nightSlots?.map((slot, index) => (
                                  <label key={index}>
                                    <input type="radio" name="radio" disabled={isSlotDisabled(slot)}
                                      onClick={() => handleSlotSelect(slot)} />
                                    <span className={isSlotDisabled(slot) ? "disabled-slot" : ""}>{slot.start.format("h:mm A")}</span>
                                  </label>
                                ))}
                              </form>
                            </div>
                          </div>
                        )}



                      </form>
                    </div>
                  </div>
                </div>
                {/* <div className="bottom-part">
                  {(morningSlots?.length + eveningSlots?.length + nightSlots?.length) > 0 ? <Link to="/bookAppointment" state={{ doctor_id: doctorDetails?._id }}>View all Slots</Link> : ""}
                </div> */}


              </div>
              <div className="buttonPart mt-3 d-flex justify-content-center">
                {/* <Button className="theme-outline-btn" style={{ fontWeight: 300, marginInlineEnd: '20px' }}>
              Chat With Doctor
            </Button> */}
                {/* {(morningSlots?.length + eveningSlots?.length + nightSlots?.length)  > 0 ?
                  <Button className="theme-button" style={{ fontWeight: 300 }} onClick={(e) => {
                    e.preventDefault();
                    navigate("/bookAppointment", { state: { doctor_id: doctorDetails?._id  , selectedSlot: selectedSlot?.slot} })
                  }}>
                    Book Appointment
                  </Button> : ""} */}
              </div>
            </Col>
          </Row>

        </div>
      </div>
    </Layout>
  );
};


export default DoctorDetails
