import React from 'react'

import Patient from '../assets/images/patient.png'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FillStar from '../assets/images/star.svg'
import '../assets/css/commonComponent.css'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import DoctorDummy from "../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../assets/images/defaultpatient@3x.png"

const options = {
    loop: true,
    dots: false,
    margin: 0,
    items:4,
    slideBy:1,
    nav:true,
    mergeFit:true,autoWidth:true,
    navText:['<','>'],
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1.5,
        },
        700: {
            items: 1.5,
        },
        1000: {
            items: 2.2,
        },
        1366: {
            items: 4,
        },
        1520: {
            items: 4,
        }
    },
}

const AppointmentCard = (props) => {

    return (
<>
        {props?.physiotherapist && props?.physiotherapist.length <= 0 && 
            <div className='head-data' key={props?.physiotherapist?._id}>
                <h6 style={{fontSize:"14px"}}>No Appointment For the day</h6>
            </div> 
        }
        <div className='container-fluid row ' key={props?.physiotherapist?._id} >

                <OwlCarousel {...options}
                    className="owl-theme assign-slider col-12 overflow-hidden"
                >
                    {props?.physiotherapist && props?.physiotherapist.length >= 0 &&
                        props?.physiotherapist.map((item, index) => {
                            return (
                                <>
                                    <div key={index}>

                                        <Link    to='/doctorDetails' state={{id:item?._id,doctor:item}} className='doctorList'>
                                            <div className='assign-doctor-card'>
                                                <div className='doctor-card-content'>
                                                    <div className='image-part'>
                                                        <img src={item?.profile_image ? item?.profile_image : DoctorDummy} height="100px" width="125px"  style={{borderRadius:"50%"}} alt='Doctor' />
                                                    </div>
                                                    <div className='text-part'>
                                                        <h5 className='name'>{item?.name}    
                                                        
                                                      {item?.clinicVerified === true && 
                                                      <div
                                >

                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <circle cx="10" cy="10" r="10" fill="green" />
                                        <path d="M7 10 L9 12 L14 7" stroke="white" stroke-width="2" fill="transparent" />
                                    </svg>

                                    {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                                </div>}
                                                        </h5>
                                                        <p className='name'>{item?.designation}</p>
                                                        <div className='rating-part'>

                                                        {Array.from({ length: 5 }, (_, indexw) => (
        <i
          key={indexw}
          className={`star fa fa-star${indexw < item?.averageRating ? ' filled' : ''}`}
        ></i>
      ))}
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                     
                                                <div className='bottom-part'>
                                                    <p>{item?.totalAssignedCount} Time Assigned</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                </>
                            )
                        })
                    }

                </OwlCarousel>
            
        </div>
        </>
    )
}

export default AppointmentCard