import { Link, useNavigate } from "react-router-dom";
import NextIcon from "../../assets/images/left.svg";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";

function Appointment({ patient, appointmentList }) {
  const navigate = useNavigate();
  const [date, setDate] = useState(dayjs());

  return (
    <>
      <div className=" mb-1 ">
        <div className="innerInfo withoutOrange">
          <div
            className="doctorDetailsCard startdata "
            onClick={(e) => {
              e.preventDefault();
              navigate("/AppointmentPatient", {
                state: { patient, appointmentList },
              });
            }}
            style={{ cursor: "pointer",boxShadow:"rgba(0, 0, 0, 0.06) 0px 1px 15px" }}
          >
            <div className="rightPartData">
              <div className="infoField filed d-flex justify-content-between">
                <div className="data">
                  <h5>Appointment History</h5>
                </div>
                {/* <div className="data d-flex justify-content-end">
                  <Link
                    to="/AppointmentPatient"
                    state={{
                      patient: patient,
                      appointmentList: appointmentList,
                    }}
                    className="nextLink"
                  >
                    <img src={NextIcon} alt="next-icon" />
                  </Link>
                </div> */}
              </div>

              <div className="filed">
                <div className="session-card-content">
                    {appointmentList?.length <= 0 && <div className="text-center">No Records Found</div>}
                  {appointmentList?.length > 0 &&
                    appointmentList.map((session, index) => {
                      const isLastSession =
                        index === appointmentList.length - 1;
                      const sessionCreatedAt = moment(session?.created_at); // Assuming 'created_at' is a date string
                      const selectedDate = moment(date); // Assuming 'date' is the selected date in appropriate format
                      let currentDate = new Date(date);
                      let totalExercises = session?.removedExercises?.length;
                      let completedExercises = 0;

                      // Calculate completion ratio
                      const completionRatio =
                        totalExercises > 0
                          ? completedExercises / totalExercises
                          : 0;

                      // Determine background color based on completion ratio
                      let backgroundColor;
                      if (completionRatio === 1) {
                        backgroundColor = "#90ee906e"; // Light green for fully completed
                      } else if (completionRatio > 0) {
                        backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                      } else {
                        backgroundColor = "transparent"; // Default background for not completed
                      }
                      const isAnyExerciseIncomplete = session?.workout?.some(
                        (exercise) => {
                          // Check if the exercise has incomplete dates
                          return (
                            exercise?.date_Complted?.length === 0 ||
                            !exercise.date_Complted.includes(
                              moment(
                                currentDate?.setUTCHours(0, 0, 0, 0)
                              ).toISOString()
                            )
                          );
                        }
                      );
                      if (
                        moment(date?.$d).isSame(sessionCreatedAt, "day") ||
                        moment(date?.$d).isAfter(sessionCreatedAt)
                      ) {
                        return (
                          <>
                            <div
                              className={`exercise-card`}
                              key={index}
                              style={{
                                cursor: "pointer",
                                maxHeight: "15vh",
                                backgroundColor: backgroundColor,
                                boxShadow: "none",
                                borderBottom: isLastSession
                                  ? "none"
                                  : "1px solid #EAEAEA", // Conditionally apply borderBottom
                                borderRadius: "1px",
                                padding: "0px",
                              }}
                              onClick={() => {
                                // handleWorkoutClick(session)
                              }}
                            >
                              <div className="img-text-part">
                                <div className="text-part ps-3">
                                  <h6>{session?.appoinmentFor}</h6>
                                </div>
                              </div>
                              <div className="details">
                                <div
                                  className="text-part ps-3"
                                  style={{ textAlign: "end" }}
                                >
                                  <h6
                                    className="session-title"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {moment(
                                      session?.timeSlot,
                                      "HH:mm:ss"
                                    ).format("h:mm A")}
                                    &nbsp;
                                    {moment(session?.date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </h6>
                                  <p>Clinic</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Appointment;
