import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import check from "../assets/css/check.json";

const Animation = () => {
  const lottieRef = useRef();

  useEffect(() => {
    if (lottieRef.current) {
      const animationInstance = lottieRef.current;

      animationInstance.goToAndStop(0, true); 
      animationInstance.loop = false; 

      animationInstance.playSegments([0, check.op], true); 
    }
  }, []);

  return (
    <div style={{
        "margin-top": "-70px",
    "margin-bottom": "-70px"
    }}>
      <Lottie
        animationData={check}
        loop={false}
        lottieRef={lottieRef}
      />
    </div>
  );
};

export default Animation;
