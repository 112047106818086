import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { format, addDays, parseISO } from "date-fns";
import { Font } from "@react-pdf/renderer";
import Poppins from "../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../assets/fonts/Poppins-SemiBold.ttf";
import NotoSansGujarati from "../assets/fonts/NotoSansGujarati-Regular.ttf";
import NotoSansDevanagari from "../assets/fonts/NotoSansDevanagari-Regular.ttf";
import QRCODE from "../assets/images/QR.png";

Font.register({
  family: "Noto Sans Devanagari",
  src: NotoSansDevanagari,
});
Font.register({
  family: "Noto Sans Gujarati",
  src: NotoSansGujarati,
});
Font.register({
  family: "Poppins",
  src: Poppins,
});
Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins, fontWeight: 400 },  // Regular weight
    { src: PoppinsBold, fontWeight: 600 },  // Bold weight
  ]
});
const styles = StyleSheet.create({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between", // Space between the text and image
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
  },
  headerContent: {
    flexDirection: "column", // Make sure the text stacks vertically
    justifyContent: "start", // Vertical alignment
  },
  headerText: {
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: 700
  },
  monthHeader: {
    fontSize: 10,
    marginBottom: 10,
    fontFamily: "Poppins",
  },
  downloadText: {
    fontSize: 8,
    fontFamily: "Poppins",
    color: "grey",
  },
  headerImage: {
    justifyContent: "flex-end", // Align image to the end of its container
    alignItems:"flex-end"
  },
  qrCodeImage: {
    width: "80%", // Make the image take up 100% of its container's width
    height: "auto", // Maintain aspect ratio
    maxWidth: 100, // Set a maximum width to avoid the image getting too large
    maxHeight: 100, // Set a maximum height as well
  },
  page: {
    padding: 30,
  },
  header: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 5,
    fontFamily: "Poppins",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    fontFamily: "Poppins",
  },
  tableRow: {
    flexDirection: "row",
  },
  dateCol: {
    width: "3.33%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
  },
  exerciseNameCol: {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    textAlign: "center",
  },
  checkbox: {
    width: 10,
    height: 10,
    borderColor: "black",
    borderWidth: 1,
  },
  exerciseCard: {
    marginBottom: 10,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start", // Ensure content starts from the left
    alignItems: "flex-start", // Align items at the start of the cross axis
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: 1,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional shadow for depth
  },
  imageBlock: {
    width: "30%", // Set width to occupy 30% of the exerciseCard
    marginRight: 10, // Add some space between the image and the content
    height: "auto", // Set height to auto to maintain aspect ratio
  },
  videoThumbnail: {
    width: "100%", // Use 100% of the imageBlock width
    height: "auto", // Adjust height automatically to maintain aspect ratio
    borderRadius: "10%", // Keep your borderRadius if desired
  },
  contentBlock: {
    width: "70%", // Set width to occupy the remaining 70% of the exerciseCard
    display: "flex", // Use flex layout to organize content
    flexDirection: "column", // Stack content vertically
    justifyContent: "center", // Center content vertically
  },
  title: {
    fontSize: 10,
    marginBottom: 4,
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  description: {
    fontSize: 10,
    marginBottom: 5,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
    fontFamily: "Poppins",
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between", // Align left and right sections
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    borderTop: "1px solid black",
    marginHorizontal: 30, // Add padding if needed
    fontFamily: "Poppins",
  },
  footerLeft: {
    textAlign: "left", // Align text to the left
    fontSize: 7, // Adjust font size as needed
    marginTop:10
  },
  footerRight: {
    textAlign: "right", // Align text to the right
    fontSize: 10, // Adjust font size as needed
  },
  twoColumnRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  descriptionHalf: {
    width: "48%", // Half of the container with some margin for space between
    fontSize: 10,
    marginBottom: 5,
  }
});
const Watermark = ({ text }) => (
  <Text
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) rotate(-45deg)",
      color: "lightgrey",
      // Adjust opacity and font size to your liking
      opacity: 0.5,
      fontSize: 50,
      // Ensure the watermark is seen behind the content
      zIndex: -1,
    }}
  >
    {text}
  </Text>
);

const Header = ({
  PatientName,
  ProgramName,
  months,
  clinic_image,
  selectedPatientsDetail,
}) => (
  <View style={styles.headerContainer}>
    <View style={styles.headerImage}>
      <Image
        style={styles.qrCodeImage}
        src={{
          uri: clinic_image
            ? clinic_image
            : "https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/RecureMe.png",
          method: "GET",
          headers: { "Cache-Control": "no-cache" },
          body: "",
        }}
      />
    </View>{" "}
    <View style={styles.headerContent}>
      <Text style={styles.headerText}>
        {ProgramName}
      </Text>
      <Text style={styles.monthHeader}>{PatientName}</Text>
    </View>
    <View style={styles.headerImage}>
      <Image
        style={styles.qrCodeImage}
        src={QRCODE} // The actual image path
      />
      <Text style={styles.downloadText}>Download the App</Text>
      { ((selectedPatientsDetail?.email || selectedPatientsDetail?.phone))  ? (
      <Text style={[styles.title, styles.downloadText , { textAlign: 'right'}]}>
        Login With: {selectedPatientsDetail?.email || ''}{selectedPatientsDetail?.email && selectedPatientsDetail?.phone ? ' or ' : ''}{selectedPatientsDetail?.phone || ''}
      </Text>
    ) : null}
    </View>
  </View>
);

// A function to simulate breaking the exercises into different pages
const paginateExercises = (exercises, exercisesPerPage) => {
  const pages = [];
  for (let i = 0; i < exercises.length; i += exercisesPerPage) {
    pages.push(exercises.slice(i, i + exercisesPerPage));
  }
  return pages;
};
const Footer = ({ ProgramName, assignedBy }) => (
  <View fixed style={styles.footerContainer}>
    {/* Left side with ProgramName, Assigned Date, and Assigned By */}
    <View style={styles.footerLeft}>
      <Text style={{fontWeight:600}}>{ProgramName} ( assigned On: {format(new Date(), "PPP")} )</Text>
      <Text></Text>
      <Text>assigned by: {assignedBy || "Yogesh Patel"} <Text>printed On: {format(new Date(), "PPP")}</Text></Text>


    </View>
    
    {/* Right side with the current date (when the document is printed) */}
    <View style={styles.footerRight}>
    </View>
  </View>
);


// You can define a function or component to decide dynamically which font family to use
const getFontFamily = (language) => {
  switch (language) {
    // case 'HND':
    //   return 'Noto Sans Devanagari';
    case "GJR":
      return "Noto Sans Gujarati";
    default:
      return "Poppins"; // Default to Hindi if unsure
  }
};

const MyDocument = ({
  PatientName,
  ProgramName,
  cart,
  startDate,
  numberOfDays = 1,
  notes,
  language,
  clinic_image,
  selectedPatientsDetail,
}) => {
  const parsedStartDate = parseISO(startDate);
  const dates = Array.from({ length: numberOfDays }, (_, i) =>
    addDays(parsedStartDate, i)
  );
  const months = [...new Set(dates.map((date) => format(date, "MMM yyyy")))];
  // Assuming we can only fit a certain number of exercises per page
  const exercisesPerPage = 10;
  const exercisePages = paginateExercises(cart, exercisesPerPage);

  const paginatedExercises = [];

  // Group exercises into pages
  for (let i = 0; i < cart.length; i += exercisesPerPage) {
    paginatedExercises.push(cart.slice(i, i + exercisesPerPage));
  }
  return (
    <Document>
      {exercisePages.map((exercisesOnPage, pageIndex) => {
        return (
          <Page style={styles.page} orientation="portrait" key={pageIndex}>
            <Watermark text="RecureMe" />

            <Header
              PatientName={PatientName}
              ProgramName={ProgramName}
              months={months}
              clinic_image={clinic_image}
              selectedPatientsDetail={selectedPatientsDetail}
            />
            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => (
                <>
                   
                  {index === 0 &&
                    notes && ( // Conditionally render notes only on the first page of each month
                      <Text style={{ color: "red", fontSize: "12px" ,textAlign: 'center'}}>
                        **{notes}**
                      </Text>
                    )}
                  <View style={styles.exerciseCard} key={item._id}>
                    <View style={styles.imageBlock}>
                      {/* Thumbnail image here */}
                      <Image
                        style={styles.videoThumbnail}
                        src={{
                          uri: item.exerciseImage,
                          method: "GET",
                          headers: { "Cache-Control": "no-cache" },
                          body: "",
                        }}
                      />
                    </View>
                    <View style={styles.contentBlock}>
                      <Text style={styles.title}>{item.title}</Text>
                      <Text
                        style={[
                          styles.description,
                          { fontFamily: getFontFamily(language) },
                        ]}
                      >
                        {item.description}
                      </Text>
                      <View style={styles.twoColumnRow}>
    <Text style={styles.descriptionHalf}>Sets : {item.set}</Text>
    <Text style={styles.descriptionHalf}>Counts : {item.count}</Text>
  </View>
  <View style={styles.twoColumnRow}>
    <Text style={styles.descriptionHalf}>Hold Time : {item.hold}</Text>
    <Text style={styles.descriptionHalf}>Rest Time : {item.restTime}</Text>
  </View>

                      {item.time &&
                        Array.isArray(item.time) &&
                        item.time.length > 0 && (
                          <Text style={styles.description}>
                            Timings:{" "}
                            {item.time
                              .map((timeObj) => timeObj?.cat)
                              .join(", ")}
                          </Text>
                        )}

                      {/* Equipment list */}
                      {item.equipment && item.equipment.length > 0 && (
                        <Text style={styles.description}>
                          Equipment: {item.equipment.join(" - ")}
                        </Text>
                      )}
                    </View>
                  </View>
                </>
              ))}
      <Footer ProgramName={ProgramName} assignedBy="Yogesh Patel" />
          </Page>
        );
      })}

      {months.map((month, monthIndex) => (
        <Page style={styles.page} orientation="landscape" key={monthIndex}>
          <Watermark text="RecureMe" />
          <Header
            PatientName={PatientName}
            ProgramName={ProgramName}
            months={[month]}
          />
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.exerciseNameCol}>
                <Text style={styles.tableCell}>Exercise</Text>
              </View>
              {dates
                .filter((date) => format(date, "MMM yyyy") === month)
                .map((date, index) => (
                  <View style={styles.dateCol} key={index}>
                    <Text style={styles.tableCell}>{format(date, "dd")}</Text>
                  </View>
                ))}
            </View>
            {paginatedExercises.map((pageExercises, pageIndex) => (
              <React.Fragment key={pageIndex}>
                {pageExercises.map((exercise, exerciseIndex) => (
                  <View style={styles.tableRow} key={exerciseIndex}>
                    <View style={styles.exerciseNameCol}>
                      <Text style={styles.tableCell}>{exercise?.title}</Text>
                    </View>
                    {dates
                      .filter((date) => format(date, "MMM yyyy") === month)
                      .map((date, dateIndex) => (
                        <View style={styles.dateCol} key={dateIndex}>
                          {/* Render checkbox or any other content for dates */}
                        </View>
                      ))}
                  </View>
                ))}
              </React.Fragment>
            ))}
          </View>
          <Footer />
        </Page>
      ))}
    </Document>
  );
};

export default MyDocument;
