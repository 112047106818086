import Layout from "../layout";
import SearchIcon from "../assets/images/search.svg";
import Carousel from "react-bootstrap/Carousel";
import doctorPayment from "../assets/images/doctorPayment.png";
import portrait1 from "../assets/images/portrait1.png";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import React, { useEffect, useState } from "react";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { Link, useNavigate } from "react-router-dom";
import Credit from "../components/Credit";
import swal from "sweetalert";
import moment from "moment";

function MyPlan() {
  const [creditsUsageMonth, setCreditsUsageMonth] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set to true
  const navigate = useNavigate();
  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });
  const [cancelSubscriptionError, setCancelSubscriptionError] = useState(null);
  const [subscriptionMessage, setSubscriptionMessage] = useState(null);

  const [totalCredits, setTotalCredits] = useState(10);
  const [credits, setCredits] = useState(0);
  const [invoices, setInvoices] = useState();
  const getMyPlanDetails = async () => {
    try {
      const data = await get(`/api/myplan`, getAuthConfig());
      if (data?.status === 200) {
        setSelectedPlanDetails(data?.data?.msg);
        let newTotalCredits = data?.data?.msg?.creditsAdded; // Assuming 10 is the base total credits without additions
        // Iterate over creditPurchased array and sum up the creditsAdded
        if (
          data?.data?.creditPurchased &&
          data?.data?.creditPurchased.length > 0
        ) {
          data?.data?.creditPurchased.forEach((credit) => {
            if (credit?.creditsAdded) {
              newTotalCredits += credit.creditsAdded;
            }
          });
        }
        setTotalCredits(newTotalCredits);
      } else {
        // Handle the case where there is no plan data
        setSelectedPlanDetails(null);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
      // Handle other errors or no data case
      setSelectedPlanDetails(null);
    } finally {
      setLoading(false); // Set loading to false when the data fetching is complete
    }
  };

  const getInvoices = async () => {
    try {
      const data = await get(`/api/paid-invoices`, getAuthConfig());
      console.log(data, "datatat");
      //if (data?.status === 200) {
      setInvoices(data?.data?.invoices);
      //}
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false); // Set loading to false when the data fetching is complete
    }
  };
  console.log(invoices, "invoicesinvoices");
  useEffect(() => {
    getMyPlanDetails();
    getInvoices();
  }, [navigate]);

  useEffect(() => {
    if (selectedPlanDetails?.purchaseDate) {
      const purchaseDate = new Date(selectedPlanDetails.purchaseDate);
      const today = new Date();
      const startOfMonth = new Date(
        purchaseDate.getFullYear(),
        purchaseDate.getMonth(),
        purchaseDate.getDate()
      );

      // Calculate endOfMonth by first moving to the next month, then adjusting the day
      let tempDate = new Date(
        startOfMonth.getFullYear(),
        startOfMonth.getMonth() + 1,
        purchaseDate.getDate()
      );
      let endOfMonth;
      if (tempDate.getMonth() === startOfMonth.getMonth() + 2) {
        // This means the next month does not have the same day as purchaseDate, adjust to last day of next month
        endOfMonth = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0); // 0 will give last day of previous month
      } else {
        // The next month has the same day as purchaseDate
        endOfMonth = tempDate;
      }

      // If the purchase date is ahead in the current month, adjust the endOfMonth to the next equivalent date
      if (today.getDate() < purchaseDate.getDate()) {
        tempDate = new Date(
          startOfMonth.getFullYear(),
          startOfMonth.getMonth() + 2,
          purchaseDate.getDate()
        );
        if (tempDate.getMonth() !== startOfMonth.getMonth() + 2) {
          endOfMonth = new Date(tempDate.getFullYear(), tempDate.getMonth(), 0);
        } else {
          endOfMonth = tempDate;
        }
      }

      // Format the date as needed
      const monthStartStr = startOfMonth.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const monthEndStr = endOfMonth.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      setCreditsUsageMonth(`${monthStartStr} - ${monthEndStr}`);
    }
  }, [selectedPlanDetails]);

  useEffect(() => {
    if (selectedPlanDetails?.purchaseDate) {
      const purchaseDate = new Date(selectedPlanDetails.purchaseDate);
      // Add one month to the purchase date for the renewal date
      let renewalDate = new Date(
        purchaseDate.setMonth(purchaseDate.getMonth() + 1)
      );

      // If you want the renewal to happen at a specific time (e.g., 2:40 PM),
      // you can set hours and minutes specifically. Otherwise, just remove these lines.
      renewalDate.setHours(14); // 2 PM in 24-hour format
      renewalDate.setMinutes(40); // 40 minutes

      // Format the date as needed
      const renewalDateStr = renewalDate.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true, // Use AM/PM
      });

      setRenewalDate(renewalDateStr);
    }
  }, [selectedPlanDetails]);

  if (loading) {
    return (
      <Layout>
        <div className="preloader-whirlpool text-center align-items-center">
          <div className="whirlpool"></div>
        </div>
      </Layout>
    );
  }

  if (!selectedPlanDetails) {
    // Render some UI or message indicating that no plan details are available
    return (
      <Layout>
        <div className="text-center m-5">No plan details available.</div>
      </Layout>
    );
  }

  async function cancelSubscription() {
    setLoading(true);
    try {
      const response = await post(
        `/api/cancel-subscriptions`,
        {},
        getAuthConfig()
      );
      if (response?.status === 200) {
        setSubscriptionMessage(response?.data?.message);
        getMyPlanDetails();
      } else {
        setCancelSubscriptionError(
          "Failed to cancel subscription. Please try again."
        );
      }
    } catch (error) {
      setCancelSubscriptionError(
        error?.response?.data?.message ||
          "Failed to cancel subscription. Please try again."
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Layout>
        <Credit setCredit={setCredits} displayDiv={"none"} />
        <div
          className="main-content-part foot-page p-0 "
          defaultActiveKey="first"
          style={{ backgroundColor: "#fff9f7a8" }}
        >
          <div className="container mt-5">
            {cancelSubscriptionError && (
              <div className="alert alert-danger" role="alert">
                {cancelSubscriptionError}
              </div>
            )}

            {subscriptionMessage && (
              <div className="alert alert-success" role="alert">
                {subscriptionMessage}
              </div>
            )}

            <div className="card card-custom">
              <div className="card-body">
                <div className="summary-header d-flex justify-content-between">
                  <div>
                    <h4>
                      {selectedPlanDetails?.plan?.nickname
                        ? selectedPlanDetails?.plan?.nickname
                        : selectedPlanDetails?.plan?.item?.name}{" "}
                      Plan{" "}
                    </h4>
                    {selectedPlanDetails?.isActive && (
                      <h6 className="text-secondary">
                        Your next charge will be {renewalDate}.{" "}
                      </h6>
                    )}
                  </div>
                  {selectedPlanDetails?.isActive && (
                    <button
                      className="btn manage-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        swal({
                          title: "Are you sure?",
                          text: "Are You sure to cancel the subscription ",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willRefund) => {
                          if (willRefund) {
                            cancelSubscription();
                          } else {
                            // User clicked "Cancel" button, do nothing
                          }
                        });
                      }}
                    >
                      Manage Subscription
                    </button>
                  )}
                </div>

                <div className="summary-body my-2">
                  <div className="row g-4">
                    <div className="col-md-6">
                      {" "}
                      <span style={{ fontSize: "12px" }}>What's Included</span>
                    </div>
                    <div className="col-md-6 text-end">
                      {" "}
                      <span style={{ fontSize: "12px" }}>Price</span>
                    </div>
                  </div>
                  <div className="row g-4">
                    <div className="col-md-4">
                      {" "}
                      <span>Users</span>
                    </div>
                    <div className="col-md-4 text-center">
                      {" "}
                      <span>Physiotherapist * 3</span>
                    </div>
                    <div className="col-md-4 text-end">
                      {" "}
                      <span className="price-tag">
                        ₹{selectedPlanDetails?.price}/Month
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row g-4">
                    <div className="col-md-4">
                      {" "}
                      <span>Credits (In your Plan)</span>
                    </div>
                    <div className="col-md-4 text-center">
                      {" "}
                      <span>
                        Unlimited Exercise plan export <br /> Assign up to 10
                        Patients / Month
                      </span>
                    </div>
                    <div className="col-md-4 text-end">
                      {" "}
                      <span>&nbsp;</span>
                    </div>
                  </div>
                  <hr />

                  {data?.associatedEntity === "Individual" &&
                    selectedPlanDetails?.isActive && (
                      <>
                        <div className="row g-4">
                          <div className="col-md-4">
                            {" "}
                            <span>Add-On Credits</span>
                          </div>
                          <div className="col-md-4 text-center">
                            {" "}
                            <span>
                              Add Unlimited Patients (₹99/Patient) <br />{" "}
                              <button
                                className="btn btn-link"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (data?.address?.country === "India") {
                                    navigate("/creditPurchase");
                                  } else {
                                    navigate("/creditPurchaseStripe");
                                  }
                                }}
                              >
                                + Add Patient
                              </button>
                            </span>
                          </div>
                          <div className="col-md-4 text-end">
                            {" "}
                            <span className="price-tag">
                              ₹99 / Patient / Month
                            </span>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                  <div className="d-flex justify-content-between">
                    <span>Monthly Total</span>
                    <span className="price-tag">
                      ₹{selectedPlanDetails?.price}/Month
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {selectedPlanDetails?.isActive && (
              <div className="card card-custom my-3">
                <div className="card-body">
                  <div className="summary-header border-0 d-flex justify-content-between">
                    <div>
                      <span className="d-flex align-items-center">
                        {" "}
                        <h4>Credits Usage | </h4>
                        <h6 className="text-secondary">
                          &nbsp; {creditsUsageMonth}
                        </h6>
                      </span>
                    </div>
                    <span>Credits will renew: {renewalDate}</span>
                  </div>
                  <hr />

                  <div className="summary-header d-flex justify-content-between border-0">
                    <div>
                      <span className=""> Patient Credits</span>
                    </div>
                    <span>
                      {credits}/{totalCredits} Patients Remain
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="card card-custom my-3">
              <div className="card-body">
                <div className="summary-header border-0 d-flex justify-content-between">
                  <div>
                    <span className="d-flex align-items-center">
                      <h4>Invoices |</h4>
                    </span>
                  </div>
                </div>
                {selectedPlanDetails?.device === "iOS" && (
                  <span>Invoices are managed By the Apple Pay</span>
                )}

                {selectedPlanDetails?.device === "Android" && (
                  <span>Invoices are managed By the Google Play Store</span>
                )}

                {invoices?.length > 0 &&
                  selectedPlanDetails?.device === "Web" &&
                  invoices.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="d-flex justify-content-start">
                        <span
                          className="mx-4"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              item.short_url || item.hosted_invoice_url,
                              "_blank"
                            );
                          }}
                          style={{
                            cursor: "pointer",
                            color: "#FF6036",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Download
                        </span>

                        <span className="d-flex align-items-center">
                          {item.paid_at &&
                            moment(item.paid_at * 1000).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          {item.created &&
                            moment(item.created * 1000).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                        </span>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* {selectedPlanDetails && 
            <div className="container-fluid footer fixed-bottom bg-white px-5 py-2 shadow-lg">
            <div className="row align-items-center">
                <div className="col-8">
                <span className="summary-text">
                <h6>Summary</h6>
                    <h4>
                {selectedPlanDetails?.plan?.nickname} </h4></span>
                </div>
                <div className="col-2">
                <span className="price-text">₹ {(selectedPlanDetails?.plan?.amount ? selectedPlanDetails?.plan?.amount   : selectedPlanDetails?.plan?.unit_amount) / 100}/mo</span>
                </div>
                <div className="col-2 text-right">
                <button className="current-plan-btn"  onClick={(e) =>{
                    e.preventDefault();
                    navigate("/paymentForm", {
                                        state: { plan: selectedPlanDetails },
                                    });
                }}>Select Plan</button>
                </div>
            </div>
            </div> } */}
      </Layout>
    </>
  );
}
export default MyPlan;
