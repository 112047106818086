import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import UploadIcon from "../../assets/images/upload.svg";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getAuthConfig, post } from "../../libs/http-hydrate";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import isEmail from "validator/lib/isEmail";
import FieldValidationError from "../../common/FieldValidation";
import { isValidPhoneNumber } from "libphonenumber-js";
import doneImg from "../../assets/images/doneImg.png";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import Auth from "../../libs/auth";

const PersonalInformation = () => {
  const location = useLocation()
  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const [completeModal, setCompleteModal] = useState(false);
  const [completeModalAll, setCompleteModalAll] = useState(false);

  const [patient, setPatient] = useState({});
  const [personalInformation, setPersonalInformation] = useState({
    name: "",
    age: "",
    profile_image: "",
    email: "",
    phone: "",
    countryCode: "",
    country: "",
  });
  const [personalInformationError, setPersonalInformationError] = useState({
    name: false,
    age: false,
    profile: false,
    email: false,
    phone: false,
    common: false,
  });
  const name = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const age = useRef(null);
  const [existingPatient, setExistingPatient] = useState(null);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]); // Array to store individual OTP digits
  const otpInputRefs = useRef([]);
  const [otperrorMessage, setOTPErrorMessage] = useState("");
  const [showModal, setModalShow] = useState(false);
  const closeModal = () => setModalShow(false);
  const [permissionId, setpermissionId] = useState();
  const [isRequestingPermission, setIsRequestingPermission] = useState(false);
  const [isLoadingVer, setLoadingVer] = useState(false); // Add a loading state
  useEffect(() => {
    if (location?.state?.personalInformation) {
      setPersonalInformation(location?.state?.personalInformation);
    }
    // You might also want to handle operationalInformation if needed
  }, [location.state]);
  // Function to check if patient already exists
  const checkForExistingPatient = async () => {
    try {
      const formdata = new FormData();
      formdata.append("email", personalInformation.email);
      if (personalInformation?.phone?.trim() != "") {
        formdata.append("phone", `+${personalInformation.phone}`);
      } else {
        formdata.append("phone", ``);
      }

      const response = await post(
        "/checkForPatient",
        formdata,
        getAuthConfig()
      );

      if (response.status === 200) {
        if (personalInformation?.phone) {
          const formattedPhone = `+${personalInformation.phone}`; // Add plus sign
          navigate("/operationalInformation", {
            state: {
              personalInformation: {
                ...personalInformation,
                phone: formattedPhone,
              },
              operationalInformation:location?.state?.operationalInformation,
              contactInformation:location?.state?.contactInformation
            },
          });
        } else {
          navigate("/operationalInformation", {
            state: { personalInformation: personalInformation ,operationalInformation:location?.state?.operationalInformation,              contactInformation:location?.state?.contactInformation
            },
          });
        }
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        setPatient(error?.response?.data?.existingPatient);
        if (
          error?.response?.data?.existingPatient?.doctor_added !=
          user?._id
        ) {
        // Patient already exists
        swal({
          title: "Are you sure?",
          text: `From this email or phone user already Exists , Do you want to Add in your account ?`,
          icon: "warning",
          dangerMode: true,
          buttons: ["No", "Yes"],
        }).then(async (willDelete) => {
          if (willDelete) {
            try {
              if (
                error?.response?.data?.existingPatient?.doctor_added !=
                user?._id
              ) {
                handlesendRequestPermission(error?.response?.data?.existingPatient?._id);
              }
              // const newFormdata = new FormData();
              // newFormdata.append("patient_id", error?.response?.data?.existingPatient?._id)
              // const response = await post("/addDoctorToPatient", newFormdata, getAuthConfig());
              // if (response.status === 200) {
              //   setCompleteModal(true)
              // }
            } catch (error) {
              toast.error("Error adding patient to your account");

              console.error("Error adding patient to your account:", error);
            }
          } else {
          }
        });
      }
      else{
        setCompleteModalAll(true)
      }
        setExistingPatient(true);
      }
      console.error("Error checking for existing patient:", error);
    }
  };

  const handleContinue = () => {
    if (!personalInformation?.name) {
      setPersonalInformationError((p) => ({ ...p, name: true }));
      handleFieldError(name);
      return false;
    }
    if (!personalInformation?.age) {
      setPersonalInformationError((p) => ({ ...p, age: true }));
      handleFieldError(age);
      return false;
    }

    const isEmailProvided = personalInformation?.email?.trim() != "";
    const isPhoneProvided = personalInformation?.phone?.trim() != "";

    const isEmailValid = isEmailProvided && isEmail(personalInformation?.email);
    const isPhoneValid =
      isPhoneProvided &&
      isValidPhoneNumber(
        remove_first_occurrence(
          personalInformation?.phone,
          personalInformation?.countryCode
        ),
        personalInformation?.country?.toUpperCase()
      );

    if (isEmailProvided && !isEmailValid) {
      setPersonalInformationError((p) => ({ ...p, common: true }));
      handleFieldError(email); // Assuming you want to highlight the email field, you can change this to phone if needed
      return false;
    }
    if (isPhoneProvided && !isPhoneValid) {
      setPersonalInformationError((p) => ({ ...p, phone: true }));
      handleFieldError(phone);
      return false;
    }

    if (!personalInformation?.email && !personalInformation?.phone) {
      setPersonalInformationError((p) => ({ ...p, common: true }));
      handleFieldError(email);
      return false;
    }

    // if (!personalInformation?.email && !personalInformation?.phone) {
    //   setPersonalInformationError((p) => ({ ...p, common: true }))
    //   handleFieldError(email)
    //   return false;
    // }

    // if (!(personalInformation?.email && isEmail(personalInformation?.email))) {

    //   setPersonalInformationError((p) => ({ ...p, email: true }))
    //   handleFieldError(email)
    //   return false;
    // }

    // if(personalInformation?.phone){

    //   console.log("ewoekwoeewew",isValidPhoneNumber(remove_first_occurrence(personalInformation?.phone,personalInformation?.countryCode),personalInformation?.country))

    //   setPersonalInformationError((p) =>({...p,phone:false}))
    //   handleFieldError(phone)
    //   return false;
    // }

    // Call the checkForExistingPatient function before proceeding
    checkForExistingPatient();

    // Now you can proceed based on the existingPatient status
    if (existingPatient === false) {
      // Patient doesn't exist, continue with the logic
      // For example, navigate to the next page
    } else if (existingPatient === true) {
      // Patient already exists, show appropriate message to the user
    }
  };

  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector("input");
    if (inputField) {
      // inputField.classList.add('field-error'); // Add the error class to the input field
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector("select");
      if (selectField) {
        selectField.classList.add("field-error"); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  function remove_first_occurrence(str, searchstr) {
    var index = str.indexOf(searchstr);
    if (index === -1) {
      return str;
    }
    return str.slice(0, index) + str.slice(index + searchstr.length);
  }

  // Handle input change for each OTP digit
  const handleOtpChange = (index, value) => {
    // If the user enters a non-digit value or clears the input, set the corresponding digit to an empty string
    const newValue = /^\d$/.test(value) ? value : "";
    setOtpDigits((prevDigits) => {
      const newDigits = [...prevDigits];
      newDigits[index] = newValue;
      return newDigits;
    });

    // Move to the next input field if a digit is entered
    if (/^\d$/.test(value) && otpInputRefs.current[index + 1]) {
      otpInputRefs.current[index + 1].focus();
    }

    setOTPErrorMessage("");
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").trim(); // Get the pasted text

    // Split the pasted text into individual characters
    const otpCharacters = pastedText.split("");

    // Loop through the OTP input elements and assign characters
    otpCharacters.forEach((character, index) => {
      if (index < otpDigits.length) {
        // Update the OTP digit at the current index
        handleOtpChange(index, character);
      }
    });
  };
  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otpDigits[index]) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index - 1] = ""; // Clear the previous box
      setOtpDigits(newOtpDigits); // Update state if using React hooks or similar state management
      otpInputRefs.current[index - 1].focus(); // Move focus to previous input
    }
  };

  async function handlesendRequestPermission(id) {
    setIsRequestingPermission(true); // Start loading
    const formData = new FormData();
    formData.append("patientId", id);
    const url = "/addDoctorToPatient";
    try {
      const data = await post(url, formData, getAuthConfig());
      if (data?.status === 200) {
        setpermissionId(data?.data?.permissionId);
        setModalShow(true);
      }
    } catch (error) {
      // Handle any errors
    } finally {
      setIsRequestingPermission(false); // Stop loading
    }
  }

  async function verifyOTP() {
    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (!isAllFieldsFilled) {
      setOTPErrorMessage("Please Add Correct OTP");
      return false;
    }
    setLoadingVer(true); // Set loading to true at the beginning of the API call
    const formdata = new FormData();
    formdata.append("permissionId", permissionId);
    formdata.append("otp", otpDigits.join(""));

    try {
      const response = await post(
        "/verifyOTPForAddingPatient",
        formdata,
        getAuthConfig()
      )
        .then((data) => {
          if (data?.status === 200) {
            closeModal();
            setCompleteModal(true);
            setPatient((p) => ({ ...p, allowedPermission: true }));
          }
        })
        .catch((err) => {
          setOtpDigits(["", "", "", ""]); // Reset OTP digits
          setOTPErrorMessage(err?.response?.data?.err);
          if (otpInputRefs.current[0]) {
            otpInputRefs.current[0].focus();
          }
        })
        .finally(() => {
          setLoadingVer(false); // Set loading to false after API call completion
        });
    } catch (error) {
      setLoadingVer(false); // Ensure loading is set to false if an error occurs
    }
  }
  return (
    <>
      <ToastContainer />
      <Layout>
        <TopBar
          title={"Personal Information"}
          tab={"Add Exercises"}
          tabChild={"Add Patient"}
        />

        <div className="main-content-part">
          <div className="form-data">
            {/* <h5 className="title">Personal Information</h5> */}
            <div className="filed" ref={name}>
              <label>
                Patient Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={` ${
                  personalInformationError?.name ? "field-error" : ""
                }`}
                placeholder="Jack Williams"
                value={personalInformation?.name}
                onChange={(e) => {
                  e.preventDefault();
                  setPersonalInformation((p) => ({
                    ...p,
                    name: e.target.value,
                  }));
                  setPersonalInformationError((p) => ({ ...p, name: false }));
                }}
              />
              {personalInformationError?.name && (
                <FieldValidationError message="Please enter name" />
              )}
            </div>
            <div className="filed" ref={age}>
              <label>
                Age <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`input-field ${
                  personalInformationError?.age ? "field-error" : ""
                }`}
                placeholder="25"
                value={personalInformation?.age}
                onChange={(e) => {
                  e.preventDefault();
                  const value = e.target.value;
                  if (value === '' || /^[0-9]*$/.test(value)) {
                    let newAge = value === '' ? '' : parseInt(value, 10);
                
                    // Check if the new age is within the range 0 to 100
                    if (newAge === '' || (newAge >= 0 && newAge <= 100)) {
                      setPersonalInformation((p) => ({
                        ...p,
                        age: newAge,
                      }));
                      setPersonalInformationError((p) => ({ ...p, age: false }));
                    } else if (newAge > 100) {
                      // If age is more than 100, set age to 100
                      setPersonalInformation((p) => ({
                        ...p,
                        age: 100,
                      }));
                      setPersonalInformationError((p) => ({ ...p, age: false }));
                    }
                  } else {
                    // Handle invalid input
                    setPersonalInformationError((p) => ({ ...p, age: true }));
                  }
                }}
              />
              {personalInformationError?.age && (
                <FieldValidationError message="Please enter valid age" />
              )}
            </div>

            <Row className="g-4">
              <Col lg="6">
                <div className="filed" ref={email}>
                  <label>Email Id</label>
                  <input
                    type="text"
                    className={`input-field ${
                      personalInformationError?.email ? "field-error" : ""
                    }`}
                    placeholder="jackwilliams@gmail.com"
                    value={personalInformation?.email}
                    onChange={(e) => {
                      e.preventDefault();
                      setPersonalInformation((p) => ({
                        ...p,
                        email: e.target.value,
                      }));
                      setPersonalInformationError((p) => ({
                        ...p,
                        email: false,
                      }));
                      setPersonalInformationError((p) => ({
                        ...p,
                        common: false,
                      }));
                    }}
                  />
                  {personalInformationError?.email && (
                    <FieldValidationError message="Please enter valid email" />
                  )}
                </div>
              </Col>
              <Col lg="6">
                <div className="filed" ref={phone}>
                  <label>Patient Mobile Number</label>

                  <PhoneInput
                    country={"in"}
                    className={` ${
                      personalInformationError?.phone ? "field-error" : ""
                    }`}
                    onChange={(phone, country) => {
                      setPersonalInformation((p) => ({
                        ...p,
                        countryCode: country?.dialCode,
                      }));
                      setPersonalInformation((p) => ({
                        ...p,
                        country: country?.countryCode,
                      }));

                      setPersonalInformation((p) => ({ ...p, phone: phone }));
                      if (personalInformationError?.phone) {
                        setPersonalInformationError((p) => ({
                          ...p,
                          phone: false,
                        }));
                      }
                      if (personalInformationError?.common) {
                        setPersonalInformationError((p) => ({
                          ...p,
                          common: false,
                        }));
                      }
                    }}
                  />
                  {personalInformationError?.phone && (
                    <FieldValidationError message="Please enter valid mobile number" />
                  )}
                </div>
              </Col>
            </Row>
            {personalInformationError?.common && (
              <FieldValidationError message="Please enter email / mobile number" />
            )}

            <div className="filed">
              <label>Add Profile Image</label>
              <div className="upload-part mt-2">
                <div className="form-group file-area">
                  <input
                    type="file"
                    name="images"
                    id="images"
                    required="required"
                    accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                    onChange={(e) => {
                      const files = e.target.files;
                      let isValid = true;
                    
                      for (let i = 0; i < files.length; i++) {
                        const fileType = files[i].type;
                        const fileSize = files[i].size;
                    
                        if (
                          fileType !== "image/png" &&
                          fileType !== "image/jpg" &&
                          fileType !== "image/jpeg" &&
                          fileType !== "image/gif" &&
                          fileType !== "application/pdf" &&
                          fileType !== "application/msword" &&
                          fileType !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ) {
                          alert("Only images, PDF, DOC, and DOCX are allowed");
                          isValid = false;
                          break;
                        }
                    
                        if (fileSize > 2097152) { // 2 MB in bytes
                          alert("File size should not be more than 2 MB");
                          isValid = false;
                          break;
                        }
                      }
                    
                      if (isValid) {
                        setPersonalInformation((p) => ({
                          ...p,
                          profile_image: e.target.files[0], // or handle all files if needed
                        }));
                      } else {
                        // Optionally reset the input if invalid file is selected
                        e.target.value = null;
                      }
                    }}
                    
                  />
                  <div className="file-dummy">
                    <div className="success">
                      Great, your files are selected. Keep on.
                    </div>
                    <div className="default">
                      <div className="image-block mb-2">
                        <img src={UploadIcon} alt="icon" />
                      </div>
                      Add profile here (png, jpg, pdf)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonPart mt-5 d-flex justify-content-center">
              <Button
                className="theme-button"
                disabled={isRequestingPermission}
                onClick={(e) => {
                  e.preventDefault();
                  handleContinue();
                }}
              >
                {isRequestingPermission
                  ? "Requesting Permission..."
                  : "Continue"}
              </Button>
            </div>
          </div>
        </div>

        <Modal
          show={completeModalAll}
          onHide={() => {
            setCompleteModalAll(false);
            navigate("/allPatient");
          }}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteModalAll(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
              <img src={doneImg} height={300} width={350} alt="modal-video" />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Patient Already in your Account</p>

                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/allPatient");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Body style={{ border: "none" }}>
            <div className="content-modal">
              <div className="top-btn-part">
                <button className="btn close-btn" onClick={closeModal}>
                  <AiOutlineClose size={20} color="#000" />
                </button>
              </div>
              <div className="modal-inside-content">
                <h5 className="heading">Authentication Code Sent to patient's email</h5>
                <p className="desc-text">
                  4 digit OTP has sent you patient's {patient?.email ?  "Email Address": "Phone Number"}{" "}
                </p>
                <p className="mobileno" style={{ cursor: "pointer" }}>
                {patient?.email ?  patient?.email : patient?.phone}   </p>
                <div className="otp-input-group" onPaste={handlePaste}>
                  {otpDigits.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      autoFocus={index === 0}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) => handleBackspace(index, e)}
                      ref={(el) => (otpInputRefs.current[index] = el)} // Store refs to OTP input elements
                    />
                  ))}
                </div>
                {otperrorMessage && (
                  <FieldValidationError message={otperrorMessage} />
                )}
                <button className="btn-continue" onClick={verifyOTP}>
                  {isLoadingVer ? "Verifying OTP" : "Continue"}
                </button>
                {/* <span className="notreceive-text">
                  Haven't you received the Email?
                </span>
                <button
                  className="resend-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    //   sendOtp()
                  }}
                >
                  Resend Code
                </button> */}
                {/* <p className="timer">{`${Math.floor(timerSeconds / 60)}:${(timerSeconds % 60)
                  .toString()
                  .padStart(2, '0')}`}</p>     */}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={completeModal}
          onHide={() => {
            setCompleteModal(false);
          }}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => {
                  setCompleteModal(false);
                  navigate("/allPatient");
                }}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
              <img src={doneImg} height={300} width={350} alt="modal-video" />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Request Approved successfully</p>

                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setCompleteModal(false);
                    navigate("/allPatient");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
};

export default PersonalInformation;
