import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import doneImg from '../../assets/images/Opps.svg'; // Import the image
import { useNavigate } from 'react-router-dom';

const ProfileCompletionModal = ({ show, onHide , From }) => {
  const navigate = useNavigate()
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="complete-modal"
      centered
    >
      <Modal.Header>
        <div className="top-heading w-100 d-flex justify-content-end text-end">
          <button className="btn btn-transpatent" onClick={onHide}>
            X
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="content-block text-center">
          <img src={doneImg} height={60} width={60} alt="modal-video" />
        </div>
        <div className="bottom-block text-center">
          <div
            className="workout-desc body-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "25px",
            }}
          >
            <p>Opps!</p>
            <span>Your Profile is not completed yet.</span>
            <div className='rounded py-3 px-3 my-2 text-start' style={{ width: "70%", border: "1px solid #eaeaea" }}>
              <span style={{ fontSize: "13px" }}>Profile Completeness:</span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="progressBar pt-1" style={{ flex: 1 }}>
                  <ProgressBar now={30} />
                </div>
                <span style={{ fontSize: '13px', marginLeft: '10px' }}>30%</span>
              </div>
            </div>
            <span style={{ fontSize: '12px', marginLeft: '10px' }}>Complete Profile to access {From}</span>
            <button
              className="theme-button btn btn-primary mt-3"
              style={{ minWidth: "300px", padding: "14px" }}
              onClick={() => navigate("/profileData")}
            >
              Complete Profile
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileCompletionModal;
