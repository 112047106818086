// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.foot-page{
    overflow-x: hidden;
    /* height: 100vh; */
}
.foot-sidebar{
    background-color: #fff;
    box-shadow: 0px 1px 15px #0000000f;
    padding: 30px 24px;
    margin-top: 1px;
    /* height: 86vh; */
}
.foot-page .nav.nav-pills{
    margin-top: 30px;
}
.foot-page .nav.nav-pills .nav-item{
    box-shadow: 0px 1px 15px #0000000f;
    padding: 16px;
    margin-bottom: 15px;
    border-radius: 14px;
}
.foot-page .nav.nav-pills  .nav-link{
    font-size: 14px;
    font-weight: 500;
    color: #1D2432;
}
.foot-page .nav.nav-pills  .nav-link.active{
    background-color: transparent;
}

.sidebarCheckbox > label::after {
    top:-14px !important;
}

.sidebarCheckbox >  label::before {
    top:-22px !important;
}

.scrollable-column {
    max-height: calc(100vh - 150px); /* Adjust 150px to the combined height of your header/footer */
    min-height: calc(100vh - 150px); /* Same adjustment as max-height */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
}

.accordion-button {
    background: #cfe2ff00 !important;
    width: max-content !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/foot.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,kCAAkC;IAClC,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kCAAkC;IAClC,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,6BAA6B;AACjC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,+BAA+B,EAAE,8DAA8D;IAC/F,+BAA+B,EAAE,kCAAkC;IACnE,gBAAgB,EAAE,8BAA8B;IAChD,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;IAChC,6BAA6B;AACjC","sourcesContent":[".foot-page{\r\n    overflow-x: hidden;\r\n    /* height: 100vh; */\r\n}\r\n.foot-sidebar{\r\n    background-color: #fff;\r\n    box-shadow: 0px 1px 15px #0000000f;\r\n    padding: 30px 24px;\r\n    margin-top: 1px;\r\n    /* height: 86vh; */\r\n}\r\n.foot-page .nav.nav-pills{\r\n    margin-top: 30px;\r\n}\r\n.foot-page .nav.nav-pills .nav-item{\r\n    box-shadow: 0px 1px 15px #0000000f;\r\n    padding: 16px;\r\n    margin-bottom: 15px;\r\n    border-radius: 14px;\r\n}\r\n.foot-page .nav.nav-pills  .nav-link{\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    color: #1D2432;\r\n}\r\n.foot-page .nav.nav-pills  .nav-link.active{\r\n    background-color: transparent;\r\n}\r\n\r\n.sidebarCheckbox > label::after {\r\n    top:-14px !important;\r\n}\r\n\r\n.sidebarCheckbox >  label::before {\r\n    top:-22px !important;\r\n}\r\n\r\n.scrollable-column {\r\n    max-height: calc(100vh - 150px); /* Adjust 150px to the combined height of your header/footer */\r\n    min-height: calc(100vh - 150px); /* Same adjustment as max-height */\r\n    overflow-y: auto; /* Enable vertical scrolling */\r\n    overflow-x: hidden;\r\n}\r\n\r\n.accordion-button {\r\n    background: #cfe2ff00 !important;\r\n    width: max-content !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
