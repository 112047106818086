import React, { useEffect, useState } from 'react'
import '../../assets/css/login.css';
import doctorsImg from '../../assets/images/Group 3038.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from '../Login';
import LoginPage from '../LoginPage/Login';
import Email from '../Login/Email';
import Mobile from './Mobile';


const SignupHomeComb = () => {
    const navigate = useNavigate();
    const [mobile,setMobile]= useState(false)
    const location = useLocation();
    useEffect(() =>{
      if(location?.state?.mobile)
      {
        setMobile(true)
      }
      if(location?.state?.email){
        setMobile(false)
      }
    },[location?.state])
   
    return (
        <div className='login-main'>
        <div className="content-wrapper">
          <div className="banner-part">
            <div className="banner-content">
              <img src={doctorsImg} alt="doctors-logo" />
              <div className="text-part">
                <h4>Serving Over Thousands of Doctors and Physiotherapists</h4>
                <p>Elevate your practice with RecureMe. Streamline patient management, create custom exercise programs, and monitor patient progress in real-time. Deliver high-quality care, efficiently and remotely.</p>
              </div>
            </div>
          </div>
          <div className="form-part">

             

          {mobile === true ?  <Mobile mobile={mobile} setMobile={setMobile}/> : <Email  setMobile={setMobile}/>}
          </div>
          </div>
          </div>
    )
}

export default SignupHomeComb
