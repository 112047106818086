import React from 'react'
import { ReactComponent as CloseCircleLineIcon } from '../assets/images/cross.svg'

export default function FieldValidationError (props) {
  const { name, message } = props
  return (
    <p className='error-message mb-0'>
      {
        message && <><CloseCircleLineIcon />{message}</>
      }
      {
        name && <><CloseCircleLineIcon /> {`Please enter valid ${name}`}</>
      }
    </p>
  )
}
