
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function TimingComponent(props) {
    const [timing,setTiming]= useState({
        startTime:"",
        endTime:""
    });


    return (
        <>
        
        
    


            <div className='timer-block d-flex mt-2 ' style={{ width: "100%" }}>
                <div className='' style={{ width: "100%" }}>

                    <label style={{ fontWeight: 400 }}>Open At</label>

                    <Form.Select aria-label="Default select example w-100" value={props?.timeSlot?.startTime} onChange={(e) => props?.handleChange(props?.index, 'startTime', e.target.value)}>
                    <option> Start Time</option>
                        
                     <option value={"08:30:00"}>8:30 AM</option>
                        <option value={"09:00:00"}>9:00 AM</option>
                        <option value={"09:30:00"}>9:30 AM</option>
                        <option value={"10:00:00"}>10:00 AM</option>
                        <option value={"10:30:00"}>10:30 AM</option>
                        <option value={"11:00:00"}>11:00 AM</option>
                        <option value={"11:30:00"}>11:30 AM</option>
                        <option value={"12:00:00"}>12:00 PM</option>
                        <option value={"12:30:00"}>12:30 PM</option> 
                        <option value={"13:00:00"}>1:00 PM</option>
                        <option value={"13:30:00"}>1:30 PM</option>
                        <option value={"14:00:00"}>2:00 PM</option>
                        <option value={"14:30:00"}>2:30 PM</option>
                        <option value={"15:00:00"}>3:00 PM</option>
                        <option value={"15:30:00"}>3:30 PM</option>
                        <option value={"16:00:00"}>4:00 PM</option>
                        <option value={"16:30:00"}>4:30 PM</option>
                        <option value={"17:00:00"}>5:00 PM</option>
                        <option value={"17:30:00"}>5:30 PM</option>
                        <option value={"18:00:00"}>6:00 PM</option>
                        <option value={"18:30:00"}>6:30 PM</option>
                        <option value={"19:00:00"}>7:00 PM</option>
                        <option value={"19:30:00"}>7:30 PM</option>
                        <option value={"20:00:00"}>8:00 PM</option>
                        <option value={"20:30:00"}>8:30 PM</option>
                        <option value={"21:00:00"}>9:00 PM</option>
                        <option value={"21:30:00"}>9:30 PM</option>   
                        <option value={"22:00:00"}>10:00 PM</option>
                        <option value={"22:30:00"}>10:30 PM</option>
                    </Form.Select>
                </div>
                <div className='ms-3' style={{ width: "100%" }}>
                    <label style={{ fontWeight: 400 }}>Close At</label>

                    <Form.Select aria-label="Default select example"
                    
                    value={props?.timeSlot?.endTime} 
                    onChange={(e) => {


                    
                        // console.log(new Date(`01/01/2011 ${props?.timeSlot?.startTime}`))

                        //  if (new Date(`01/01/2011 ${e.target.value}`) <= new Date(`01/01/2011 ${props?.timeSlot?.startTime}`)) {
                        //     toast.error("End Time of the slots should be greater than start Time of slot")
                        //     return;
                        //   }
                        //   else{
                            props?.handleChange(props?.index, 'endTime', e.target.value)}

                        //   }  
                        }>
                    <option> End Time</option>

                     <option value={"08:30:00"}>8:30 AM</option>
                        <option value={"09:00:00"}>9:00 AM</option>
                        <option value={"09:30:00"}>9:30 AM</option>
                        <option value={"10:00:00"}>10:00 AM</option>
                        <option value={"10:30:00"}>10:30 AM</option>
                        <option value={"11:00:00"}>11:00 AM</option>
                        <option value={"11:30:00"}>11:30 AM</option>
                        <option value={"12:00:00"}>12:00 PM</option>
                        <option value={"12:30:00"}>12:30 PM</option> 
                        <option value={"13:00:00"}>1:00 PM</option>
                        <option value={"13:30:00"}>1:30 PM</option>
                        <option value={"14:00:00"}>2:00 PM</option>
                        <option value={"14:30:00"}>2:30 PM</option>
                        <option value={"15:00:00"}>3:00 PM</option>
                        <option value={"15:30:00"}>3:30 PM</option>
                        <option value={"16:00:00"}>4:00 PM</option>
                        <option value={"16:30:00"}>4:30 PM</option>
                        <option value={"17:00:00"}>5:00 PM</option>
                        <option value={"17:30:00"}>5:30 PM</option>
                        <option value={"18:00:00"}>6:00 PM</option>
                        <option value={"18:30:00"}>6:30 PM</option>
                        <option value={"19:00:00"}>7:00 PM</option>
                        <option value={"19:30:00"}>7:30 PM</option>
                        <option value={"20:00:00"}>8:00 PM</option>
                        <option value={"20:30:00"}>8:30 PM</option>
                        <option value={"21:00:00"}>9:00 PM</option>
                        <option value={"21:30:00"}>9:30 PM</option>   
                        <option value={"22:00:00"}>10:00 PM</option>
                        <option value={"22:30:00"}>10:30 PM</option>
                    </Form.Select>
                </div>

                <div className='ms-3 mt-4' style={{ width: "10%" ,display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Button variant="danger" onClick={() => props?.handleDelete(props?.index)}>
                ✕            </Button>
                  
                </div>



            </div>

            <div className='filed  mt-2 '>
                                        <label>Duration</label>
                                        <Form.Select aria-label="Default select example"   value={props?.timeSlot?.duration}  onChange={(e) => {
                                            e.preventDefault();
                                            props?.handleChange(props?.index, 'duration', e.target.value)
                                        }}>
                                            <option value={""}>Select duration</option>
                                            <option value={"05"}>5:00 min</option>
                                            <option value={"15"}>15:00 min</option>
                                            <option value={"35"}>35:00 min</option>
                                            <option value={"45"}>45:00 min</option>
                                            <option value={"60"}>60:00 min</option>

                                        </Form.Select>
                                    </div>
</>
    )
}

export default TimingComponent;