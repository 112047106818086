import React, { useState } from "react";
import TopBarNavigation from "../components/TopBarNavigation";
import Layout from "../layout";
import { Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import { getAuthConfig, post } from "../libs/http-hydrate";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { useNavigate } from "react-router-dom";

function Ticket() {
  const [subject, setSubject] = useState("");
  const [issue, setIssue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate()
  const handleSubmit = async () => {
    // Clear previous error and success messages
    setError("");
    setSuccess("");

    // Validate input fields
    if (!subject.trim() || !issue.trim()) {
      setError("Subject and Issue are required.");
      return;
    }

    try {
      setLoading(true);
      // API call to create a ticket
      const response = await post(
        "/tickets",
        {
          title: subject,
          description: issue,
        },
        getAuthConfig()
      );

      // Handle successful response
      setSuccess("Ticket generated successfully!");
      setTimeout(() =>{
        navigate("/support")
      },2000)
      setSubject("");
      setIssue("");
    } catch (err) {
      // Handle error response
      setError(err.response?.data?.error || "Error creating ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <TopBarNavigation title={"Support"} tab={"Generate"} tabChild={"Ticket"} />
      <div className="main-content-part container">
        <div className="form-data container mt-5">
        
          <div className="filed">
            <label>Subject</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>

          <div className="filed">
            <label>Your Issue</label>
            <input
              type="text"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            />
          </div>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <div className="buttonPart mt-5 d-flex justify-content-center">
            <Button
              className="theme-button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Generating Ticket...
                </>
              ) : (
                "Generate Ticket"
              )}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Ticket;
