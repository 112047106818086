import React, { useEffect, useRef, useState } from "react";
// import '../../assets/css/home.css'
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import Patient from "../../assets/images/patientInfo.png";
import Star from "../../assets/images/star.svg";
import Location from "../../assets/images/location.svg";
import Call from "../../assets/images/call.svg";
import Chat from "../../assets/images/chat.svg";
import User from "../../assets/images/user.svg";
import Verify from "../../assets/images/verify.svg";
import Rating from "../../assets/images/rating.svg";
import "../../assets/css/doctorInfo.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import PDFIcon from "../../assets/images/pdf.svg";
import { useLocation, Link, useNavigate } from "react-router-dom";
import NextIcon from "../../assets/images/left.svg";
import EditIcon from "../../assets/images/edit.svg";
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png";
import PatientDummy from "../../assets/images/defaultpatient@3x.png";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Auth from "../../libs/auth";
import { Modal, Badge } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import FieldValidationError from "../../common/FieldValidation";
import { get, getAuthConfig, post } from "../../libs/http-hydrate";
import doneImg from "../../assets/images/doneImg.png";
import defaultSVG from "../../assets/images/defaultWorkout.jpg";
import dayjs from "dayjs";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
import Exercises from "../../assets/images/Exercises.svg";
import GlobalPhysio from "../../assets/images/Globle Physio.svg";
import ClinicTeam from "../../assets/images/Clinic Team.svg";
import { FaRegEnvelope, FaUserEdit } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { BsGeoAlt } from "react-icons/bs";
import OverView from "./OverView";
import Workout from "./Workout";
import Appointment from "./Appointment";
import Documents from "./Documents";
import { MdPersonRemoveAlt1 } from "react-icons/md";
import Animation from "../../Animation/Animation";

const PatientDetails = () => {
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [date, setDate] = useState(dayjs());
  const navigate = useNavigate();
  const location = useLocation();
  const user = Auth.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [patient, setPatient] = useState({});
  const [activeTab, setActiveTab] = useState(
    patient?.allowedPermission && "overview"
  );
  const [appointmentList, setAppointmentList] = useState([]);
  const [workoutHistory, setWorkoutHistory] = useState([]);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]); // Array to store individual OTP digits
  const otpInputRefs = useRef([]);
  const [otperrorMessage, setOTPErrorMessage] = useState("");
  const [showModal, setModalShow] = useState(false);
  const closeModal = () => setModalShow(false);
  const [showEditModal, setShowEditModal] = useState(false); // State for edit modal

  const [showAssignModal, setShowAssignModal] = useState(false);
  const closeAssignModal = () => setShowAssignModal(false);

  const [isLoadingVer, setLoadingVer] = useState(false); // Add a loading state
  const [permissionId, setpermissionId] = useState();
  const [isRequestingPermission, setIsRequestingPermission] = useState(false);

  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });
  useEffect(() => {
    if (location?.state?.patient) {
      getPatientDetail(location?.state?.patient?._id);
    }
  }, [location?.state?.patient]);

  async function getPatientDetail(patientId) {
    try {
      setLoading(true);
      const data = await get(`/patientDetail?id=${patientId}`, getAuthConfig());
      if (data?.status === 200) {
        setPatient(data?.data?.msg);

        if (
          data?.data?.msg?.workoutGivenBy === null ||
          data?.data?.msg?.workoutGivenBy === user?._id ||
          data?.data?.msg?.allowedPermission
        ) 
        {
          setActiveTab("overview");

        }
        // if (data?.data?.msg?.allowedPermission === true) {
        //   setActiveTab("overview");
        // }
        if (data?.data?.appointmentList) {
          setAppointmentList(data?.data?.appointmentList);
        }
        if (data?.data?.workoutHistories) {
          setWorkoutHistory(data?.data?.workoutHistories);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const handleEditModalOpen = () => {
    setShowEditModal(true); // Open edit modal
  };

  const handleEditModalClose = () => {
    setShowEditModal(false); // Close edit modal
  };

  // Handle input change for each OTP digit
  const handleOtpChange = (index, value) => {
    // If the user enters a non-digit value or clears the input, set the corresponding digit to an empty string
    const newValue = /^\d$/.test(value) ? value : "";
    setOtpDigits((prevDigits) => {
      const newDigits = [...prevDigits];
      newDigits[index] = newValue;
      return newDigits;
    });

    // Move to the next input field if a digit is entered
    if (/^\d$/.test(value) && otpInputRefs.current[index + 1]) {
      otpInputRefs.current[index + 1].focus();
    }

    setOTPErrorMessage("");
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").trim(); // Get the pasted text

    // Split the pasted text into individual characters
    const otpCharacters = pastedText.split("");

    // Loop through the OTP input elements and assign characters
    otpCharacters.forEach((character, index) => {
      if (index < otpDigits.length) {
        // Update the OTP digit at the current index
        handleOtpChange(index, character);
      }
    });
  };
  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otpDigits[index]) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index - 1] = ""; // Clear the previous box
      setOtpDigits(newOtpDigits); // Update state if using React hooks or similar state management
      otpInputRefs.current[index - 1].focus(); // Move focus to previous input
    } else if (e.key === "Enter") {
      // Trigger OTP verification when Enter key is pressed
      const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
      if (isAllFieldsFilled) {
        verifyOTP();
      } else {
        // Handle the case when all fields are not filled
        setOTPErrorMessage("Please Add Correct OTP");
      }
    }
  };

  async function handlesendRequestPermission() {
    setIsRequestingPermission(true); // Start loading
    const formData = new FormData();
    formData.append("patientId", patient?._id);
    const url = "/request-permission";
    try {
      const data = await post(url, formData, getAuthConfig());
      if (data?.status === 200) {
        setpermissionId(data?.data?.permissionId);
        setModalShow(true);
      }
    } catch (error) {
      // Handle any errors
    } finally {
      setIsRequestingPermission(false); // Stop loading
    }
  }

  async function verifyOTP() {
    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (!isAllFieldsFilled) {
      setOTPErrorMessage("Please Add Correct OTP");
      return false;
    }
    setLoadingVer(true); // Set loading to true at the beginning of the API call
    const formdata = new FormData();
    formdata.append("permissionId", permissionId);
    formdata.append("otp", otpDigits.join(""));

    try {
      const response = await post(
        "/verify-otp-permission",
        formdata,
        getAuthConfig()
      )
        .then((data) => {
          if (data?.status === 200) {
            closeModal();
            setCompleteModal(true);
            setPatient((p) => ({ ...p, allowedPermission: true }));
          }
        })
        .catch((err) => {
          setOtpDigits(["", "", "", ""]); // Reset OTP digits
          setOTPErrorMessage(err?.response?.data?.err);
          if (otpInputRefs.current[0]) {
            otpInputRefs.current[0].focus();
          }
        })
        .finally(() => {
          setLoadingVer(false); // Set loading to false after API call completion
        });
    } catch (error) {
      setLoadingVer(false); // Ensure loading is set to false if an error occurs
    }
  }

  const [loadingRemove, setLoadingRemove] = useState(false);
  const [errorRemove, setErrorRemove] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleRemovePatient = async (patientId) => {
    setLoadingRemove(true);
    setErrorRemove(null); // Reset error state before making the API call

    try {
      const response = await post(
        `/remove-doctor?patientId=${patientId}`,
        {},
        getAuthConfig() // Pass authentication headers if needed
      );

      if (response?.status === 200) {
        // On success, show the success modal
        setShowSuccessModal(true);
      } else {
        // Handle any other unexpected status
        setErrorRemove(response?.message || "Something went wrong.");
      }
    } catch (error) {
      setErrorRemove(error?.message || "Failed to remove the patient.");
    } finally {
      setLoadingRemove(false); // Stop loading
    }
  };

  return (
    <Layout>
      <TopBar
        backlink={"/appointment"}
        title={patient?.name}
        tab={"Patient"}
        tabChild={"Physiotherapist"}
      />
      {loading ? (
        <div className="preloader-whirlpool text-center align-items-center">
          <div className="whirlpool"></div>
        </div>
      ) : (
        <div
          className="main-content-part topBox "
        >
          <div className="top-orange-box"></div>
          <div className="innerInfo">
            <div className="main-box">
              <div className="card-box row">
                <div className="col-lg-2 col-md-12 col-sm-12">
                <img
                  src={
                    patient?.profile_image
                      ? patient?.profile_image
                      : patient?.gender === "female"
                      ? PatientFemale
                      : patient?.gender === "male"
                      ? PatientMale
                      : PatientCommon
                  }
                  className="profile-image-container"
                /></div>
                <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="profile-content">
                  <div className="heading">
                    <div className="user-name">
                      <p className="name-tag-text-style">{patient?.name}</p>
                      <p
                        className="edit-label"
                        style={{ cursor: "pointer" }}
                        onClick={handleEditModalOpen}
                      >
                        Edit
                      </p>
                    </div>
                    <div>
                      {((data?.associatedEntity !== "Individual" &&
                        data?.clinicVerified === true &&
                        data?.associatedEntity?.isPremiumUser === true &&
                        data?.associatedEntity?.credits > 0) ||
                        (data?.associatedEntity === "Individual" &&
                          data?.isPremiumUser === true &&
                          data?.credits > 0)) && (
                        <div className="buttonPart mt-3 d-flex justify-content-center">
                          <button
                            className="action-button"
                            onClick={(e) => {
                              e.preventDefault();
                              if (patient?.isAssignedExercise === true) {
                                if (
                                  patient?.workoutGivenBy === null ||
                                  patient?.workoutGivenBy === user?._id ||
                                  patient?.allowedPermission
                                ) {
                                  // Open the assignment modal
                                  setShowAssignModal(true);
                                } else if (
                                  patient?.allowedPermission === false
                                ) {
                                  // Request permission
                                  if (!isRequestingPermission) {
                                    handlesendRequestPermission();
                                  }
                                } else {
                                  // Open the assignment modal
                                  setShowAssignModal(true);
                                }
                              } else if (
                                (data?.associatedEntity !== "Individual" &&
                                  data?.clinicVerified === true &&
                                  data?.associatedEntity?.isPremiumUser ===
                                    true &&
                                  data?.associatedEntity?.credits > 0) ||
                                (data?.associatedEntity === "Individual" &&
                                  data?.isPremiumUser === true &&
                                  data?.credits > 0)
                              ) {
                                // Open the assignment modal
                                setShowAssignModal(true);
                              } else {
                                // Handle when there are not enough credits
                                alert(
                                  "Doesn't have enough credit to Assign Workout"
                                );
                              }
                            }}
                          >
                            {(patient?.isAssignedExercise === true && patient?.allowedPermission === false
                            
                            &&
                              patient?.workoutGivenBy !=  null &&
                              patient?.workoutGivenBy != user?._id 
                            
                            )
                              ? isRequestingPermission
                                ? "Requesting Permission..."
                                : "Request Permission"
                              : "+ Assign"}
                          </button>
                        </div>
                      )}
                    </div>

                    {/* <div>
                      <button className="action-button" onClick={(e) =>{
                        e.preventDefault();
                        setShowAssignModal(true)
                        // navigate("/history", {
                        //   state: { patient_id: patient },
                        // });
                      }}>+ Assign</button>
                    </div> */}
                  </div>
                  <div className="contact-info-section">
                    {patient?.email && (
                      <p className="contact-info-text">
                        <span className=" me-2">
                          {" "}
                          <FaRegEnvelope />
                        </span>
                        {patient?.email}
                      </p>
                    )}

                    {patient?.phone && (
                      <p className="contact-info-text">
                        <span className=" me-2">
                          {" "}
                          <BsTelephone />
                        </span>
                        {patient?.phone}
                      </p>
                    )}

                    {(patient?.address?.street ||
                      patient?.address?.state ||
                      patient?.address?.country ||
                      patient?.address?.city) && (
                      <p className="contact-info-text">
                        <span className=" me-2">
                          {" "}
                          <BsGeoAlt />
                        </span>{" "}
                        {[
                          patient?.address?.street,
                          patient?.address?.city,
                          patient?.address?.state,
                          patient?.address?.country,
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </p>
                    )}
                  </div>

                  <div className="injury-exercise-container">
                    <div
                      className="injury-info-container"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <p className="injured-body-part-text-style mt-3 mb-1">
                        Injurd Body Part
                      </p>
                      <p className="injury-description">
                        {" "}
                        {patient?.inguredBodyPart
                          ? patient?.inguredBodyPart
                          : "N/A"}
                      </p>
                    </div>
                    <div className="exercise-progress-container w-100">
                      <div className="progress-indicator-container">
                        <p className="injured-body-part-text-style   mt-2 mb-1">
                          Exercise Progress
                        </p>
                        <p className="injured-body-part-text-style mt-2 mb-1">
                          {patient?.progressPercentage?.toFixed(2)}%
                        </p>
                      </div>

                      <div className="ProgressBar">
                        <ProgressBar now={patient?.progressPercentage} />
                        {/* <div className="coral-button" />
                <div className="horizontal-box" /> */}
                      </div>
                    </div>{" "}
                  </div>
                </div>
                </div>
              </div>{" "}
              { (patient?.isAssignedExercise === true && patient?.allowedPermission === false
                            
                            &&
                              patient?.workoutGivenBy !=  null &&
                              patient?.workoutGivenBy != user?._id 
                            
                            )  && (
                <div className="permission-required text-center mt-3">
                  <p className="text-danger">
                    Permission required to view patient details. Please request
                    permission.
                  </p>
                </div>
              )}
              <div className="horizontal-nav-list">
                <p
                  className={`tab-text ${
                    activeTab === "overview" ? "active" : ""
                  } ${(  patient?.workoutGivenBy != null ||
                    patient?.workoutGivenBy != user?._id ||
                    !patient?.allowedPermission )? "disabled" : ""}`}
                  onClick={() => {
                    if (patient?.workoutGivenBy === null ||
                      patient?.workoutGivenBy === user?._id ||
                      patient?.allowedPermission) {
                      handleTabClick("overview");
                    }
                  }}
                >
                  Overview
                </p>
                <p
                  className={`tab-text ${
                    activeTab === "workout-history" ? "active" : ""
                  } ${patient?.allowedPermission === false ? "disabled" : ""}`}
                  onClick={() => {
                    if (patient?.workoutGivenBy === null ||
                      patient?.workoutGivenBy === user?._id ||
                      patient?.allowedPermission) {
                      handleTabClick("workout-history");
                    }
                  }}
                >
                  Workout History
                </p>
                <p
                  className={`tab-text ${
                    activeTab === "appointment-history" ? "active" : ""
                  } ${patient?.allowedPermission === false ? "disabled" : ""}`}
                  onClick={() => {
                    if (patient?.workoutGivenBy === null ||
                      patient?.workoutGivenBy === user?._id ||
                      patient?.allowedPermission) {
                      handleTabClick("appointment-history");
                    }
                  }}
                >
                  Appointment History
                </p>
                <p
                  className={`tab-text ${
                    activeTab === "documents" ? "active" : ""
                  } ${patient?.allowedPermission === false ? "disabled" : ""}`}
                  onClick={() => {
                    if (patient?.workoutGivenBy === null ||
                      patient?.workoutGivenBy === user?._id ||
                      patient?.allowedPermission) {
                      handleTabClick("documents");
                    }
                  }}
                >
                  Documents
                </p>
              </div>
            </div>
          </div>

          {activeTab === "overview" ? (
            <OverView patient_id={patient} patient={patient}
            user={user}
            workoutHistory={workoutHistory} appointmentList={appointmentList}/>
          ) : activeTab === "workout-history" ? (
            <Workout
              patient={patient}
              user={user}
              workoutHistory={workoutHistory}
            />
          ) : (
            ""
          )}

          {activeTab === "appointment-history" && (
            <Appointment patient={patient} appointmentList={appointmentList} />
          )}

          {activeTab === "documents" && <Documents patient={patient} />}
          {/* <div className="innerInfo"> */}
            {/* <Row>
              <Col xl={5} lg={12}>
                <div className="doctorDetailsCard">
                  {(patient?.workoutGivenBy === user?._id ||
                    patient.doctor_added === user?._id) && (
                    <div
                      className="edit-button"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/EditPatient", {
                          state: { id: patient?._id },
                        });
                      }}
                    >
                      <img src={EditIcon} alt="edit" />
                    </div>
                  )}
                  <div className="doctorContent">
                    <div className="imnage-part">
                      <div className="rounded-circle border_shradded ">
                        <img
                          src={
                            patient?.profile_image
                              ? patient?.profile_image
                              : patient?.gender === "female"
                              ? PatientFemale
                              : patient?.gender === "male"
                              ? PatientMale
                              : PatientCommon
                          }
                          className="rounded-circle"
                          height={180}
                          width={180}
                          alt="patient"
                        />
                      </div>
                    </div>
                    <div className="doctorInfo mt-3">
                      <h4>{patient?.name}</h4>
                      <h6 style={{ color: "#ADADAD" }}>
                        {patient?.email || patient?.phone}
                      </h6>
                      <h6>
                        {patient?.inguredBodyPart
                          ? patient?.inguredBodyPart
                          : ""}
                      </h6>
                      <h6>
                        {(patient?.address?.street ||
                          patient?.address?.state ||
                          patient?.address?.country ||
                          patient?.address?.city) && (
                          <>
                            <img src={Location} alt="location" />
                            &nbsp;
                            {[
                              patient?.address?.street,
                              patient?.address?.city,
                              patient?.address?.state,
                              patient?.address?.country,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </>
                        )}
                      </h6>
                    </div>
                    <div className="logInfo">
                      <div className="badge call" style={{ cursor: "pointer" }}>
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {patient?.phone
                                ? patient?.phone
                                : "Mobile number is not available"}
                            </Tooltip>
                          )}
                          placement="bottom"
                        >
                          <img src={Call} alt="call" />
                        </OverlayTrigger>
                      </div>
                      <div
                        className="badge email"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          // navigate("/chat",{ state: { patient: patient } })
                        }}
                      >
                        <img src={Chat} alt="email" />
                      </div>
                    </div>
                    {patient?.injuryInfo ? (
                      <div className="aboutInfo mt-4">
                        <h6>Injury Information</h6>
                        <p> {patient?.injuryInfo}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={7} lg={12}>
                {/* {patient?.operationDate === null &&
              Number(patient?.workoutCount) <= 0 ? (
                ""
              ) : (
                <div
                  className="doctorDetailsCard mb-3"
                  style={{ paddingTop: "15px", paddingBottom: "15px" }}
                >
                  <Row className="w-100">
                    {patient?.operationDate ? (
                      <Col md={6}>
                        <div
                          className="doctorDetailsCard"
                          style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        >
                          <div className="rightSmallCard">
                            <h6>
                              {moment(patient?.operationDate).format(
                                "DD / MM / YYYY"
                              )}
                            </h6>
                            <p>Operation Date</p>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md={6}>
                      <div
                        className="doctorDetailsCard"
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      >
                        <div className="rightSmallCard">
                          <h6>{patient?.workoutCount}</h6>
                          <p>
                            Assign
                            {patient?.isAssignedExercise === true &&
                            patient?.workoutGivenBy === user?._id
                              ? " by you"
                              : " Workout"}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )} */}

            {/* {patient?.isAssignedExercise === true ? (
                  patient?.workoutGivenBy === null ||
                  patient?.workoutGivenBy === user?._id ||
                  patient?.allowedPermission ? (
                    <>
                      <div className="doctorDetailsCard startdata mb-3">
                        <div className="rightPartData">
                          <div className="infoField filed d-flex justify-content-between">
                            <div className="data">
                              <h6>Today's</h6>
                              <h5>
                                {" "}
                                {patient?.workoutCount} Workout
                                {patient?.workoutCount > 1 ? "s" : ""}
                              </h5>
                            </div>
                            <div className="data d-flex justify-content-end">
                              <Link
                                to="/history"
                                state={{ patient_id: patient }}
                                className="nextLink"
                              >
                                <img src={NextIcon} alt="next-icon" />
                              </Link>
                            </div>
                          </div>
                          <div className="filed">
                            <div
                              className="progressBar mb-4"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/history", {
                                  state: { patient_id: patient },
                                });
                              }}
                            >
                              <div className="progressText mb-2">
                                <p>Progress</p>
                                <p>
                                  {patient?.progressPercentage?.toFixed(2)}%
                                </p>
                              </div>
                              <ProgressBar now={patient?.progressPercentage} />
                            </div>
                            {patient?.workoutCount > 0 && (
                              <div className="block">
                                {patient?.incompleteCount === 0 ? (
                                  <>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <div>
                                        <div className="completed-icon-wrapper">
                                          <i className="fa fa-check"></i>

                                          // Replace with your desired icon class 
                                        </div>
                                      </div>
                                      <p className="mx-2">
                                        The patient has finished today's
                                        workout.
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p style={{ fontWeight: "500" }}>
                                      {patient?.incompleteCount}{" "}
                                      {patient?.incompleteCount === 1
                                        ? "Workout"
                                        : "Workouts"}{" "}
                                      to go
                                    </p>
                                    <p>A little bit more to reach the target</p>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {workoutHistory?.length > 0 && (
                        <div className="doctorDetailsCard startdata mb-3">
                          <div className="rightPartData">
                            <div className="infoField filed d-flex justify-content-between">
                              <div className="data">
                                <h5>Workout History</h5>
                              </div>
                              <div className="data d-flex justify-content-end">
                                <Link
                                  to="/history"
                                  state={{ patient_id: patient }}
                                  className="nextLink"
                                >
                                  <img src={NextIcon} alt="next-icon" />
                                </Link>
                              </div>
                            </div>

                            <div className="filed">
                              <div
                                className="session-card-content"
                                style={{ gap: "20px" }}
                              >
                                {workoutHistory?.length > 0 &&
                                  workoutHistory.map((session, index) => {
                                    const isLastSession =
                                      index === workoutHistory.length - 1;
                                    const sessionCreatedAt = moment(
                                      session?.created_at
                                    ); // Assuming 'created_at' is a date string
                                    const selectedDate = moment(date); // Assuming 'date' is the selected date in appropriate format
                                    let currentDate = new Date(date);
                                    let totalExercises =
                                      session?.removedExercises?.length;
                                    let completedExercises = 0;

                                    // Calculate completion ratio
                                    const completionRatio =
                                      totalExercises > 0
                                        ? completedExercises / totalExercises
                                        : 0;

                                    // Determine background color based on completion ratio
                                    let backgroundColor;
                                    if (completionRatio === 1) {
                                      backgroundColor = "#90ee906e"; // Light green for fully completed
                                    } else if (completionRatio > 0) {
                                      backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                                    } else {
                                      backgroundColor = "transparent"; // Default background for not completed
                                    }
                                    if (
                                      moment(date?.$d).isSame(
                                        sessionCreatedAt,
                                        "day"
                                      ) ||
                                      moment(date?.$d).isAfter(sessionCreatedAt)
                                    ) {
                                      return (
                                        <>
                                          <div
                                            className={`exercise-card pb-2`}
                                            key={index}
                                            style={{
                                              cursor: "pointer",
                                              maxHeight: "15vh",
                                              backgroundColor: backgroundColor,
                                              boxShadow: "none",
                                              borderBottom: isLastSession
                                                ? "none"
                                                : "1px solid #EAEAEA", // Conditionally apply borderBottom
                                              borderRadius: "1px",
                                              padding: "0px",
                                            }}
                                            onClick={() => {
                                              // handleWorkoutClick(session)
                                            }}
                                          >
                                            <div className="img-text-part">
                                              <div className="image-block">
                                                <img
                                                  src={
                                                    "https://api.recureme.com/images/Elbow.svg"
                                                  }
                                                  height={70}
                                                  width={70}
                                                  style={{
                                                    borderRadius: "55%",
                                                  }}
                                                  alt="session-img"
                                                />
                                              </div>
                                              <div className="text-part ps-3">
                                                <h6>
                                                  {session?.name?.replace(
                                                    "Workout Session",
                                                    ""
                                                  )}{" "}
                                                  - {session?.session}
                                                </h6>
                                                <p>
                                                  {`${totalExercises} exercises`}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="details">
                                              <div
                                                className="text-part ps-3"
                                                style={{ textAlign: "end" }}
                                              >
                                                <h6
                                                  className="session-title"
                                                  style={{ fontWeight: "400" }}
                                                >
                                                  {moment(
                                                    session?.startedWorkoutDate
                                                  ).format("DD MMM YYYY")}
                                                  &nbsp; to &nbsp;
                                                  {moment(
                                                    session?.dateRemoved
                                                  ).format("DD MMM YYYY")}
                                                </h6>
                                                <p>
                                                  Assigned by :{" "}
                                                  {user?._id ===
                                                  session?.assigned_by?._id
                                                    ? "You"
                                                    : session?.assigned_by
                                                        ?.name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : // If workoutGivenBy is not null and doesn't match user's id and allowedPermission is false
                  patient?.allowedPermission === false ? (
                    // If allowedPermission is true or any other case, show the exercise and progress.
                    <div className="doctorDetailsCard startdata mb-3">
                      <div className="rightPartData">
                        <div className="infoField filed d-flex justify-content-between">
                          <div className="data">
                            <h6>
                              Exercises's Data is private , To see it{" "}
                              <span
                                style={{ cursor: "pointer", color: "#ff6036" }}
                                onClick={(e) => {
                                  if (!isRequestingPermission) {
                                    // Prevent multiple clicks
                                    e.preventDefault();
                                    handlesendRequestPermission();
                                  }
                                }}
                              >
                                {isRequestingPermission
                                  ? "Requesting Permission..."
                                  : "Click Here"}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // If allowedPermission is true or any other case, show the exercise and progress.
                    <div className="doctorDetailsCard startdata mb-3">
                      // Your existing JSX for showing workouts and progress 
                    </div>
                  )
                ) : (
                  ""
                )} */}

            {/* {appointmentList?.length > 0 && (
                  <div
                    className="doctorDetailsCard startdata mb-3"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/AppointmentPatient", {
                        state: { patient, appointmentList },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="rightPartData">
                      <div className="infoField filed d-flex justify-content-between">
                        <div className="data">
                          <h5>Appointment History</h5>
                        </div>
                        <div className="data d-flex justify-content-end">
                          <Link
                            to="/AppointmentPatient"
                            state={{
                              patient: patient,
                              appointmentList: appointmentList,
                            }}
                            className="nextLink"
                          >
                            <img src={NextIcon} alt="next-icon" />
                          </Link>
                        </div>
                      </div>

                      <div className="filed">
                        <div className="session-card-content">
                          {appointmentList?.length > 0 &&
                            appointmentList.map((session, index) => {
                              const isLastSession =
                                index === appointmentList.length - 1;
                              const sessionCreatedAt = moment(
                                session?.created_at
                              ); // Assuming 'created_at' is a date string
                              const selectedDate = moment(date); // Assuming 'date' is the selected date in appropriate format
                              let currentDate = new Date(date);
                              let totalExercises =
                                session?.removedExercises?.length;
                              let completedExercises = 0;

                              // Calculate completion ratio
                              const completionRatio =
                                totalExercises > 0
                                  ? completedExercises / totalExercises
                                  : 0;

                              // Determine background color based on completion ratio
                              let backgroundColor;
                              if (completionRatio === 1) {
                                backgroundColor = "#90ee906e"; // Light green for fully completed
                              } else if (completionRatio > 0) {
                                backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                              } else {
                                backgroundColor = "transparent"; // Default background for not completed
                              }
                              const isAnyExerciseIncomplete =
                                session?.workout?.some((exercise) => {
                                  // Check if the exercise has incomplete dates
                                  return (
                                    exercise?.date_Complted?.length === 0 ||
                                    !exercise.date_Complted.includes(
                                      moment(
                                        currentDate?.setUTCHours(0, 0, 0, 0)
                                      ).toISOString()
                                    )
                                  );
                                });
                              if (
                                moment(date?.$d).isSame(
                                  sessionCreatedAt,
                                  "day"
                                ) ||
                                moment(date?.$d).isAfter(sessionCreatedAt)
                              ) {
                                return (
                                  <>
                                    <div
                                      className={`exercise-card`}
                                      key={index}
                                      style={{
                                        cursor: "pointer",
                                        maxHeight: "15vh",
                                        backgroundColor: backgroundColor,
                                        boxShadow: "none",
                                        borderBottom: isLastSession
                                          ? "none"
                                          : "1px solid #EAEAEA", // Conditionally apply borderBottom
                                        borderRadius: "1px",
                                        padding: "0px",
                                      }}
                                      onClick={() => {
                                        // handleWorkoutClick(session)
                                      }}
                                    >
                                      <div className="img-text-part">
                                        <div className="text-part ps-3">
                                          <h6>{session?.appoinmentFor}</h6>
                                        </div>
                                      </div>
                                      <div className="details">
                                        <div
                                          className="text-part ps-3"
                                          style={{ textAlign: "end" }}
                                        >
                                          <h6
                                            className="session-title"
                                            style={{ fontWeight: "400" }}
                                          >
                                            {moment(
                                              session?.timeSlot,
                                              "HH:mm:ss"
                                            ).format("h:mm A")}
                                            &nbsp;
                                            {moment(session?.date).format(
                                              "DD MMM YYYY"
                                            )}
                                          </h6>
                                          <p>Clinic</p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

            {/* {patient?.report?.length > 0 && (
                  <div className="doctorDetailsCard startdata">
                    <div className="rightPartData">
                      <div className="filed">
                        <div className="block">
                          <p style={{ fontWeight: "500" }}>Reports</p>

                          {patient.report.map((report, index) => (
                            <div
                              className="report-card"
                              style={{ cursor: "pointer" }}
                              onClick={() => window.open(report, "_blank")}
                              key={index}
                            >
                              <div className="image-block">
                                <img src={PDFIcon} alt="icon" />
                              </div>
                              <div className="text-block ms-3">
                                <p className="fileName">
                                  Report File {index + 1}
                                </p>
                                // You can customize the report name and file count
                                // For example:
                                 <p className="fileName">{`Report ${index + 1}`}</p>
                                <p className="fileNo">1 file</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
            {/* </Col>
            </Row> */}

            {/* {((data?.associatedEntity !== "Individual" &&
              data?.clinicVerified === true &&
              data?.associatedEntity?.isPremiumUser === true &&
              data?.associatedEntity?.credits > 0) ||
              (data?.associatedEntity === "Individual" &&
                data?.isPremiumUser === true &&
                data?.credits > 0)) && ( */}
            {/* <div className="buttonPart mt-3 d-flex justify-content-center">
              {patient?.isAssignedExercise === true ? (
                patient?.workoutGivenBy === null ||
                patient?.workoutGivenBy === user?._id ||
                patient?.allowedPermission ? (
                  <Link
                    to={"/workoutList"}
                    state={{
                      patient_id: patient?._id,
                      patientDetail: patient,
                    }}
                  >
                    <Button className="theme-button">Edit Exercise</Button>
                  </Link>
                ) : patient?.allowedPermission === false ? (
                  <Button
                    className="theme-button"
                    onClick={(e) => {
                      if (!isRequestingPermission) {
                        e.preventDefault();
                        handlesendRequestPermission();
                      }
                    }}
                  >
                    {isRequestingPermission
                      ? "Requesting Permission..."
                      : "Request Permission (Edit Exercise)"}
                  </Button>
                ) : (
                  <Link
                    to={"/workoutList"}
                    state={{
                      patient_id: patient?._id,
                      patientDetail: patient,
                    }}
                  >
                    <Button className="theme-button">Edit Exercise</Button>
                  </Link>
                )
              ) : (data?.associatedEntity !== "Individual" &&
                  data?.clinicVerified === true &&
                  data?.associatedEntity?.isPremiumUser === true &&
                  data?.associatedEntity?.credits > 0) ||
                (data?.associatedEntity === "Individual" &&
                  data?.isPremiumUser === true &&
                  data?.credits > 0) ? (
                <Button
                  className="theme-button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/patientpoints", {
                      state: {
                        patient_id: patient?._id,
                        patientDetail: patient,
                      },
                    });
                  }}
                >
                  Suggest Exercises
                </Button>
              ) : (
                <span className="text-danger">
                  Doesn't have Enough credit to Assign Workout
                </span>
              )}
            </div> */}
            {/* )} */}
          {/* </div> */}
        </div>
      )}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body style={{ border: "none" }}>
          <div className="content-modal">
            <div className="top-btn-part">
              <button className="btn close-btn" onClick={closeModal}>
                <AiOutlineClose size={20} color="#000" />
              </button>
            </div>
            <div className="modal-inside-content">
              <h5 className="heading">
                Authentication Code Sent to patient's{" "}
                {patient?.email ? "Email Address" : "Mobile Number"}
              </h5>
              <p className="desc-text">
                4 digit OTP has sent to your patient's{" "}
                {patient?.email ? "Email Address" : "Mobile Number"}{" "}
              </p>
              <p className="mobileno" style={{ cursor: "pointer" }}>
                {patient?.email ? patient?.email : patient?.phone}
              </p>
              <div className="otp-input-group" onPaste={handlePaste}>
                {otpDigits.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    autoFocus={index === 0}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    ref={(el) => (otpInputRefs.current[index] = el)} // Store refs to OTP input elements
                  />
                ))}
              </div>
              {otperrorMessage && (
                <FieldValidationError message={otperrorMessage} />
              )}
              <button className="btn-continue" onClick={verifyOTP}>
                {isLoadingVer ? "Verifying OTP" : "Continue"}
              </button>
              {/* <span className="notreceive-text">
                Haven't you received the Email?
              </span>
              <button
                className="resend-btn"
                onClick={(e) => {
                  e.preventDefault();
                  //   sendOtp()
                }}
              >
                Resend Code
              </button> */}
              {/* <p className="timer">{`${Math.floor(timerSeconds / 60)}:${(timerSeconds % 60)
                  .toString()
                  .padStart(2, '0')}`}</p>     */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={completeModal}
        onHide={() => {
          setCompleteModal(false);
        }}
        size="md"
        className="complete-modal"
      >
        <Modal.Body>
          <div className="top-heading text-end">
            <button
              className="btn btn-transpatent"
              onClick={() => setCompleteModal(false)}
            >
              X
            </button>
          </div>
          <div className="content-block text-center">
                <Animation />
            </div>
          <div className="bottom-block text-center">
            <div
              className="workout-desc body-content"
              style={{
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                "flex-direction": "column",
                "padding-top": "25px",
              }}
            >
              <p>Request Approved successfully</p>

              <button
                className="theme-button btn btn-primary"
                style={{ minWidth: "300px", padding: "14px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setCompleteModal(false);
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAssignModal}
        onHide={closeAssignModal}
        size="md"
        centered
      >
        <Modal.Header closeButton className="border-0" />
        <Modal.Body>
          <div className="d-flex justify-content-center gap-5">
            <div
              className="text-center"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                if (patient?.isAssignedExercise === true) {
                  navigate("/workoutList", {
                    state: {
                      patient_id: patient?._id,
                      patientDetail: patient,
                    },
                  });
                } else {
                  navigate("/patientpoints", {
                    state: {
                      patient_id: patient?._id,
                      patientDetail: patient,
                    },
                  });
                }
              }}
            >
              <img src={Exercises} height={100} width={100} alt="modal-video" /> <br />
              <span style={{ fontWeight: "700", fontSize: "13px" }}>
                Exercises
              </span>
            </div>
            {data?.associatedEntity === "Individual" && 
            <div
              className="text-center"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                navigate("/doctorList", {
                  state: { patient: patient, from: "ClinicTeam" },
                });
              }}
            >
              <img
                src={ClinicTeam}
                height={100}
                width={100}
                alt="modal-video"
              /> <br/>
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "13px",
                  whiteSpace: "nowrap",
                }}
              >
                Clinic Team
              </span>
            </div> }
            <div
              className="text-center"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                navigate("/doctorList", {
                  state: { patient: patient, from: "GlobalPhysio" },
                });
              }}
            >
              <img
                src={GlobalPhysio}
                height={100}
                width={100}
                alt="modal-video"
              /><br />
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "13px",
                  whiteSpace: "nowrap",
                }}
              >
                Global Physio
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showEditModal} onHide={handleEditModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate("/EditPatient", {
                state: { id: patient?._id },
              });
            }}
            className="theme-button btn btn-primary px-2 me-3"
            style={{ minWidth: "fit-content" }}
          >
            <FaUserEdit /> &nbsp; Edit Patient Details
          </button>
          <button
            variant="danger"
            // onClick={handleRemovePatient}
            className="theme-button  btn btn-primary px-2 "
            style={{ minWidth: "fit-content" }}
            onClick={(e) => {
              e.preventDefault();
              handleRemovePatient(patient?._id);
            }}
            disabled={loadingRemove} // Disable the button during loading
          >
            {loadingRemove ? (
              <>
                <Spinner animation="border" size="sm" /> &nbsp; Removing...
              </>
            ) : (
              <>
                <MdPersonRemoveAlt1 /> &nbsp; Remove Patient
              </>
            )}
          </button>
        </Modal.Body>

        {errorRemove && <div className="text-danger mt-2">{errorRemove}</div>}
      </Modal>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <h4>Patient Removed Successfully</h4>
            <p>The patient has been removed from the system.</p>
            <Button
              variant="primary"
              onClick={() => {
                setShowSuccessModal(false);
                navigate("/allPatient");
              }}
              className="theme-button btn btn-primary px-2 mt-3"
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default PatientDetails;
