import React, { useState } from "react";
import Layout from "../layout";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import "../../src/assets/css/ticket.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthConfig, getAuthConfigImage, post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import profileUpload from "../../src/assets/images/upload.svg";

function TicketReply() {
  const navigate = useNavigate();
  const location = useLocation();
  const ticket = location?.state?.ticket;
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null); // Add file state for file uploads
  const [replies, setReplies] = useState(ticket?.replies || []);
  const user = Auth?.getCurrentUser();

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setError("");
    
    // Validate message and file
    if (!newMessage.trim() && !file) {
      setError("Reply Message or File is required");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("message", newMessage);

      if (file) {
        formData.append("attachment",file); // Attach file if uploaded
      }

      const response = await post(
        `/tickets/reply?ticketId=${ticket?._id}`,
        formData,
        getAuthConfigImage()
      );

      // Update the replies state with the new reply
      setReplies(response.data.replies);
      setNewMessage(""); // Clear the input box after sending
      setFile(null); // Clear the file input after sending
    } catch (err) {
      setError(err.response?.data?.error || "Error replying to ticket");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Capture selected file
  };
  return (
    <Layout>
      <div>
      <Container className="chat-container my-5">
      <div
            style={{ cursor: "pointer" ,color:"#ff6036"}}
            onClick={(e) => {
              e.preventDefault();
              navigate("/support");
            }}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> View All Tickets
          </div>
        <div className="chat-header d-flex justify-content-between">
      
          <div style={{ marginLeft: "10px" }}>
            <span style={{ fontSize: "1rem", fontWeight: "500" }}>
              {ticket?.title}{" "}
            </span>{" "}
            <br />
            <small style={{color:"#827472"}}>
                            Ticket # {ticket._id} • Raised{" "}
                            {moment(ticket.createdAt).fromNow()}
                          </small>          </div>

          <div>  <span
                            className="badge"
                            style={{
                              backgroundColor:
                                ticket.status === "open"
                                  ? "#28A745"
                                  : "#FF6347",
                              color: "white",
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                          >
                            {ticket.status === "open"
                              ? "IN PROGRESS"
                              : "CLOSED"}
                          </span>

          </div>
        </div>
        <div className="wrapperchat">
        <Row className="chat-content px-4">
          {replies.map((reply, index) => (
            <Col
              key={index}
              xs={12}
              className={`d-flex ${
                reply.repliedBy != user?._id
                  ? "justify-content-start"
                  : "justify-content-end"
              }`}
            >
                <div className="m-2">
              {reply.repliedBy != user?._id && 
              <svg width="30" height="30" viewBox="0 0 244 244" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_448_2)">
              <rect width="244" height="244" rx="122" fill="url(#paint0_linear_448_2)"/>
              <ellipse cx="-64.9525" cy="138.831" rx="220.391" ry="168.654" transform="rotate(90 -64.9525 138.831)" fill="url(#paint1_linear_448_2)"/>
              <ellipse cx="236.734" cy="244.682" rx="142.742" ry="135.731" transform="rotate(-53.125 236.734 244.682)" fill="url(#paint2_linear_448_2)"/>
              <path d="M140.628 146.695V181.453C140.628 183.885 138.657 185.857 136.224 185.857H105.548C102.826 185.857 100.757 183.412 101.4 180.767C105.048 165.769 113.587 148.969 118.437 142.291C137.253 113.966 166.245 103.862 179.222 102.681C179.607 102.646 179.987 102.664 180.359 102.766C184.623 103.932 184.444 108.185 184.265 110.268C184.39 116.598 184.564 130.636 184.265 136.147C183.967 141.658 177.925 142.539 174.941 142.291H145.033C142.6 142.291 140.628 144.263 140.628 146.695Z" fill="white"/>
              <path d="M62.6784 102.079H100.907C100.012 98.5043 94.5669 74.5244 93.448 61.3057C105.532 94.8179 107.232 109.351 107.807 121.814C109.113 150.113 94.7534 174.503 85.6158 185.487C97.1031 165.231 101.094 148.251 101.653 142.294H62.6784C58.7995 141.996 57.0839 138.198 56.7109 136.336V108.037C57.3077 103.568 60.9379 102.203 62.6784 102.079Z" fill="white"/>
              <path d="M129.812 72.8478C121.779 72.8478 115.266 79.3495 115.266 87.3698C115.266 90.0921 116.017 92.6394 117.322 94.817C104.637 83.6462 100.724 69.0622 100.721 62.4218C102.213 58.6982 105.01 58.3258 106.688 58.1396H133.542C139.36 58.5865 140.566 62.9183 140.442 65.0283V71.7307C143.798 73.0961 150.288 78.1353 149.393 87.3698C148.498 96.6043 141.56 100.899 138.204 101.892L133.542 101.41C139.768 99.7657 144.358 94.1029 144.358 87.3698C144.358 79.3495 137.845 72.8478 129.812 72.8478Z" fill="white"/>
              </g>
              <defs>
              <linearGradient id="paint0_linear_448_2" x1="115.448" y1="138.944" x2="244" y2="6.19448e-07" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF6036"/>
              <stop offset="0.749008" stop-color="#FF4211"/>
              </linearGradient>
              <linearGradient id="paint1_linear_448_2" x1="36.0364" y1="18.2997" x2="-201.638" y2="8.92384" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF6941"/>
              <stop offset="1" stop-color="#FF4211"/>
              </linearGradient>
              <linearGradient id="paint2_linear_448_2" x1="185.252" y1="120.224" x2="193.404" y2="159.603" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF6941"/>
              <stop offset="1" stop-color="#FF4211"/>
              </linearGradient>
              <clipPath id="clip0_448_2">
              <rect width="244" height="244" rx="122" fill="white"/>
              </clipPath>
              </defs>
              </svg>
              

} 
</div>
              <div
                className={`message-box ${
                  reply.repliedBy != user?._id
                    ? "support-message"
                    : "patient-message"
                }`}
              >
                {reply?.image ?  <img src={reply?.image} height={50} width={50}/> : <div className='message-text'>{reply.message}</div>}
                <div className="message-time">
                  {moment(reply.repliedAt).format("hh:mm A")}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <div className="px-4">
        {ticket?.status === "open" && <hr /> }
        </div>
        <Row className="chat-input px-4">
          <Col xs={12}>
            {ticket?.status === "open" ? (
              <>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSendMessage}>
                  <Form.Group className="d-flex mb-2">
                  {/* <Form.Control
                      type="file"
                      onChange={handleFileChange}
                      className="chat-file-input"
                      disabled={loading}
                    /> */}
<div className="form-data">
<div className="filed">
<label htmlFor="profile-img" className="upload" style={{width:"40px"}}>
                  <div className="profile-pic" style={{width:"40px",height:"40px",display:"flex",justifyContent:"center",alignItems:"center",border:"1px solid #E5E5E5",borderRadius:"25px"}}>
                    
                      <img
                        src={profileUpload}
                        alt="Exercise Image"
                        className="img-fluid"
                        style={{
                          height: "25px",
                          width: "25px",
                          borderRadius: "0%",
                        }}
                      />
                   
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="profile-img"
                  name="profile"
                  onChange={handleFileChange}
                />
                </div>
                </div>
                    <Form.Control
                      type="text"
                      placeholder="Type your message..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      className="chat-input-box"
                      disabled={loading}
                    />
                   
                    <button
                      type="submit"
                      className="chat-send-button"
                      disabled={loading}
                    >
                      {loading ? <Spinner animation="border" size="sm" /> : "Send"}
                    </button>
                  </Form.Group>
                </Form>
              </>
            ) : (
              <div className="text-center mb-3">
                <Button type="submit" className="btn" style={{backgroundColor:"#e6e3e3ab",color:"black",border:'none',fontSize:"0.8rem"}} disabled={loading}>
                  This Ticket is closed you cant chat in it 
                </Button>
              </div>
            )}
          </Col>
        </Row>
        </div>
      </Container>
      </div>
    </Layout>
  );
}

export default TicketReply;
