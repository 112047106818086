import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import doneImg from "../assets/images/Opps.svg"; // Import the image
import { useNavigate } from "react-router-dom";

const PremiumUserSwitchModal = ({ show, onHide, From }) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="complete-modal"
      centered
    >
      <Modal.Header>
        <div className="top-heading w-100 d-flex justify-content-end text-end">
          <button className="btn btn-transpatent" onClick={onHide}>
            X
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="content-block text-center">
          <img src={doneImg} height={60} width={60} alt="modal-video" />
        </div>
        <div className="bottom-block text-center">
          <div
            className="workout-desc body-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "25px",
            }}
          >
            <p>Opps!</p>
            <span>
              You are a Premium user. If you want to switch your profile from
              Individual to Clinic, please cancel <span style={{cursor:"pointer",color:"#ff6036"}} onClick={()=> navigate("/myPlanDetails")}>your subscription </span> first.
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PremiumUserSwitchModal;
