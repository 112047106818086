import { useEffect, useState } from "react";
import Layout from "../layout";
import TopBarNavigation from "../components/TopBarNavigation";
import {
  get,
  getAuthConfig,
  getAuthConfigImage,
  post,
} from "../libs/http-hydrate";
import { useLocation, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { Col, Form, Row } from "react-bootstrap";
import UploadIcon from "../assets/images/upload.svg";
import ReactPlayer from 'react-player'

function ExerciseForm() {
  const [videoUrl, setVideoUrl] = useState(null)

  const initialFormState = {
    body_part_id: [],
    title: "",
    description: "",
    exerciseImage: null,
    videoLink: null,
    Benifits: "",
    count: 0,
    restTime: 0,
    set: 0,
    movementType: "",
    equipment: [],
    typeOfExercise: "",
  };
  // State for form values and errors
  const [formValues, setFormValues] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [images, setImage] = useState({
    equipment: [],
  });
  const [bodyPartData, setBodyPart] = useState({
    has_more: false,
    items: [],
  });
  const [equipmentPartData, setequipmentPartData] = useState({
    has_more: false,
    items: [],
  });
  const [movemntPartData, setmovemntPartData] = useState({
    has_more: false,
    items: [],
  });
  const page = 0;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    getBodyPartData();
  }, [page]);

  useEffect(() => {
    if (location?.state?.exercise?._id) {
      setFormValues(location?.state?.exercise);
      let body_part_id =[]
      for (const key in location?.state?.exercise?.body_part_id) {
       body_part_id.push({
          key: location?.state?.exercise?.body_part_id[key].title,
          value: location?.state?.exercise?.body_part_id[key]._id,
        })
      }
      setImage((p)=>({...p,body_part_id:body_part_id}) )
    }
  }, [location?.state?.exercise]);

  async function getEquipment() {
    try {
      const data = await get(
        `/listEquipment?pageNumber=${page}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setequipmentPartData((p) => ({
          ...p,
          items: data?.data?.equipmentList,
        }));
        setequipmentPartData((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }
  async function getMovementType() {
    try {
      const data = await get(
        `/listMovementType?pageNumber=${page}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setmovemntPartData((p) => ({ ...p, items: data?.data?.equipmentList }));
        setmovemntPartData((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }
  async function getBodyPartData() {
    try {
      const data = await get(
        `/bodyPartList?pageNumber=${page}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        setBodyPart((p) => ({ ...p, items: data?.data?.data }));
        setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }
  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, checked, files, type } = e.target

    // Handle image file validation
  if (type === 'file' && name === 'exerciseImage') {
    const selectedImage = files[0];
    if (selectedImage && selectedImage.size > 2097152) { // 2MB in bytes
      setFormErrors({...formErrors, exerciseImage: "Image must be less than 2MB"});
      setFormValues({ ...formValues, exerciseImage: "" });

    } else {
      setFormErrors({...formErrors, exerciseImage: ""});
      setFormValues({ ...formValues, exerciseImage: selectedImage });
    }
  }

  // Handle video file validation
  else if (type === 'file' && name === 'videoLink') {
    const selectedVideo = files[0];
    if (!selectedVideo) {
      // Handle case where no file is selected
      ///setFormErrors({...formErrors, videoLink: "No file selected"});
      return false;
    }
    if (selectedVideo && selectedVideo.size > 52428800) { // 50MB in bytes
      setFormErrors({...formErrors, videoLink: "Video must be less than 50MB"});
      setFormValues({ ...formValues, videoLink: "" });
      setVideoUrl(""); // Set the video URL
    } else {
      setFormErrors({...formErrors, videoLink: ""});
      setFormValues({ ...formValues, videoLink: selectedVideo });
      setVideoUrl(URL?.createObjectURL(selectedVideo)); // Set the video URL
    }
  } else if (type === 'checkbox' && name === 'isLive') {
      setFormValues({ ...formValues, [name]: checked })
    } else {
      setFormValues({ ...formValues, [name]: value })
    }
  }
  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    if (Object.keys(errors).length === 0) {
      handleCreateExercise();
      // Form submission logic here
    } else {
      setFormErrors(errors);
    }
  };
  const validate = (values) => {
    const errors = {};

    if (!images?.body_part_id || images?.body_part_id?.length === 0) errors.body_part_id = "Body Part is required";
    if (!values.title) errors.title = "Title is required";
    if (!values.description) errors.description = "Description is required";
    if (!values.Benifits) errors.Benifits = "Benefits are required";
    if (!values.exerciseImage)
      errors.exerciseImage = "Exercise image is required";
    if (!values.videoLink) errors.videoLink = "Video link is required";

    if (!values.count) {
      errors.count = "Count is required";
    } else if (isNaN(values.count) || values.count <= 0) {
      errors.count = "Count must be a positive number";
    }

    if (!values.restTime) {
      errors.restTime = "Rest Time is required";
    } else if (isNaN(values.restTime) || values.restTime <= 0) {
      errors.restTime = "Rest time must be a positive number";
    }

    if (!values.set) {
      errors.set = "Set is required";
    } else if (isNaN(values.set) || values.set <= 0) {
      errors.set = "Set must be a positive number";
    }

    if (!values.movementType) errors.movementType = "Movement Type is required";

    if (!values.typeOfExercise)
      errors.typeOfExercise = "Type of Exercise is required";
    // if (!images.body_part_id) errors.body_part_id = "BodyPart is required";
    // if (values.equipment.length === 0)
    //   errors.equipment = "At least one equipment must be selected";

    // ... Add any other specific validations you need

    return errors;
  };

  async function handleCreateExercise() {
    setIsLoading(true); // Start loading
    const formDat = new FormData();
    formDat.append("title", formValues.title);
    formDat.append("description", formValues.description);
    // Assuming formValues.exerciseImage and formValues.videoLink are supposed to be files
    if (formValues.exerciseImage && formValues.exerciseImage instanceof File) {
      formDat.append("exerciseImage", formValues.exerciseImage);
    }

    if (formValues.videoLink && formValues.videoLink instanceof File) {
      formDat.append("videoLink", formValues.videoLink);
    }
    formDat.append("count", formValues.count);
    formDat.append("restTime", formValues.restTime);
    formDat.append("set", formValues.set);
    formDat.append("Benifits", formValues.Benifits);
    formDat.append("movementType", formValues.movementType);
    formDat.append("typeOfExercise", formValues.typeOfExercise);
    formDat.append("hold", formValues.hold);
    if (images?.equipment?.length > 0) {
      // Appending the data to the URLSearchParams object
      for (const key in images?.equipment) {
        formDat.append("equipment", images?.equipment[key].key);
      }
    } else {
      // Loop through the array and append each item to formData
      formValues?.equipment?.forEach((item, index) => {
        formDat.append(`equipment[${index}]`, item);
      });
    }

    if (images?.body_part_id?.length > 0) {
      for (const key in images?.body_part_id) {
        formDat.append("body_part_id", images?.body_part_id[key].value);
      }
    }
    if (formValues?._id) {
      formDat.append("_id", formValues?._id);
    }
    const url = "/personalExercise";
    try {
      const data = await post(url, formDat, getAuthConfigImage());
      if (data?.status === 200) {
        navigate("/myExercise");
      }
      setIsLoading(false); // Start loading
    } catch (error) {
      setIsLoading(false); // Start loading
    }
  }

  return (
    <>
      <Layout>
        <TopBarNavigation
          backlink={"/true"}
          title={"Add Exercise"}
          tab={"Exercise"}
          tabChild={"Add Exercise"}
        />
        <div className="main-content-part">
          <Row>
            <Col lg="4">
              <div className="filed">
                <div className="upload-part mt-2">
                  <div className="form-group file-area">
                    <input
                      type="file"
                      id="images"
                      required="required"
                      multiple="multiple"
                      accept="image/png,image/gif,image/jpeg,image/jpg"
                      name="exerciseImage"
                      className="input-field"
                      onChange={handleChange}
                    />
                    <div className="file-dummy">
                    {formErrors.exerciseImage && (
                      <div className="error">
                        <div className="image-block mb-2">
                          <img src={UploadIcon} alt="icon" />
                        </div>
                        Upload Exercise Image here (png, jpg) - Max size 2MB
                      </div>
                    )}
                    { formValues.exerciseImage instanceof File
                      ? <div className="success">
                          <img
                            src={URL.createObjectURL(formValues.exerciseImage)}
                            onLoad={() => URL.revokeObjectURL(formValues.exerciseImage)}
                            alt="Exercise Preview"
                            style={{ maxHeight: '100px', maxWidth: '100px' }}
                          />
                        </div>
                      : formValues.exerciseImage
                        ? <img
                            src={formValues.exerciseImage}
                            alt="Exercise Image"
                            style={{ maxHeight: '100px', maxWidth: '100px' }}
                          />
                        : formErrors.exerciseImage ? " " : <div className="default">
                          <div className="image-block mb-2">
                            <img src={UploadIcon} alt="icon" />
                          </div>
                          Upload Exercise Image here (png, jpg) - Max size 2MB
                        </div>
                    }
                    </div>
                  </div>

                  {formErrors.exerciseImage && (
                    <div className="text-danger">
                      {formErrors.exerciseImage}
                    </div>
                  )}
                </div>
              </div>

              <div className="filed">
                <div className="upload-part mt-2">
                  <div className="form-group file-area">
                    <input
                      type="file"
                      id="images"
                      required="required"
                      multiple="multiple"
                      accept="video/mp4,video/x-m4v,video/*"
                      name="videoLink"
                      className="input-field"
                      onChange={handleChange}
                    />

                    <div className="file-dummy">
                    {formErrors.videoLink && (
                      <div className="error">
                        <div className="image-block mb-2">
                          <img src={UploadIcon} alt="icon" />
                        </div>
                        Upload Exercise Video here - Max size 50MB
                      </div>
                    )}
                    {videoUrl
                      ? (
                          <div className="success">
                            <ReactPlayer url={videoUrl} height={'130px'} width={'200px'} controls />
                          </div>
                        )
                      : formValues.videoLink
                        ? (
                            <ReactPlayer url={formValues.videoLink} height={'130px'} width={'200px'} controls />
                          )
                        : (
                          !formErrors.videoLink &&
                          <div className="default">
                            <div className="image-block mb-2">
                              <img src={UploadIcon} alt="icon" />
                            </div>
                            Upload Exercise Video here - Max size 50MB
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {formErrors.videoLink && (
                  <div className="text-danger">{formErrors.videoLink}</div>
                )}
              </div>
            </Col>
            <Col lg="8">
              <div className="form-data" style={{ maxWidth: "100%" }}>
                {/* Title */}
                <div className="mb-1 filed">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="input-field"
                    onChange={handleChange}
                    value={formValues.title}
                    placeholder="Exercise Title"
                  />
                  {formErrors.title && (
                    <div className="text-danger">{formErrors.title}</div>
                  )}
                </div>

                {/* Description */}
                <div className="mb-1 filed">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    className="input-field w-100 form-control"
                    onChange={handleChange}
                    value={formValues.description}
                    rows={3}
                    placeholder="Exercise Description"
                  />
                  {formErrors.description && (
                    <div className="text-danger">{formErrors.description}</div>
                  )}
                </div>

                {/* Body Part */}
                <div className="mb-1 filed filed">
                  <label htmlFor="body_part_id" className="form-label">
                    Body Part
                  </label>
                  <Multiselect
                    options={bodyPartData?.items?.map((bodyPart) => ({
                      key: bodyPart.title,
                      value: bodyPart._id,
                    }))} // closeIcon="close"
                    customArrow={
                      <img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />
                    }
                    displayValue="key"
                    placeholder="Select BodyPart"
                    showArrow={true}
                    keepSearchTerm={true}
                    onSelect={(selectedList, selectedItem) => {
                      setImage((p) => ({ ...p, body_part_id: selectedList }));
                      // handleTimesChange(itemId, selectedList)
                    }} // Function will trigger on select event
                    onRemove={(selectedList, removedItem) => {
                      // handleTimesChange(itemId, selectedList)
                      setImage((p) => ({ ...p, body_part_id: selectedList }));
                    }}
                    selectedValues={formValues?.body_part_id?.map(
                      (bodyPart) => ({
                        key: bodyPart?.title,
                        value: bodyPart?._id,
                      })
                    )}
                    onFocus={getBodyPartData}
                  />

                  {formErrors.body_part_id && (
                    <div className="text-danger">{formErrors.body_part_id}</div>
                  )}
                </div>
                {/* Movement Type */}
                <div className="mb-1 filed">
                  <label htmlFor="movementType" className="form-label">
                    Movement Type
                  </label>
                  <select
                    name="movementType"
                    className="input-field form-control form-select"
                    onChange={handleChange}
                    value={formValues.movementType}
                    onFocus={getMovementType}
                  >
                    <option value="">
                      {formValues?.movementType
                        ? formValues?.movementType
                        : "Select Movement Type"}
                    </option>
                    {movemntPartData.items.map((bodyPart) => (
                      <option key={bodyPart._id} value={bodyPart.title}>
                        {bodyPart.title}
                      </option>
                    ))}
                  </select>
                  {formErrors.movementType && (
                    <div className="text-danger">{formErrors.movementType}</div>
                  )}
                </div>

                {/* Equipment */}
                <div className="mb-1 filed" onFocus={getEquipment} tabIndex="0">
                  <label htmlFor="equipment" className="form-label">
                    Equipment
                  </label>
                  <Multiselect
                    options={equipmentPartData?.items?.map((bodyPart) => ({
                      key: bodyPart.title,
                      value: bodyPart.title,
                    }))} // closeIcon="close"
                    customArrow={
                      <img src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e" />
                    }
                    displayValue="key"
                    placeholder="Select Equipment"
                    showArrow={true}
                    keepSearchTerm={true}
                    onSelect={(selectedList, selectedItem) => {
                      setImage((p) => ({ ...p, equipment: selectedList }));
                    }} // Function will trigger on select event
                    onRemove={(selectedList, removedItem) => {
                      setImage((p) => ({ ...p, equipment: selectedList }));
                    }}
                    selectedValues={formValues?.equipment?.map((bodyPart) => ({
                      key: bodyPart,
                      value: bodyPart,
                    }))}
                    onFocus={getEquipment}
                  />
                </div>
                {/* Benefits */}
                <div className="mb-1 filed">
                  <label htmlFor="Benifits" className="form-label">
                    Benefits
                  </label>
                  <input
                    type="text"
                    name="Benifits"
                    className="input-field"
                    onChange={handleChange}
                    value={formValues.Benifits}
                    placeholder="Exercise Benefits"
                  />
                  {formErrors.Benifits && (
                    <div className="text-danger">{formErrors.Benifits}</div>
                  )}
                </div>

                <Row>
                  <Col lg="6">
                    {/* Count */}
                    <div className="mb-1 filed">
                      <label htmlFor="count" className="form-label">
                        Count
                      </label>

                      <Form.Select
                        aria-label="Default select example"
                        name="count"
                        className="mb-3"
                        onChange={handleChange}
                      >
                        <option>{formValues.count}</option>
                        {[...Array(10)].map((_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.count && (
                        <div className="text-danger">{formErrors.count}</div>
                      )}
                    </div>

                    {/* Rest Time */}
                    <div className="mb-1 filed">
                      <label htmlFor="restTime" className="form-label">
                        Rest Time
                      </label>

                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3"
                        name="restTime"
                        onChange={handleChange}
                      >
                        <option>{formValues.restTime}</option>

                        {[...Array(13)].map((_, index) => (
                          <option key={index + 1} value={(index + 1) * 5}>
                            {(index + 1) * 5} seconds
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.restTime && (
                        <div className="text-danger">{formErrors.restTime}</div>
                      )}
                    </div>
                  </Col>

                  <Col lg="6">
                    {/* Set */}
                    <div className="mb-1 filed">
                      <label htmlFor="set" className="form-label">
                        Set
                      </label>

                      <Form.Select
                        aria-label="Default select example"
                        name="set"
                        className="mb-3"
                        onChange={handleChange}
                      >
                        <option>{formValues.set}</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                      </Form.Select>
                      {formErrors.set && (
                        <div className="text-danger">{formErrors.set}</div>
                      )}
                    </div>
                    <div className="filed">
                      <label>Holds </label>
                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3"
                        name="hold"
                        placeholder="Select Seconds"
                        onChange={handleChange}
                      >
                        <option>{formValues?.hold}</option>

                        {[...Array(13)].map((_, index) => (
                          <option key={index + 1} value={(index + 1) * 5}>
                            {(index + 1) * 5} seconds
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                </Row>

                <div className="mb-1 filed">
                  <label className="form-label">Type of Exercise</label>
                  <div style={{ whiteSpace: "nowrap" }}>
                    <label
                      className="radio-container"
                      style={{
                        fontSize: "12px",
                        marginRight: "10px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="radio"
                        name="typeOfExercise"
                        value="Suggest"
                        checked={formValues.typeOfExercise === "Suggest"}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>{" "}
                      &nbsp; Suggest to RecureMe
                    </label>

                    <label
                      className="radio-container"
                      style={{
                        fontSize: "12px",
                        marginRight: "30px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="radio"
                        name="typeOfExercise"
                        value="Personal"
                        checked={formValues.typeOfExercise === "Personal"}
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>
                      &nbsp; Personal
                    </label>
                  </div>

                  {formErrors.typeOfExercise && (
                    <div className="text-danger">
                      {formErrors.typeOfExercise}
                    </div>
                  )}
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className=" theme-button btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting" : "Submit"}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
}
export default ExerciseForm;
