import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import UpcomingPatientsCard from "../../components/UpcomingPatientsCard";
import { Row, Col, Button, ProgressBar } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/images/search.svg";
import FilterIcon from '../../assets/images/filter.svg'
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import moment from "moment";
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png"
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import checkIcon from '../../assets/images/check (1).png'
import Modal from 'react-bootstrap/Modal';
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
const AllPatient = () => {

  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    searchTerm: "",
    title:""
})
const [showFilter, setShowFilter] = useState(false);
const handleCloseFilter = () => setShowFilter(false);
const handleShowFilter = () => setShowFilter(true);
const [loading, setLoading] = useState(true);

const [bodyPartData, setBodyPart] = useState(
  {
      has_more: false,
      items: [{
        title:"Today",
      },{
        title:"Upcoming"
      },{
        title:"Monthly"
      },{
        title:"Weekly"
      }],
  });

  useEffect(() => {
    if (page >= 1) {
      getPhysiotherapistLit()
    }

  }, [page])
  useEffect(() => {
    getPhysiotherapistLit();  
    // organizeDataByTimeOfDay(readyData);
}, [filter]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && patientList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [patientList?.has_more]
  );

  async function getPhysiotherapistLit() {

    try {
      setLoading(true);

      
      let queryParams = `pageNumber=${page}`;
    
      if (filter?.searchTerm) {
        queryParams += `&searchTerm=${filter?.searchTerm}`;
      }
      if (filter?.title) {
        queryParams += `&appointmentType=${filter?.title.toLowerCase()}`;
      }
      const data = await get(`/patientList?${queryParams}`, getAuthConfig());
      if (data.status === 200) {

        if (page > 0) {
          setPatientList((p) => ({ ...p, items: patientList?.items.concat(data?.data?.data) }));
        } else {
          setPatientList((p) => ({ ...p, items: data?.data?.data }));
        }
        setPatientList((p) => ({ ...p, has_more: data?.data?.has_more }))
      }

    } catch (error) {
      if (error.response.status === 401) {
        Auth.logout();
        navigate("/login")

      }
    }
      finally {
        setLoading(false);
      }
  }

  return (
    <Layout>
      <TopBar title={"All Patient"} tab={"Patient"} tabChild={"All Patient"} />
      <div className="main-content-part">
        <div className="serchPart">
          <div className="searchInput">
            <div className="searchIcon">
              <img src={SearchIcon} alt="searchIcon" />
            </div>
            <input type="text" placeholder="Search patient" value={filter?.searchTerm} onChange={(e) =>{
              e.preventDefault();
              setFilter((p)=>({...p,searchTerm:e?.target?.value}) )
            }}/>
            <div className="filterIcon" onClick={(e) =>{
              e.preventDefault();
              handleShowFilter()
            }}>
              <img src={FilterIcon} alt="filterIcon" />
            </div>
          </div>
        </div>
        <Row className="g-4">

        {loading && page === 0 ? (
            <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div>
          ) : 
          (
            <>

          {patientList?.items && patientList?.items?.length <= 0 && <div className="text-center">No Patient found</div>}
          {patientList?.items && patientList?.items?.length > 0 &&

            patientList?.items?.map((item, index) => {
              const istTimestamp = moment(item?.created_at).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss');
              return (
                <>

                  <Col lg="6" ref={lastBookElementRef} >
                    <Link to="/patientDetails" state={{ patient: item }} className="linkCard">
                      <div className='doctor-card upcomingPatientsCard'>
                        <div className='doctor-card-content'>
                          <div className='image-part d-flex align-items-center'>
                            <img src={item?.profile_image ? item?.profile_image : item?.gender === "female" ? PatientFemale : item?.gender === "male" ? PatientMale : PatientCommon}  height={100} alt='Patient' className='w-100 rounded-circle' />
                          </div>
                          <div className='text-part w-100'>
                            <h5>{item?.name}</h5>
                            <p className='gray' style={{fontSize:"14px"}}>{item?.lastAppointmentFor ? item?.lastAppointmentFor : item?.inguredBodyPart }</p>
                            <p>{ moment(istTimestamp).format('h:mm A')} <span style={{ color: '#ADADAD' }}> {moment(item?.created_at).format("MMM DD")}</span></p>
                          
                           {item?.lastDate  ? <Button className="componentBtn green-btn mt-3">{moment(item?.lastDate).format("DD MMM YYYY")}</Button>: ""}  
                          </div>
                        </div>
                        <div className='progressBar'>
                          <div className='progressText'>
                            <p>Progress</p>
                            <p>{item?.progressPercentage.toFixed(2)}%</p>
                          </div>
                          <ProgressBar now={item?.progressPercentage} />
                        </div>
                        <div className='bottom-bar'>
                          <p>{user?.name}</p>
                        </div>
                      </div>            </Link>
                  </Col>
                </>
              )
            })


          }
          </>)}
          {loading && page !== 0 && (
            <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div>
          )}


        </Row>
      </div>

      <Modal show={showFilter} onHide={handleCloseFilter} centered size="md">
                    <Modal.Header closeButton>
                        <Modal.Title className='text-center'>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pb-0'>
                        <div className='body-content'>
                            <ul className='filterList'>
                                {bodyPartData?.items && bodyPartData?.items.length > 0 && (
                                    bodyPartData.items.map((item, index) => (
                                        <li
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                setFilter((prevFilter) => ({
                                                    ...prevFilter,
                                                    title:item?.title,
                                                }));
                                            }}
                                        >
                                            <a >{item?.title}
                                                {filter?.title === item?.title && (
                                                    <div className="select-img">
                                                        <img src={checkIcon} alt="checkIcon" style={{ display: "block" }} />
                                                    </div>
                                                )}
                                            </a>
                                        </li>
                                    ))
                                )}


                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='theme-button' onClick={(e) => {
                            e.preventDefault();
                            handleCloseFilter();

                        }}>
                            Done
                        </Button>
                    </Modal.Footer>
                </Modal>

    </Layout>
  );
};

export default AllPatient;
