import React, { useEffect, useState } from 'react'
// import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import { Row, Col, Button } from 'react-bootstrap';
import '../../assets/css/doctorInfo.css'
import { useLocation, Link, useNavigate } from 'react-router-dom';
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { get, getAuthConfig, post } from '../../libs/http-hydrate';
import Auth from '../../libs/auth';
const DoctorAllSlot = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [SlotsDetail, setSlotsDetail] = useState();
    const [BlockedList, setBlockedList] = useState();
    const [selectedSlot, setSelectedSlot] = useState({
        appoinmentFor: "",
        slot: ""
    });

    useEffect(() => {

        getSlotDetails()

    }, [selectedDate])


    const handleDateChange = (newValue) => {
        setSelectedDate(moment(newValue?.$d).format("YYYY-MM-DD"));
    };


    async function getSlotDetails() {
        try {
            const data = await get(`/Slots?doctor_id=${location?.state?.doctor_id}&date=${selectedDate}`, getAuthConfig());
            if (data?.status === 200) {
                setSlotsDetail(data?.data?.msg[0]);
                setBlockedList(data?.data?.bookedList)
            }
            else {
            }

        } catch (error) {
            if (error?.status === 401) {
                Auth.logout()
                navigate("/login")
            }

            if (error?.response?.status === 400) {
                setSlotsDetail()
            }
        }
    }
    // Separate slots into morning, evening, and night arrays
    const [morningSlots, setMorningSlots] = useState([]);
    const [eveningSlots, setEveningSlots] = useState([]);
    const [nightSlots, setNightSlots] = useState([]);
    useEffect(() => {
        if (SlotsDetail?.Time && selectedDate) {
            const allSlots = [];
            const now = moment();

            // Generate slots based on startTime, endTime, and duration
            SlotsDetail?.Time?.forEach((slot) => {
                const { startTime, endTime, duration } = slot;
                const startMoment = moment(startTime, 'HH:mm:ss');
                const endMoment = moment(endTime, 'HH:mm:ss');

                while (startMoment.add(duration, 'minutes').isSameOrBefore(endMoment)) {
                    allSlots.push({
                        start: startMoment.clone().subtract(duration, 'minutes'),
                        end: startMoment.clone(),
                    });
                }
            });

            // ...

            // Check if the selected date is today
            const isToday = moment(selectedDate).isSame(moment(), 'day');

            // Filter slots for the selected date and future dates, excluding past dates
            const filteredSlots = allSlots.filter((slot) => {
                if (isToday) {
                    // If it's today, only include slots that have not started yet (start time is after current time)
                    const isBooked = BlockedList.some((bookedSlot) => {
                        return (
                            moment(bookedSlot.date).isSame(selectedDate, "day") &&
                            bookedSlot.timeSlot === slot.start.format("HH:mm:ss")
                        );
                    });

                    return !isBooked && slot.start.isAfter(now);

                    return slot.start.isAfter(now);
                } else {
                    // For other dates, include all slots for that date and future dates
                    return true;
                }
            });
            // Classify filtered slots into morning, evening, and night
            const morning = [];
            const evening = [];
            const night = [];
            filteredSlots.forEach((slot) => {
                const startHour = slot.start.hour();
                if (startHour >= 6 && startHour < 12) {
                    morning.push(slot);
                } else if (startHour >= 12 && startHour < 18) {
                    evening.push(slot);
                } else {
                    night.push(slot);
                }
            });

            setMorningSlots(morning);
            setEveningSlots(evening);
            setNightSlots(night);
        } else {
            // If there are no slots for the selected date, set the slot states to empty arrays
            setMorningSlots([]);
            setEveningSlots([]);
            setNightSlots([]);
        }
    }, [SlotsDetail, selectedDate, BlockedList]);


    async function bookAppointmentDoctor() {
        const appoinmentId = SlotsDetail?._id;
        const timeSlot = selectedSlot?.slot;
        const selectedDates = selectedDate;
        const appoinmentFor = selectedSlot?.appoinmentFor;

        // Perform validation for mandatory fields
        if (!appoinmentId || !timeSlot || !selectedDates || !appoinmentFor) {
            // Show an error message to the user indicating the missing fields
            toast.error("Please fill in all mandatory fields.");
            return;
        }

        const formData = new FormData();

        formData.append("appoinment_id", SlotsDetail?._id)
        formData.append("timeSlot", selectedSlot?.slot)
        formData.append("date", selectedDate)
        formData.append("appoinmentFor", selectedSlot?.appoinmentFor)


        try {
            const response = await post("/BookAppoinment", formData, getAuthConfig());
            if (response?.status === 200) {
                setSelectedSlot({
                    appoinmentFor: "",
                    slot: "",
                });
                getSlotDetails()
                toast.success("Appointment Booked successfully ")
                setTimeout(() => {

                }, 2000)
            } else {

            }
        } catch (error) {
            toast.error(error?.response?.data?.err)
        }

    }


    const handleSlotSelect = (slot) => {
        const selectedTime = slot.start.format("HH:mm:ss");
        setSelectedSlot({
            appoinmentFor: selectedSlot.appoinmentFor,
            slot: selectedTime,
        });
    };

    return (
        <Layout>

<TopBar  title={"Book Appointment"}   tab={"Appointment"} tabChild={"Book Appointment"} />
            <div className='main-content-part topBox'>
                <div className='innerInfo withoutOrange'>
                    <Row>
                        <Col lg={5}>
                            <div className='doctorDetailsCard'>
                                <div className="doctorContent">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateCalendar
                                            autoFocus={true}
                                            onChange={(newValue) => {
                                                // Handle the date change if needed


                                                setSelectedDate(moment(newValue?.$d).format("YYYY-MM-DD"));
                                            }}

                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className='doctorDetailsCard startdata mb-3'>
                                <div className='rightPartData'>
                                    <div className='locationTimeField filed pb-3'>
                                        <div className='block'>
                                            <p>{location?.state?.doctor?.hospital_address}</p>
                                            <p>{

<span> {morningSlots?.length + eveningSlots.length + nightSlots.length } Slots available</span>}</p>
                                            <h5 className='mt-1'>${location?.state?.doctor?.hospital_fees} Fees</h5>
                                        </div>
                                        <div className='block'>
                                            <p>Wait Time</p>
                                            <p className='badge'>60 min</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='doctorDetailsCard startdata'>
                                <div className='rightPartData'>
                                    {morningSlots.length > 0 && (
                                        <div className='infoField filed border-0'>
                                            <p>Morning</p>
                                            <div className='radio-badge-part'>
                                                <form>
                                                    {morningSlots?.map((slot, index) => (
                                                        <label key={index}>
                                                            <input type="radio" name="radio" onClick={() => handleSlotSelect(slot)}
                                                            />
                                                            <span>{slot.start.format("h:mm A")}</span>
                                                        </label>
                                                    ))}
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                    {eveningSlots.length > 0 && (

                                        <div className='infoField filed border-0'>
                                            <p>Evening</p>
                                            <div className='radio-badge-part'>
                                                <form>
                                                    {eveningSlots?.map((slot, index) => (
                                                        <label key={index}>
                                                            <input type="radio" name="radio" onClick={() => handleSlotSelect(slot)} />
                                                            <span>{slot.start.format("h:mm A")}</span>
                                                        </label>
                                                    ))}
                                                </form>
                                            </div>
                                        </div>)}
                                    {nightSlots.length > 0 && (

                                        <div className='infoField filed'>
                                            <p>Night</p>
                                            <div className='radio-badge-part'>
                                                <form>
                                                    {nightSlots?.map((slot, index) => (
                                                        <label key={index}>
                                                            <input type="radio" name="radio" onClick={() => handleSlotSelect(slot)} />
                                                            <span>{slot.start.format("h:mm A")}</span>
                                                        </label>
                                                    ))}
                                                </form>
                                            </div>


                                        </div>
                                    )}
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <div className='buttonPart mt-5 d-flex justify-content-center'>
                        <Button className='theme-button'>Book Appointment</Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DoctorAllSlot
