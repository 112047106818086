import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import { Row, Col, Button } from 'react-bootstrap';
import '../../assets/css/doctorInfo.css'
import UpcomingPatientsCard from '../../components/UpcomingPatientsCard'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { get, getAuthConfig } from '../../libs/http-hydrate';
import Auth from '../../libs/auth';
import { UseEffectOnce } from '../../Hook/UseEffectOnce';
import moment from 'moment';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import dayjs from 'dayjs';
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import Badge from "@mui/material/Badge";

const UpcomingAppointment = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [Appointment, setAppointment] = useState({
    items:[],
    has_more:false,
  });
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [page,setPage] = useState(0);
  const [date, setDate] = useState(dayjs());

  UseEffectOnce(() =>{
    getAppointmentData()
  },[])

  useEffect(() =>{
    if(page >  0){
      getAppointmentData()
    }
    if(page > 0 && !date){
      getAppointmentData()
    }if(page === 0  && date){
      getAppointmentData()
    }
  },[page,date])

  useEffect(() =>{
    setPage(0)
  },[date])


  
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && Appointment?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [Appointment?.has_more]
  );
  async function getAppointmentData() {
    try {
      setIsLoading(true); // Start loading

        let url ;
        if(date){
          url = `/appointmentList?pageNumber=${page}&date=${moment(date?.$d).format("YYYY-MM-DD")}`;

        }
        else{
          url = `/appointmentList?pageNumber=${page}`;
        }
        const data = await get(url, getAuthConfig());
        if (data?.status === 200) {
          if(page > 0 ){
            setAppointment((p) => ({ ...p, items: Appointment.items.concat(data?.data?.data) }));
          }else{
            setAppointment((p) => ({ ...p, items: data?.data?.data }));
          }
          if(data?.data?.appointmentDates){
            setHighlightedDays(data?.data?.appointmentDates);
          }
          setAppointment((p) => ({ ...p, has_more: data?.data?.has_more }))
          }
    } catch (error) {
        if (error?.response?.status === 401) {
            Auth.logout();
            navigate("/login")
        }
    }finally{
      setIsLoading(false); // Stop loading
    }
}  

const renderDay = (date, selectedDates, pickersDayProps) => {
  const dateString = dayjs(date).format("YYYY-MM-DD");
  const isHighlighted = highlightedDays?.includes(dateString);

  if (isHighlighted) {
    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          border: "2px solid #ff6036",
          borderRadius: "50%",
          color: pickersDayProps.outsideCurrentMonth ? "text.disabled" : "inherit",
        }}
      />
    );
  }

  return <PickersDay {...pickersDayProps} />;
};

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const dateString = dayjs(day).format("YYYY-MM-DD");
  const isFullCompleted = highlightedDays.includes(dateString);

  // Initialize isSelected as false
  const isSelected = day?.$D == date?.$D
  const customStyles = {
    backgroundColor: isSelected
        ? "#f3603729"
      : "transparent",
    color: isFullCompleted ? "#ff6036" : "inherit",
    border:
      isFullCompleted
        ? "2px solid #ff6036"
        : day?.$D == date?.$D 
        ? "none"
        : "none", // Add a 2px solid orange border if isSelected and isFullCompleted
  };

  return (
    <Badge key={day.toString()} overlap="circular">
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        style={customStyles}
      />
    </Badge>
  );
}
const handleMonthChange = (newDate) => {
  setDate(newDate);

  setHighlightedDays([]);
};
  return (
    <Layout>
    <TopBar  title={"Upcoming Appointment"}   tab={"Home"} tabChild={"Upcoming Appointment"} />
      <div className='main-content-part topBox'>
        <div className='innerInfo withoutOrange'>

        {isLoading ?  
            <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div>
         :  
            <Row>
                <Col lg={5}>
                    <div className='doctorDetailsCard bg-white' style={{position:"fixed"}}>
                        <div className="doctorContent bg-white " >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar 
                                
                                value={date}
                                renderLoading={() => <DayCalendarSkeleton />}
                                slots={{
                                  day: ServerDay,
                                }}
                                slotProps={{
                                  day: {
                                    highlightedDays,
                                  },
                                }}
                                onMonthChange={handleMonthChange}
                                onChange={(newValue) => {
                                  setDate(newValue);
                                }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </Col>
                <Col lg={7}>

                <h6 className="title my-2">
                          {moment(date?.$d).format("Do MMM")} Appointments
                </h6>
                  {Appointment?.items && Appointment?.items.length === 0 && "No Appointment for the day "}

                  {Appointment?.items && Appointment?.items.length > 0  && 
                   Appointment?.items?.map((item,index) =>{
                    return(
                      <>
                    <div className='mb-4' ref={lastBookElementRef}>
                        <UpcomingPatientsCard Appointment={item} />
                    </div>
                      </>
                    )
                   })
                  }
                   
                    
                </Col>
            </Row>
}
        </div>
      </div>
    </Layout>
  )
}

export default UpcomingAppointment
