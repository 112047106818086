import React, { useEffect, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import { Row, Col, Button, ProgressBar } from 'react-bootstrap';
import '../../assets/css/doctorInfo.css'
import UpcomingPatientsCard from '../../components/UpcomingPatientsCard'
import Auth from '../../libs/auth';
import { get, getAuthConfig } from '../../libs/http-hydrate';
import { Link, useNavigate } from 'react-router-dom';
import { UseEffectOnce } from '../../Hook/UseEffectOnce';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { format } from 'date-fns';

const MyAppointment = () => {
  const user = Auth.getCurrentUser()
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [ttodayAppointments, settodayAppointments] = useState([]);
  const [noAppointments, setnoAppointments] = useState([]);


  const [selectedMonth,setSelectedMonth] = useState()
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [date, setDate] = useState(new Date())

  UseEffectOnce(() => {
    getHomeData()
  }, [])
  useEffect(() => {

   getHomeData()

  }, [date])

  async function getHomeData() {
    try {
      const data = await get(`/appointmentList?date=${moment(date).format("YYYY-MM-DD")}`, getAuthConfig());
  
      if (data?.status === 200) {
        let todayStart = new Date();
        todayStart.setUTCHours(0, 0, 0, 0);
        let todayEnd = new Date();
        todayEnd.setUTCHours(23, 59, 59, 999);
  
        // Separate completed and upcoming appointments based on their status
        const completed = [];
        const upcoming = [];
        const today = [];
        const noDateAppointments =[]
        data?.data?.data.forEach((appointment) => {
         
          // Check if the appointment has a 'date' field
  if (appointment?.date) {
    const appointmentDate = new Date(appointment.date);
    if (appointmentDate < todayStart) {
      completed.push(appointment);
    } else if (appointmentDate > todayEnd) {
      upcoming.push(appointment);
    } else {
      today.push(appointment);
    }
  } else {
   noDateAppointments.push(appointment);
  }
        });
  
        setnoAppointments(noDateAppointments)
        setCompletedAppointments(completed);
        setUpcomingAppointments(upcoming);
        settodayAppointments(today);
        setData(data?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login");
      }
    }
  }
  
  return (
    <Layout>

      <TopBar title={"My Appointments"} tab={"Profile"} tabChild={"My Appointments"} />
      <div className='main-content-part topBox'>
        <div className='innerInfo withoutOrange'>
          <Row>
            <Col lg={5}>
              <div className='doctorDetailsCard'>
                <div className="doctorContent">

        


<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        autoFocus={true}
        onChange={(newValue) => {
          // Handle the date change if needed


          setDate(moment(newValue?.$d).format("YYYY-MM-DD"));
        }}
      
      />
    </LocalizationProvider>




                  {/* <div className='text-center d-flex'>

                    <div className='align-items-center px-2'> <button className=' green-btn btn componentBtn' style={{ width: "10px", borderRadius: "10px", border: "none" }}></button> completed</div>
                    <div className='align-items-center'> <button className=' btn componentBtn ' style={{ "background-color": "#FF6036", width: "10px", borderRadius: "10px", border: "none" }}></button>  upcoming</div>

                  </div> */}
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className='doctorDetailsCard mb-3'>
                <div className='patientListpartTitle'>
                  <h6>{moment(date).format("Do MMM")} patient</h6>
                </div>

{ttodayAppointments && ttodayAppointments.length <= 0  &&  <div className='text-center'>No Appoinment for the day</div>}
                {ttodayAppointments && ttodayAppointments.length > 0 &&
                 ttodayAppointments?.map((item,index) =>{
                  return(
                    <>
                     <div className='mb-4 w-100'>

                     <Link to={"/patientDetails"} state={{patient:item?.patient_id}}>
   <div className='doctor-card upcomingPatientsCard'>
    <div className='doctor-card-content'>
        <div className='image-part d-flex align-items-center'>
            <img src={item?.patient_id?.profile_image} alt='Patient' height={100} width={100} style={{borderRadius:"50%"}} className='w-100'/>
        </div>
        <div className='text-part w-100'>
            <h5>{item?.patient_id?.name}</h5>
            <p className='gray'>{item?.appoinmentFor}</p>
            <p>{moment(item?.timeSlot, ["hh:mm:ss"]).format("HH:mm A")} <span style={{ color: '#ADADAD'}}>{moment(item?.date).format("MMM DD")}</span></p>
            <Button className="componentBtn green-btn mt-3">{moment(item?.date).format("DD MMM YYYY")}</Button>
        </div>
    </div>
    <div className='progressBar'>
        <div className='progressText'>
            <p>Progress</p>
            <p>{item?.patient_id?.progressPercentage?.toFixed(2)}%</p>
        </div>
        <ProgressBar now={item?.patient_id?.progressPercentage} />
    </div>
   <div className='bottom-bar'>
        <p>{user?.name}</p>
   </div>
   </div>
   </Link>
                </div>
                    
                    </>
                  )

                 })}
               

               {noAppointments && noAppointments.length > 0 &&
                 noAppointments?.map((item,index) =>{
                  return(
                    <>
                     <div className='mb-4 w-100 '>

                     <Link to={"/patientDetails"} state={{patient:item?.patient_id}}>
   <div className='doctor-card upcomingPatientsCard fromAdd'>
    <div className='doctor-card-content'>
        <div className='image-part d-flex align-items-center'>
            <img src={item?.patient_id?.profile_image} alt='Patient' height={100} width={100} style={{borderRadius:"50%"}} className='w-100'/>
        </div>
        <div className='text-part w-100'>
            <h5>{item?.patient_id?.name}</h5>
            <p className='gray'>{item?.patient_id?.inguredBodyPart  }</p>
            {/* <p>{ item?.timeSlot ? moment(item?.timeSlot, ["hh:mm:ss"]).format("HH:mm A") : ""} <span style={{ color: '#ADADAD'}}>{moment(item?.date).format("MMM DD")}</span></p> */}
            <Button className="componentBtn green-btn mt-3">{moment(item?.date).format("DD MMM YYYY")}</Button>
        </div>
    </div>
    <div className='progressBar'>
        <div className='progressText'>
            <p>Progress</p>
            <p>{item?.patient_id?.progressPercentage?.toFixed(2)}%</p>
        </div>
        <ProgressBar now={item?.patient_id?.progressPercentage} />
    </div>
   <div className='bottom-bar'>
        <p>{user?.name}</p>
   </div>
   </div>
   </Link>
                </div>
                    
                    </>
                  )

                 })}
              
              </div>

            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default MyAppointment
