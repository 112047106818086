import Layout from "../layout";
import SearchIcon from "../assets/images/search.svg";
import Carousel from "react-bootstrap/Carousel";
import doctorPayment from "../assets/images/jhnavi.png";
import doctorPayment1 from "../assets/images/gaytri.jpeg";
import doctorPayment2 from "../assets/images/hiral.jpg";

import portrait1 from "../assets/images/portrait1.png";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { useEffect, useState } from "react";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { Link, useNavigate } from "react-router-dom";
import { Row , Col } from "react-bootstrap";
import include from "../assets/images/include.png";
import axios from "axios";

function PaymentStripe() {
  const [key, setKey] = useState("home");
  const presets = [0, 10, 20, 30, 40, 50, 60, 70];
  const [plansDetails, setplansDetails] = useState({});
  const [selectedPlanDetails, setSelectedPlanDetails] = useState();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("Monthly");

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  UseEffectOnce(() => {
    getBodyPartData();
    getMyPlanDetails();
  }, []);

  async function getBodyPartData() {
    try {
      const data = await get(`/stripe/api/plans`, getAuthConfig());
      if (data?.status === 200) {
        const plans = data?.data?.plans;
        const basicPlan = {
          id: "basic",
          item: {
            name: "Basic",
            amount: 0, // Amount should be zero for the free plan
          },
          nickname: "Basic",
          amount: 0,
          // Add other necessary properties for the plan
        };
        const updatedPlans = [...plans, basicPlan];
        setplansDetails((p) => ({ ...p, items: updatedPlans }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }

  async function getMyPlanDetails() {
    try {
      const data = await get(`/api/myplan`, getAuthConfig());
      if (data?.status === 200) {
        setSelectedPlanDetails(data?.data?.msg?.price);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }
  useEffect(() => {
    const carouselElement = document.getElementById(
      "carouselExampleIndicators"
    );

    const handleSlideChange = (event) => {
      const activeSlideIndex = event.to;
      const imageElement = document.getElementById("doctorPaymentImage");

      switch (activeSlideIndex) {
        case 0:
          imageElement.src = doctorPayment;
          break;
        case 1:
          imageElement.src = doctorPayment1;
          break;
        case 2:
          imageElement.src = doctorPayment2;
          break;
      }
    };

    carouselElement.addEventListener("slide.bs.carousel", handleSlideChange);

    return () => {
      carouselElement.removeEventListener(
        "slide.bs.carousel",
        handleSlideChange
      );
    };
  }, []);



   const [plansDetailss, setPlansDetailss] = useState([]);
  const [location, setLocation] = useState({});
  // const navigate = useNavigate();

  useEffect(() => {
    fetchLocation();
  }, []);

  const fetchLocation = async () => {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      const userLocation = response.data;
      setLocation(userLocation);
      handleLocationBasedRedirection(userLocation);
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };

  const handleLocationBasedRedirection = (userLocation) => {
    if (userLocation.country === 'CA') {
      // If the user is from Canada, set Canadian plan details
      setPlansDetailss([{
        "id": "price_1Pr0IuG8OCISy64iS0gP4xsu",
        "object": "plan",
        "active": true,
        "aggregate_usage": null,
        "amount": 1995,
        "amount_decimal": "1995",
        "billing_scheme": "per_unit",
        "created": 1724430336,
        "currency": "cad",
        "interval": "month",
        "interval_count": 1,
        "livemode": true,
        "metadata": {
            "description": "credits:50"
        },
        "meter": null,
        "nickname": "One Simple Pricing Plan",
        "product": "prod_QiRBj6hui7Uvsj",
        "tiers_mode": null,
        "transform_usage": null,
        "trial_period_days": null,
        "usage_type": "licensed"
    }]);
    } else {
      // Default to US plan details
      setPlansDetailss([{
        "id": "price_1Pr0JhG8OCISy64iDkjRPtCz",
        "object": "plan",
        "active": true,
        "aggregate_usage": null,
        "amount": 1499,
        "amount_decimal": "1499",
        "billing_scheme": "per_unit",
        "created": 1724430385,
        "currency": "usd",
        "interval": "month",
        "interval_count": 1,
        "livemode": true,
        "metadata": {
            "description": "credits:5"
        },
        "meter": null,
        "nickname": "One Simple Pricing Plan",
        "product": "prod_QiRCaii0vNB3br",
        "tiers_mode": null,
        "transform_usage": null,
        "trial_period_days": null,
        "usage_type": "licensed"
    }]);
    }
  };

  const handlePlanSelection = (plan) => {
    navigate("/paymentFormStripe", { state: { plan } });
  };

  
  return (
    <>
      <Layout>
        <div
          className="main-content-part foot-page p-0 "
          defaultActiveKey="first"
          style={{ backgroundColor: "#fff9f7a8" }}
        >
{location.country === 'CA' ? 

<>
<div className="payment-physio-section">
        <div className="container">
          <div className="title-part-best">
            <h6 className="">pricing</h6>
            <h2 className="split-heading">
              <span>One Simple Pricing Plan</span>
            </h2>
            <div className="subscription-plan-selector">
            <button
                  className={selectedPlan === "Monthly" ? "monthly-button active" : "quarterly-button"}
                  onClick={() => handlePlanChange("Monthly")}
                >
                  Monthly
                </button>
                <button
                  className={selectedPlan === "Quarterly" ? "monthly-button active" : "quarterly-button"}
                  onClick={() => handlePlanChange("Quarterly")}
                >
                  Quarterly
                </button>
                <button
                  className={selectedPlan === "Yearly" ? "monthly-button active" : "yearly-button"}
                  onClick={() => handlePlanChange("Yearly")}
                >
                  Yearly
                </button>
              
            </div>
            <div className="d-flex justify-content-center mt-3">
            <div className="pricing-container">
              <div className="price-container">
              {selectedPlan === "Monthly" && (
                      <>
                        <p className="price-strike-through">CAD 19.99</p>
                        <p className="price-highlighted-text">CAD 12.99</p>
                        <p className="monthly-price-label">per month</p>
                      </>
                    )}
                    {selectedPlan === "Quarterly" && (
                      <>
                        <p className="price-strike-through">CAD 52.99</p>
                        <p className="price-highlighted-text">CAD 32.99</p>
                        <p className="monthly-price-label">per quarter</p>
                      </>
                    )}
                    {selectedPlan === "Yearly" && (
                      <>
                        <p className="price-strike-through">CAD 199.99</p>
                        <p className="price-highlighted-text">CAD 132.99</p>
                        <p className="monthly-price-label">per year</p>
                      </>
                    )}
              </div>
              <div className="promo-banner">
                <p className="promo-banner-text-style">
                  Special Offer, Grab It Now!
                </p>
              </div>{" "}
            </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="include-section"
        style={{ backgroundColor: "#FFEDE8" }}
      >
        <div className="container">
          <p className="include-title-text">What we include</p>
          <div className="include-allcontent-section d-flex justify-content-center ">
            <div className="row">
            <div className="col-md-6 col-12">
                <div className="include-content-section ">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                   Monthly <b>30 Patient Access</b>
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Browse a library of over <b>1,600+ exercise</b>
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Print and email workout plans (Up to 50 times.){" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Expand your patient base{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Track patient activity & get max result{" "}
                  </p>
                </div>
                </div>
              <div className="col-md-6 col-12">
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Web & Mobile App{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access more then <b>250 protocols</b>{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Add your own exercise and workout protocols{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Seamless workout management{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access large Physiotherapist’s community{" "}
                  </p>
                </div>
                </div>
                </div>
          </div>

          <div className="include-list-container">
            <div className="store-name d-flex justify-content-center">
              <a
                href="https://physiotherapist.recureme.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="px-3 rounded"
                  style={{
                    backgroundColor: "rgb(255, 72, 23)",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    border: "none",
                    width: "250px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if(selectedPlan === "Monthly"){
                      navigate("/paymentFormStripe", {
                        state: {
                          plan: {
                            "id": "price_1PxSzCG8OCISy64iZh4kI1dw",
                            "object": "plan",
                            "active": true,
                            "aggregate_usage": null,
                            "amount": 1299,
                            "amount_decimal": "1299",
                            "billing_scheme": "per_unit",
                            "created": 1725970558,
                            "currency": "cad",
                            "interval": "month",
                            "interval_count": 1,
                            "livemode": true,
                            "metadata": {
                                "description": "credits:30"
                            },
                            "meter": null,
                            "nickname": "One Simple Pricing Plan - Monthly - CAD",
                            "product": "prod_Qp7DrFAwD94ONI",
                            "tiers_mode": null,
                            "transform_usage": null,
                            "trial_period_days": null,
                            "usage_type": "licensed"
                        },
                        },
                      });
                    }else if (selectedPlan === "Yearly"){
                      navigate("/paymentFormStripe", {
                        state: {
                          plan: {
                            "id": "price_1PxT5CG8OCISy64iBXgsO3AE",
                            "object": "plan",
                            "active": true,
                            "aggregate_usage": null,
                            "amount": 13299,
                            "amount_decimal": "13299",
                            "billing_scheme": "per_unit",
                            "created": 1725970930,
                            "currency": "cad",
                            "interval": "year",
                            "interval_count": 1,
                            "livemode": true,
                            "metadata": {
                                "description": "credits:360"
                            },
                            "meter": null,
                            "nickname": "One Simple Pricing Plan - Yearly - CAD",
                            "product": "prod_Qp7JFxzrcBXgjl",
                            "tiers_mode": null,
                            "transform_usage": null,
                            "trial_period_days": null,
                            "usage_type": "licensed"
                        },
                        },
                      });
                    }else if (selectedPlan === "Quarterly")
                    {   navigate("/paymentFormStripe", {
                      state: {
                        plan: {
                          "id": "price_1PxT2dG8OCISy64iqdnpg5qI",
                          "object": "plan",
                          "active": true,
                          "aggregate_usage": null,
                          "amount": 3299,
                          "amount_decimal": "3299",
                          "billing_scheme": "per_unit",
                          "created": 1725970771,
                          "currency": "cad",
                          "interval": "month",
                          "interval_count": 3,
                          "livemode": true,
                          "metadata": {
                              "description": "credits:90"
                          },
                          "meter": null,
                          "nickname": "One Simple Pricing Plan - Quarterly - CAD",
                          "product": "prod_Qp7HyxZRe9GMLG",
                          "tiers_mode": null,
                          "transform_usage": null,
                          "trial_period_days": null,
                          "usage_type": "licensed"
                      },
                      },
                    });

                    }
                 
                  }}
                >
                  Subscribe
                </button>
              </a>
            </div>{" "}
            <p className="sales-team-contact-info mb-0">
              <span className="friendly-sales-team-text-style">
                or connect our friendly sales team at{" "}
              </span>
              <span className="protocol-count-text-style">
                support@recureme.com
              </span>
            </p>
          </div>
        </div>
      </section>
      </>:

<>
<div className="payment-physio-section">
        <div className="container">
          <div className="title-part-best">
            <h6 className="">pricing</h6>
            <h2 className="split-heading">
              <span>One Simple Pricing Plan</span>
            </h2>
            <div className="subscription-plan-selector">
            <button
                  className={selectedPlan === "Monthly" ? "monthly-button active" : "quarterly-button"}
                  onClick={() => handlePlanChange("Monthly")}
                >
                  Monthly
                </button>
                <button
                  className={selectedPlan === "Quarterly" ? "monthly-button active" : "quarterly-button"}
                  onClick={() => handlePlanChange("Quarterly")}
                >
                  Quarterly
                </button>
                <button
                  className={selectedPlan === "Yearly" ? "monthly-button active" : "yearly-button"}
                  onClick={() => handlePlanChange("Yearly")}
                >
                  Yearly
                </button>
              
            </div>
            <div className="d-flex justify-content-center mt-3">
            <div className="pricing-container">
              <div className="price-container">
              {selectedPlan === "Monthly" && (
                      <>
                        <p className="price-strike-through">USD 14.99</p>
                        <p className="price-highlighted-text">USD 9.99</p>
                        <p className="monthly-price-label">per month</p>
                      </>
                    )}
                    {selectedPlan === "Quarterly" && (
                      <>
                        <p className="price-strike-through">USD 39.99</p>
                        <p className="price-highlighted-text">USD 24.99</p>
                        <p className="monthly-price-label">per quarter</p>
                      </>
                    )}
                    {selectedPlan === "Yearly" && (
                      <>
                        <p className="price-strike-through">USD 149.99</p>
                        <p className="price-highlighted-text">USD 99.99</p>
                        <p className="monthly-price-label">per year</p>
                      </>
                    )}
              </div>
              <div className="promo-banner">
                <p className="promo-banner-text-style">
                  Special Offer, Grab It Now!
                </p>
              </div>{" "}
            </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="include-section"
        style={{ backgroundColor: "#FFEDE8" }}
      >
        <div className="container">
          <p className="include-title-text">What we include</p>
          <div className="include-allcontent-section d-flex justify-content-center ">
            <div className="row">
            <div className="col-md-6 col-12">
                <div className="include-content-section ">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                   Monthly <b>30 Patient Access</b>
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Browse a library of over <b>1,600+ exercise</b>
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Print and email workout plans (Up to 50 times.){" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Expand your patient base{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Track patient activity & get max result{" "}
                  </p>
                </div>
                </div>
              <div className="col-md-6 col-12">
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Web & Mobile App{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access more then <b>250 protocols</b>{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Add your own exercise and workout protocols{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Seamless workout management{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access large Physiotherapist’s community{" "}
                  </p>
                </div>
                </div>
                </div>
          </div>

          <div className="include-list-container">
            <div className="store-name d-flex justify-content-center">
              <a
                href="https://physiotherapist.recureme.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="px-3 rounded"
                  style={{
                    backgroundColor: "rgb(255, 72, 23)",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    border: "none",
                    width: "250px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if(selectedPlan === "Monthly"){
                      navigate("/paymentFormStripe", {
                        state: {
                          plan: {
                            "id": "price_1PxRoYG8OCISy64ieASjzxdM",
                            "object": "plan",
                            "active": true,
                            "aggregate_usage": null,
                            "amount": 999,
                            "amount_decimal": "999",
                            "billing_scheme": "per_unit",
                            "created": 1725966054,
                            "currency": "usd",
                            "interval": "month",
                            "interval_count": 1,
                            "livemode": true,
                            "metadata": {
                                "description": "credits:30"
                            },
                            "meter": null,
                            "nickname": "One Simple Pricing Plan - Monthly - USD",
                            "product": "prod_Qp60vhM9X70yn0",
                            "tiers_mode": null,
                            "transform_usage": null,
                            "trial_period_days": null,
                            "usage_type": "licensed"
                        }
                        },
                      });
                    }else if (selectedPlan === "Yearly"){
                      navigate("/paymentFormStripe", {
                        state: {
                          plan: {
                            "id": "price_1PxSLBG8OCISy64i8bIZ2JvU",
                            "object": "plan",
                            "active": true,
                            "aggregate_usage": null,
                            "amount": 9999,
                            "amount_decimal": "9999",
                            "billing_scheme": "per_unit",
                            "created": 1725968077,
                            "currency": "usd",
                            "interval": "year",
                            "interval_count": 1,
                            "livemode": true,
                            "metadata": {
                                "description": "credits:360"
                            },
                            "meter": null,
                            "nickname": "One Simple Pricing Plan - Yearly - USD",
                            "product": "prod_Qp6YsYjtz7CxVY",
                            "tiers_mode": null,
                            "transform_usage": null,
                            "trial_period_days": null,
                            "usage_type": "licensed"
                        },
                        },
                      });
                    }else if (selectedPlan === "Quarterly")
                    {   navigate("/paymentFormStripe", {
                      state: {
                        plan: {
                          "id": "price_1PxSD2G8OCISy64ilvIr3E5l",
                          "object": "plan",
                          "active": true,
                          "aggregate_usage": null,
                          "amount": 2499,
                          "amount_decimal": "2499",
                          "billing_scheme": "per_unit",
                          "created": 1725967572,
                          "currency": "usd",
                          "interval": "month",
                          "interval_count": 3,
                          "livemode": true,
                          "metadata": {
                              "description": "credits:90"
                          },
                          "meter": null,
                          "nickname": "One Simple Pricing Plan - Quarterly - USD",
                          "product": "prod_Qp6PkvPF7QyJqS",
                          "tiers_mode": null,
                          "transform_usage": null,
                          "trial_period_days": null,
                          "usage_type": "licensed"
                      },
                      },
                    });

                    }
                 
                  }}
                >
                  Subscribe
                </button>
              </a>
            </div>{" "}
            <p className="sales-team-contact-info mb-0">
              <span className="friendly-sales-team-text-style">
                or connect our friendly sales team at{" "}
              </span>
              <span className="protocol-count-text-style">
                support@recureme.com
              </span>
            </p>
          </div>
        </div>
      </section>
      </>


                }
   {/* <div className="payment-physio-section">
          <div className="container">
            <div className="title-part-best">
              <h6 className="">Pricing</h6>
              <h2 className="split-heading">
                <span>One Simple Pricing Plan</span>
                <span className="mt-4" style={{ color: "#FF6036" }}>
                  {plansDetailss[0]?.currency.toUpperCase() === "USD" ? "USD $14.99" : "CAD $19.95"}
                </span>
              </h2>
              <p className="mb-0">per month</p>
            </div>
          </div>
        </div>

        <section
        className="include-section"
        style={{ backgroundColor: "#FFEDE8" }}
      >
        <div className="container">
          <p className="include-title-text">What we include</p>
          <div className="include-allcontent-section">
            <Row className="justify-content-center">
              <Col md={5} sm={12}>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    50 Patient Access
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Browse a library of over <b>1,600+ exercise</b>
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Print and email workout plans (Up to 50 times.){" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Expand your patient base{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Track patient activity & get max result{" "}
                  </p>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Web & Mobile App{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access more then <b>250 protocols</b>{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Add your own exercise and workout protocols{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Seamless workout management{" "}
                  </p>
                </div>
                <div className="include-content-section">
                  <img
                    alt=""
                    className="include-description-container"
                    src={include}
                  />
                  <p className="include-description-text-block">
                    Access large Physiotherapist’s community{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="include-list-container">
            <div className="store-name d-flex justify-content-center">
              <a
                href="https://physiotherapist.recureme.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="px-3 rounded"
                  style={{
                    backgroundColor: "rgb(255, 72, 23)",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    border: "none",
                    width: "250px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePlanSelection(plansDetailss[0]);
                  }}
                >
                  Subscribe
                </button>
              </a>
            </div>{" "}
            <p className="sales-team-contact-info mb-0">
              <span className="friendly-sales-team-text-style">
                or connect our friendly sales team at{" "}
              </span>
              <span className="protocol-count-text-style">
                support@recureme.com
              </span>
            </p>
          </div>
        </div>
      </section> */}

          <div className="container py-5">
            {/* <div className="text-center">
              <h6
                className="m-3"
                style={{
                  color: "#FF4817",
                  "text-transform": "uppercase",
                  "font-size": "16px",
                }}
              >
                our best features
              </h6>
              <h2 className="m-3">
                Choose Your Simple <br />
                Pricing Plan
              </h2>
              <span className="m-3">Choose a plan that’s right for you.</span>
            </div> */}
            {/* <div className="container mt-2">
              <div className="switches-container">
                <input
                  type="radio"
                  id="switchMonthly"
                  name="switchPlan"
                  value="Monthly"
                  checked={selectedPlan === "Monthly"}
                  onChange={handlePlanChange}
                />
                <input
                  type="radio"
                  id="switchYearly"
                  name="switchPlan"
                  value="Yearly"
                  checked={selectedPlan === "Yearly"}
                  onChange={handlePlanChange}
                />
                <label htmlFor="switchMonthly">Monthly</label>
                <label htmlFor="switchYearly">Yearly</label>
                <div className="switch-wrapper">
                  <div className="switchee">
                    <div>Monthly</div>
                    <div>Yearly</div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row row-cols-1 row-cols-md-4 g-4 mt-1 d-flex justify-content-center ">
              {plansDetails?.items &&
                plansDetails?.items?.length > 0 &&
                plansDetails?.items
                  ?.slice() // Create a copy of the array
                  .sort((a, b) => a.amount - b.amount) // Sort the array based on the amount
                  .map((item, index) => {
                    if (
                      item?.nickname?.includes("Clinic") ||
                      item?.nickname?.includes("Test_Rzp")
                    ) {
                      return null;
                    }

                    // return (
                    //   <>
                    //   { ((selectedPlan === "Monthly"  &&  item?.interval === "month") ) &&
                    //     <div className="col-md-3 col-lg-3 col-sm-12">
                    //       <div
                    //         className={`card cardFromPrice shadow-lg rounded ${
                    //           item?.amount / 100 === 29.99 &&
                    //           "cardFromPriceSelected"
                    //         }`}
                    //       >
                    //         <div className="card-body">
                    //           <h4 className="priceTitle">{item?.nickname}</h4>
                    //           <div className="d-flex align-items-center">
                    //             <h2 className="price">
                    //               {item?.amount <= 0
                    //                 ? "Free"
                    //                 : "$" + item?.amount / 100}{" "}
                    //             </h2>
                    //             <h2 className="priceSub">&nbsp; / Month</h2>
                    //           </div>
                    //           {/* <p className="priceData">Always Free</p> */}

                    //           <hr />
                    //           <ul className="features  list-unstyled">
                    //             {item?.name !== "Basic" && (
                    //               <li>
                    //                 <span className="successIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 <div>
                    //                   <strong>
                    //                     {item?.metadata?.description
                    //                       ? item?.metadata?.description.split(
                    //                           ":"
                    //                         )[1]
                    //                       : "10"}
                    //                   </strong>{" "}
                    //                   Patient Access
                    //                 </div>
                    //               </li>
                    //             )}
                    //             <li>
                    //               <span className="successIcon">
                    //                 <i className="fa fa-check"></i>
                    //               </span>{" "}
                    //               Web & Mobile App
                    //             </li>
                    //             <li>
                    //               <span className="successIcon">
                    //                 <i className="fa fa-check"></i>
                    //               </span>{" "}
                    //               Browse a library of over &nbsp;
                    //               <strong>1,600 exercises</strong>
                    //             </li>
                    //             <li>
                    //               <span className="successIcon">
                    //                 <i className="fa fa-check"></i>
                    //               </span>{" "}
                    //               Access more than &nbsp;
                    //               <strong>250 protocols</strong>
                    //             </li>
                    //             {item?.name !== "Basic" && (
                    //               <>
                    //                 <li>
                    //                   <span className="successIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Print and email workout plans (up to 50
                    //                   times)
                    //                 </li>
                    //                 <li>
                    //                   <span className="successIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Add patients to your panel
                    //                 </li>
                    //                 <li>
                    //                   <span className="successIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Create your own exercises and workout
                    //                   protocols
                    //                 </li>
                    //               </>
                    //             )}
                    //             {item?.name === "Basic" && (
                    //               <>
                    //                 <li>
                    //                   <span className="failureIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Export exercise plan
                    //                 </li>
                    //                 <li>
                    //                   <span className="failureIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Add Patient
                    //                 </li>
                    //                 <li>
                    //                   <span className="failureIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Assign exercise plan to patient
                    //                 </li>
                    //                 <li>
                    //                   <span className="failureIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Add personal exercise plan or personal
                    //                   exercises
                    //                 </li>
                    //                 <li>
                    //                   <span className="failureIcon">
                    //                     <i className="fa fa-check"></i>
                    //                   </span>{" "}
                    //                   Create your own exercises and workout
                    //                   protocols
                    //                 </li>
                    //               </>
                    //             )}
                    //           </ul>
                    //           <div className="w-100 text-center">
                    //             {item?.nickname !== "Basic" && (
                    //               <button
                    //                 onClick={(e) => {
                    //                   e.preventDefault();
                    //                   // setSelectedPlanDetails(item);
                    //                   // handleSelectPlan(item)
                    //                   navigate("/paymentFormStripe", {
                    //                     state: { plan: item },
                    //                   });
                    //                 }}
                    //                 className="select-btn"
                    //               >
                    //                 Select Plan
                    //               </button>
                    //             )}
                    //           </div>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   }  { ((selectedPlan === "Yearly"  &&  item?.interval === "year")  ||   item?.nickname === "Basic") &&
                    //   <div className="col-md-3 col-lg-3 col-sm-12">
                    //     <div
                    //       className={`card cardFromPrice shadow-lg rounded ${
                    //         item?.amount / 100 === 29.99 &&
                    //         "cardFromPriceSelected"
                    //       }`}
                    //     >
                    //       <div className="card-body">
                    //         <h4 className="priceTitle">{item?.nickname}</h4>
                    //         <div className="d-flex align-items-center">
                    //           <h2 className="price">
                    //             {item?.amount <= 0
                    //               ? "Free"
                    //               : "$" + item?.amount / 100}{" "}
                    //           </h2>
                    //           <h2 className="priceSub">&nbsp; / Month</h2>
                    //         </div>
                    //         {/* <p className="priceData">Always Free</p> */}

                    //         <hr />
                    //         <ul className="features  list-unstyled">
                    //           {item?.name !== "Basic" && (
                    //             <li>
                    //               <span className="successIcon">
                    //                 <i className="fa fa-check"></i>
                    //               </span>{" "}
                    //               <div>
                    //                 <strong>
                    //                   {item?.metadata?.description
                    //                     ? item?.metadata?.description.split(
                    //                         ":"
                    //                       )[1]
                    //                     : "10"}
                    //                 </strong>{" "}
                    //                 Patient Access
                    //               </div>
                    //             </li>
                    //           )}
                    //           <li>
                    //             <span className="successIcon">
                    //               <i className="fa fa-check"></i>
                    //             </span>{" "}
                    //             Web & Mobile App
                    //           </li>
                    //           <li>
                    //             <span className="successIcon">
                    //               <i className="fa fa-check"></i>
                    //             </span>{" "}
                    //             Browse a library of over &nbsp;
                    //             <strong>1,600 exercises</strong>
                    //           </li>
                    //           <li>
                    //             <span className="successIcon">
                    //               <i className="fa fa-check"></i>
                    //             </span>{" "}
                    //             Access more than &nbsp;
                    //             <strong>250 protocols</strong>
                    //           </li>
                    //           {item?.name !== "Basic" && (
                    //             <>
                    //               <li>
                    //                 <span className="successIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Print and email workout plans (up to 50
                    //                 times)
                    //               </li>
                    //               <li>
                    //                 <span className="successIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Add patients to your panel
                    //               </li>
                    //               <li>
                    //                 <span className="successIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Create your own exercises and workout
                    //                 protocols
                    //               </li>
                    //             </>
                    //           )}
                    //           {item?.name === "Basic" && (
                    //             <>
                    //               <li>
                    //                 <span className="failureIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Export exercise plan
                    //               </li>
                    //               <li>
                    //                 <span className="failureIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Add Patient
                    //               </li>
                    //               <li>
                    //                 <span className="failureIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Assign exercise plan to patient
                    //               </li>
                    //               <li>
                    //                 <span className="failureIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Add personal exercise plan or personal
                    //                 exercises
                    //               </li>
                    //               <li>
                    //                 <span className="failureIcon">
                    //                   <i className="fa fa-check"></i>
                    //                 </span>{" "}
                    //                 Create your own exercises and workout
                    //                 protocols
                    //               </li>
                    //             </>
                    //           )}
                    //         </ul>
                    //         <div className="w-100 text-center">
                    //           {item?.nickname !== "Basic" && (
                    //             <button
                    //               onClick={(e) => {
                    //                 e.preventDefault();
                    //                 // setSelectedPlanDetails(item);
                    //                 // handleSelectPlan(item)
                    //                 navigate("/paymentFormStripe", {
                    //                   state: { plan: item },
                    //                 });
                    //               }}
                    //               className="select-btn"
                    //             >
                    //               Select Plan
                    //             </button>
                    //           )}
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // }
                    //   </>
                    // );
                  })}

              
            </div>

            {/* <div className="slider"><label for="fader">Volume </label><input type="range" min="0" max="100" value="50" id="fader" step="20" list="volsettings" /><datalist id="volsettings"><option>0</option><option>20</option><option>40</option><option>60</option><option>80</option><option>100</option></datalist></div>  */}

            {/* <div className="container-fluid my-5">
              <div className="text-center">
                <h6
                  className="m-3"
                  style={{
                    color: "#FF4817",
                    "text-transform": "uppercase",
                    "font-size": "16px",
                  }}
                >
                  comparison{" "}
                </h6>
                <h2 className="m-3">Plan Details</h2>
              </div>
              <table className="price-table">
                <tbody>
                  <tr className="price-table-head">
                    <td style={{ paddingLeft: "2rem" }}>
                      <span>Features</span>
                    </td>
                    <td className="green-width">
                      <span className="mr-1">Basic</span>
                    </td>

                    {plansDetails?.items
                      ?.slice() // Create a copy of the array
                      .sort((a, b) => a.amount - b.amount) // Sort the array based on the amount
                      .map((item, index) => {
                        if (
                          item?.nickname.includes("Clinic") ||
                          item?.nickname === "Basic"
                        ) {
                          return null;
                        }

                        return (
                          <>
                          {((selectedPlan === "Monthly"  &&  item?.interval === "month") )  && 
                            <td className="green-width">
                              <span className="mr-1">{item?.nickname}</span>
                            </td>
                      }

{ ((selectedPlan === "Yearly"  &&  item?.interval === "year")  ||   item?.nickname === "Basic") &&

<td className="green-width">
                              <span className="mr-1">{item?.nickname}</span>
                            </td>
                    }
                          </>
                        );
                      })}
                  </tr>

                  <tr>
                    <td>{selectedPlan}</td>
                    <td>
                      <strong>Free</strong>
                    </td>

                    {plansDetails?.items
                      ?.slice() // Create a copy of the array
                      .sort((a, b) => a.amount - b.amount) // Sort the array based on the amount
                      .map((item, index) => {
                        if (
                          item?.nickname.includes("Clinic") ||
                          item?.nickname === "Basic" ||
                          item?.nickname.includes("Test_Rzp")
                        ) {
                          return null;
                        }

                        return (
                          <>
                                                    {((selectedPlan === "Monthly"  &&  item?.interval === "month") )  && 

                            <td>
                              <span className="mr-1">
                                ${item?.amount / 100}
                              </span>
                            </td>
                      }

{ ((selectedPlan === "Yearly"  &&  item?.interval === "year")  ||   item?.nickname === "Basic") &&


<td>
<span className="mr-1">
  ${item?.amount / 100}
</span>
</td>
                    }
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Patient </td>
                    <td>1</td>
                    {plansDetails?.items
                      ?.slice() // Create a copy of the array
                      .sort((a, b) => a.amount - b.amount) // Sort the array based on the amount
                      .map((item, index) => {
                        if (
                          item?.nickname.includes("Clinic") ||
                          item?.nickname === "Basic" ||
                          item?.nickname.includes("Test_Rzp")
                        ) {
                          return null;
                        }

                        return (
                          <>
                                                                              {((selectedPlan === "Monthly"  &&  item?.interval === "month") )  && 

                            <td>
                              {item?.metadata?.description
                                ? item?.metadata?.description.split(":")[1]
                                : "10"}
                            </td>
                      }

{ ((selectedPlan === "Yearly"  &&  item?.interval === "year")  ||   item?.nickname === "Basic") &&
  <td>
  {item?.metadata?.description
    ? item?.metadata?.description.split(":")[1]
    : "10"}
</td>

                    }

                          </>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Exercise</td>
                    <td>1600+</td>
                    <td>1600+</td>
                    <td>1600+</td>
                  </tr>
                  <tr>
                    <td> Export Exercise Plan</td>
                    <td>
                      <div className="iconTd">
                        <span className="failureIcon">
                          <i className="fa fa-check"></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Add Patient</td>
                    <td>
                      <div className="iconTd">
                        <span className="failureIcon">
                          <i className="fa fa-check"></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Assign exercise plan to patient</td>
                    <td>No</td>
                    <td>$1.99/patient</td>
                    <td>10 Free (after that $1.99/patient)</td>
                  </tr>
                  <tr>
                    <td>Add personal exercise plan or personal exercises</td>
                    <td>
                      <div className="iconTd">
                        <span className="failureIcon">
                          <i className="fa fa-check"></i>{" "}
                        </span>
                      </div>
                    </td>

                    <td className="">
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Patient Progress</td>
                    <td>
                      <div className="iconTd">
                        <span className="failureIcon">
                          <i className="fa fa-check"></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Community</td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Add Blog to Community</td>
                    <td>
                      <div className="iconTd">
                        <span className="failureIcon">
                          <i className="fa fa-check"></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Patient Can Book Appointment</td>
                    <td>
                      <div className="iconTd">
                        <span className="failureIcon">
                          <i className="fa fa-check"></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="iconTd">
                        <span className="successIcon">
                          <i className="fa fa-check"></i>
                        </span>{" "}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}

            <div className="container-fluid my-5">
              <div className="advanced-needs-section">
                <div className="advanced-needs-section-content ">
                  <h2>
                    Have more <br />
                    Advance Needs?
                  </h2>
                  <button
                    className="mt-3"
                    onclick="location.href='contact.html'"
                  >
                    Contact Us
                  </button>
                </div>
                <div className="image-container">
                  <img src={portrait1} alt="Doctor" />
                </div>
              </div>
            </div>

            <div className="container-fluid my-5">
              <div className="text-center">
                <h6
                  className="m-3"
                  style={{
                    color: "#FF4817",
                    "text-transform": "uppercase",
                    "font-size": "16px",
                  }}
                >
                  testimonials
                </h6>
                <h2 className="m-3">
                  Don’t Just <br />
                  Take It From Us
                </h2>
                {/* <span className="m-3">Choose a plan that’s right for you.</span> */}
              </div>
              <div className="row g-0">
                <div
                  className="col-12 col-md-8"
                  style={{
                    background: "white",
                    "box-shadow": "0px 15px 40px rgba(0, 0, 0, 0.10)",
                    "border-bottom-left-radius": "14px",
                    "border-top-left-radius": "14px",
                  }}
                >
                  <div className="p-4">
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                    >
                      <div className="carousel-indicators">
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "#1E1E1E" }}
                          >
                            Dr. Jhanvi
                          </h5>
                          <p
                            className="font-weight-normal"
                            style={{ color: "#1E1E1E" }}
                          >
                            Orthopedic
                          </p>
                          <blockquote className="blockquote">
                            <p className="mb-0" style={{ color: "#1E1E1E" }}>
                              “RecureMe has transformed how I manage my
                              patients' rehabilitation. The vast exercise video
                              library allows me to customize workouts
                              specifically tailored to each patient's needs. The
                              platform's user-friendly interface and robust
                              tracking features ensure my patients stay on the
                              right path to recovery. It's a must-have tool for
                              any physiotherapist.”
                            </p>
                          </blockquote>{" "}
                        </div>
                        <div className="carousel-item">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "#1E1E1E" }}
                          >
                            Dr. Gayatri
                          </h5>
                          <p
                            className="font-weight-normal"
                            style={{ color: "#1E1E1E" }}
                          >
                            Physiotherapist
                          </p>
                          <blockquote className="blockquote">
                            <p className="mb-0" style={{ color: "#1E1E1E" }}>
                              “Using RecureMe has streamlined my practice in
                              ways I never thought possible. From adding new
                              patients to assigning personalized workout plans,
                              everything is incredibly efficient. The ability to
                              print or email exercise plans and track patient
                              progress has made a significant difference in my
                              workflow and patient satisfaction. RecureMe is an
                              invaluable resource for healthcare professionals.”
                            </p>
                          </blockquote>{" "}
                        </div>{" "}
                        <div className="carousel-item">
                          <h5
                            className="font-weight-bold"
                            style={{ color: "#1E1E1E" }}
                          >
                            Dr. Hiral
                          </h5>
                          <p
                            className="font-weight-normal"
                            style={{ color: "#1E1E1E" }}
                          >
                            Physiotherapist
                          </p>
                          <blockquote className="blockquote">
                            <p className="mb-0" style={{ color: "#1E1E1E" }}>
                              “RecureMe has been a game-changer for my practice.
                              The extensive video library and easy-to-use
                              platform make it simple to create personalized
                              exercise plans for my patients. The ability to
                              track progress and communicate seamlessly has
                              significantly improved patient outcomes. I highly
                              recommend RecureMe to any physiotherapist or
                              doctor looking to enhance their patient care.”
                            </p>
                          </blockquote>{" "}
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <img
                    id="doctorPaymentImage"
                    src={doctorPayment}
                    className=""
                    height={400}
                    width={430}
                    alt="Responsive image"
                    style={{
                      borderBottomRightRadius: "14px",
                      borderTopRightRadius: "14px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="py-5" style={{ backgroundColor: "#FFEDE8" ,borderRadius:"14px"}}>
              <div className="text-center">
                <h6
                  className="m-3"
                  style={{
                    color: "#FF4817",
                    "text-transform": "uppercase",
                    "font-size": "16px",
                  }}
                >
                  FAQ
                </h6>
                <h2 className="m-3">Frequently asked questions</h2>
              </div>
              <div className="faq">
                <input id="faq-a" type="checkbox" />
                <label for="faq-a">
                  <p className="faq-heading">
                    What payment methods are accepted on RecureMe?
                  </p>
                  <div className="faq-arrow">
                    <i className="fa fa-plus"></i>
                  </div>
                  <p className="faq-text">
                    We accept various payment methods including credit cards,
                    debit cards, and popular online payment systems for your
                    convenience.
                  </p>
                </label>
                <input id="faq-b" type="checkbox" />
                <label for="faq-b">
                  <p className="faq-heading">
                    Is my payment information secure on your platform?
                  </p>
                  <div className="faq-arrow">
                    <i className="fa fa-plus"></i>
                  </div>
                  <p className="faq-text">
                    Yes, your payment information is secure. We use advanced
                    encryption technology to ensure your data is protected.
                  </p>
                </label>
                <input id="faq-c" type="checkbox" />
                <label for="faq-c">
                  <p className="faq-heading">
                    How can I update my billing information?
                  </p>
                  <div className="faq-arrow">
                    <i className="fa fa-plus"></i>
                  </div>
                  <p className="faq-text">
                    You can easily update your billing information through your
                    account settings. Simply log in, go to the billing section,
                    and make the necessary changes.
                  </p>
                </label>
                <input id="faq-d" type="checkbox" />
                <label for="faq-d">
                  <p className="faq-heading">
                    Are there any additional fees or hidden charges?
                  </p>
                  <div className="faq-arrow">
                    <i className="fa fa-plus"></i>
                  </div>
                  <p className="faq-text">
                    No, there are no additional fees or hidden charges. All
                    costs are clearly outlined during the payment process.
                  </p>
                </label>
                <input id="faq-e" type="checkbox" />
                <label for="faq-e">
                  <p className="faq-heading">
                    Can I change my payment method after subscribing?
                  </p>
                  <div className="faq-arrow">
                    <i className="fa fa-plus"></i>
                  </div>
                  <p className="faq-text">
                    Yes, you can change your payment method at any time through
                    your account settings. Just go to the payment section and
                    update your payment details.
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default PaymentStripe;
