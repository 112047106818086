import { Link } from "react-router-dom";
import NextIcon from "../../assets/images/left.svg";
import moment from "moment";
import { useState } from "react";
import dayjs from "dayjs";
function Workout({ patient, workoutHistory, user }) {
  const [date, setDate] = useState(dayjs());

  return (
    <>
      <div className="mb-1">
        <div className="innerInfo withoutOrange">
          <div className="doctorDetailsCard startdata" style={{boxShadow:"rgba(0, 0, 0, 0.06) 0px 1px 15px"}}>
            <div className="rightPartData">
              <div className="infoField filed d-flex justify-content-between">
                <div className="data">
                  <h5>Workout History</h5>
                </div>
             
              </div>

              <div className="filed">
                <div className="session-card-content" style={{ gap: "20px" }}>
                {workoutHistory?.length <= 0 && <div className="text-center">No Records Found</div>}

                  {workoutHistory?.length > 0 &&
                    workoutHistory.map((session, index) => {
                      const isLastSession = index === workoutHistory.length - 1;
                      const sessionCreatedAt = moment(session?.created_at); // Assuming 'created_at' is a date string
                      const selectedDate = moment(date); // Assuming 'date' is the selected date in appropriate format
                      let currentDate = new Date(date);
                      let totalExercises = session?.removedExercises?.length;
                      let completedExercises = 0;

                      // Calculate completion ratio
                      const completionRatio =
                        totalExercises > 0
                          ? completedExercises / totalExercises
                          : 0;

                      // Determine background color based on completion ratio
                      let backgroundColor;
                      if (completionRatio === 1) {
                        backgroundColor = "#90ee906e"; // Light green for fully completed
                      } else if (completionRatio > 0) {
                        backgroundColor = "#FFFFE0"; // Light yellow for partially completed
                      } else {
                        backgroundColor = "transparent"; // Default background for not completed
                      }
                      if (
                        moment(date?.$d).isSame(sessionCreatedAt, "day") ||
                        moment(date?.$d).isAfter(sessionCreatedAt)
                      ) {
                        return (
                          <>
                            <div
                              className={`exercise-card pb-2`}
                              key={index}
                              style={{
                                cursor: "pointer",
                                maxHeight: "15vh",
                                backgroundColor: backgroundColor,
                                boxShadow: "none",
                                borderBottom: isLastSession
                                  ? "none"
                                  : "1px solid #EAEAEA", // Conditionally apply borderBottom
                                borderRadius: "1px",
                                padding: "0px",
                              }}
                              onClick={() => {
                                // handleWorkoutClick(session)
                              }}
                            >
                              <div className="img-text-part">
                                <div className="image-block">
                                  <img
                                    src={
                                      "https://api.recureme.com/images/Elbow.svg"
                                    }
                                    height={70}
                                    width={70}
                                    style={{
                                      borderRadius: "55%",
                                    }}
                                    alt="session-img"
                                  />
                                </div>
                                <div className="text-part ps-3">
                                  <h6>
                                    {session?.name?.replace(
                                      "Workout Session",
                                      ""
                                    )}{" "}
                                    - {session?.session}
                                  </h6>
                                  <p>{`${totalExercises} exercises`}</p>
                                </div>
                              </div>
                              <div className="details">
                                <div
                                  className="text-part ps-3"
                                  style={{ textAlign: "end" }}
                                >
                                  <h6
                                    className="session-title"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {moment(session?.startedWorkoutDate).format(
                                      "DD MMM YYYY"
                                    )}
                                    &nbsp; to &nbsp;
                                    {moment(session?.dateRemoved).format(
                                      "DD MMM YYYY"
                                    )}
                                  </h6>
                                  <p>
                                    Assigned by :{" "}
                                    {user?._id === session?.assigned_by?._id
                                      ? "You"
                                      : session?.assigned_by?.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Workout;
