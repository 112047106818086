import TopBarNavigation from "../../components/TopBarNavigation";
import Layout from "../../layout";
import UploadIcon from '../../assets/images/upload.svg'
import { Button, Col, Row } from 'react-bootstrap';
import { useRef, useState } from "react";
import { getAuthConfigImage, post } from "../../libs/http-hydrate";
import FieldValidationError from "../../common/FieldValidation";
import '../../assets/css/home.css'
import Modal from 'react-bootstrap/Modal';
import doneImg from "../../assets/images/doneImg.png";
import { useNavigate } from "react-router-dom";
import RichTextEditor from 'react-rte';
function Community() {
    const [completeWorkout, setCompleteWorkout] = useState(false);
    const [blogdata,setBlogData] = useState({
        title:"",
        description:"",
        community_image:null
    })
    const navigate = useNavigate()
    const [blogdataerr,setBlogDataerr] = useState({
        title:false,
        description:false,
        community_image:false
    })
    const [loading, setLoading] = useState(false)
    const title = useRef(null);
    const description = useRef(null);
    const community_image = useRef(null);
    const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
    const onChange = (value) => {
        setEditorValue(value);
  // Convert the editor's value to HTML
  const htmlContent = value.toString('html');

  // Update your blog data with the HTML content
  setBlogData((p) => ({ ...p, description: htmlContent }));
      setBlogDataerr((p) =>({...p,description:false}))
    };

    const handleFieldError = (fieldRef) => {
        const inputField = fieldRef?.current?.querySelector('input')
        if (inputField) {
    
          // inputField.classList.add('field-error'); // Add the error class to the input field
          inputField.focus(); // Focus on the input field
          fieldRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          const selectField = fieldRef?.current?.querySelector('select');    
          if (selectField) {
            selectField.classList.add('field-error'); // Add the error class to the select field
            fieldRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }
      };
    async function generateCoomunity(){
        try {

            if (blogdata?.title?.trim() === '') {
                setBlogDataerr((p) => ({ ...p, title: true }))
                handleFieldError(title)
                return false;
              }
              if (blogdata?.description?.trim () === "") {
                setBlogDataerr((p) => ({ ...p, description: true }))
                handleFieldError(description)
                return false;
              }
            //   if (!blogdata?.community_image) {
            //     setBlogDataerr((p) => ({ ...p, community_image: true }))
            //     handleFieldError(community_image)
            //     return false;
            //   }

              
            setLoading(true);
            const formdata = new FormData();
            formdata.append("title",blogdata?.title);
            formdata.append("description",blogdata?.description);
            formdata.append("community_image",blogdata?.community_image)
            const response = await post("/community",formdata,getAuthConfigImage()).then((data) => {
              if (data?.status === 200) {
                setLoading(false)
                setCompleteWorkout(true)
              }
            }).catch((err) => {
              setLoading(false)
            })
          } catch (error) {
            setLoading(false)
          }
    }
    return (
        <>
            <Layout>

                <TopBarNavigation backlink={"/community"} title={"Add Blog"} tab={"Community"} tabChild={"Add Blog"} />
                <div className='main-content-part'>
                    <div className='form-data'>
                        <h5 className='title'>Blog Details</h5>
                        <div className='filed' ref={title}>
                            <label>Title<span className='text-danger'>*</span></label>
                            <input type='text' className={`input-field ${blogdataerr?.title ? 'field-error' : ''}`} value={blogdata?.title} onChange={(e) =>{
                                e.preventDefault();
                                setBlogData((p) =>({...p,title:e.target.value}))
                                setBlogDataerr((p) =>({...p,title:false}))

                            }} placeholder='Healthy LifeStyle' />
                            {blogdataerr?.title && <FieldValidationError message="Please enter title" />}
                        </div>
                        <div className='filed' ref={description}>
                            <label>Description<span className='text-danger'>*</span></label>
                            <RichTextEditor
       value={editorValue}
       onChange={onChange}
    //    style={{ height: '600px' }} // Set the height here

      />
                            {/* <input type='text' className={`input-field  ${blogdataerr?.description ? 'field-error' : ''}`} value={blogdata?.description} onChange={(e) =>{
                                e.preventDefault();
                                setBlogData((p) =>({...p,description:e.target.value}))
                                setBlogDataerr((p) =>({...p,description:false}))
                            }} placeholder='Steps to follow Healthy LifeStyle' /> */}
                            {blogdataerr?.description && <FieldValidationError message="Please enter Description" />}
                        </div>
                            <div className='filed' ref={community_image}>
                                <label>Add Community Image</label>
                                <div className='upload-part mt-2'>
                                    <div className="form-group file-area">
                                        <input type="file" name="images" id="images" required="required"  accept="image/png,image/jpeg,image/jpg"
                                    
                                    onChange={(e) => {
                                        const files = e?.target?.files;
                                        let isValid = true;
                                    
                                        for (let i = 0; i < files.length; i++) {
                                        const fileType = files[i].type;
                                        const fileSize = files[i].size;
                                    
                                        if (
                                            fileType !== "image/png" &&
                                            fileType !== "image/jpg" &&
                                            fileType !== "image/jpeg" ) {
                                            alert("Only images are allowed");
                                            isValid = false;
                                            break;
                                        }
                                    
                                        if (fileSize > 2097152) { // 2 MB in bytes
                                            alert("File size should not be more than 2 MB");
                                            isValid = false;
                                            break;
                                        }
                                        }
                                    
                                        if (isValid) {
                                            setBlogData((p) => ({ ...p, community_image: e.target.files[0] }));
                                            setBlogDataerr((p) => ({ ...p, community_image: false}));

                                        } else {
                                        // Optionally reset the input if invalid file is selected
                                        e.target.value = null;
                                        }
                                    }}
                                    
                                    // onChange={(e) => {
                                    //         if (
                                    //             !(e?.target.files && (
                                    //                 e?.target.files[0].type === "image/png" ||
                                    //                 e?.target.files[0].type === "image/jpg" ||
                                    //                 e?.target.files[0].type === "image/jpeg" ||
                                    //                 e.target.files[0].type === "image/gif" )
                                    //             )
                                    //         ) {
                                    //             alert("Only images,pdf,doc,docx allowded");
                                    //             return false;
                                    //         }
                                    //     else  if (e.target.files[0].size > 2097152) {
                                    //             alert("Not More than 2 MB is allowded");
                                    //             return false;
                                    //         }
                                    //         else{
                                    //             setBlogData((p) => ({ ...p, community_image: e.target.files[0] }));
                                    //             setBlogDataerr((p) => ({ ...p, community_image: false}));
        
                                    //         }
                    
                                    //     }} 
                                        />
                                        <div className="file-dummy">
                        <div className="success">
                        Great, your files are selected. Keep on.
                        </div>
                        <div className="default">
                        <div className="image-block mb-2">
                            <img src={UploadIcon} alt="icon" />
                        </div>
                        Add image here (png, jpg, jpeg)
                        </div>
                    </div>
                                    </div>
                                    {blogdataerr?.community_image && <FieldValidationError message="Please add image" />}

                                </div>
                            </div>
                    </div>
                    <div className='buttonPart mt-5 d-flex justify-content-center'>

                        <Button className='theme-button' 
                        disabled={loading}
                        onClick={(e) => {
                            e.preventDefault();
                             generateCoomunity()
                        }}>{loading ? "Saving Community" : "Add Community"}</Button>


                    </div>
                </div>


                <Modal
                    show={completeWorkout}
                    onHide={() => setCompleteWorkout(false)}
                    size="md"
                    className="complete-modal"
                >
                    <Modal.Body>
                        <div className="top-heading text-end">
                            <button
                                className="btn btn-transpatent"
                                onClick={() => setCompleteWorkout(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="content-block text-center">
                            <img src={doneImg} height={300} width={350} alt="modal-video" />
                        </div>
                        <div className="bottom-block text-center">
                            <div className="workout-desc body-content" style={{
                                "display": "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                "flex-direction": "column",
                                "padding-top": "25px"
                            }}>
                                <p>Blog Added successfully</p>

                                <button className="theme-button btn btn-primary" style={{ minWidth: "300px", padding: "14px" }} onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/community")
                                }}>Done</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Layout>

        </>
    )
}
export default Community;
