import React from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";
import axios from "axios";
export default function Places({ setFormData,  setSelected, selected, isLoaded , data }) {
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete setFormData={setFormData} datas={data}  setSelected={setSelected} />
      </div>
    </>
  );
}

const PlacesAutocomplete = ({ setFormData, setSelected , datas }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    getAddressFromCoordinates(lat ,lng ,address)
    setSelected({ lat, lng, address });
  };

  const getAddressFromCoordinates = async (latitude, longitude,address) => {
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;

    try {
      const response = await axios.get(nominatimUrl, {
        headers: {
          "User-Agent": "YourAppName"
        }
      });

      const addressData = response.data;
      if (addressData && addressData.display_name) {
        const { city, state_district,state, country,postcode} = addressData.address;
        const displayParts = addressData.display_name.split(',');
        // Filter out the parts that contain state, city, and postcode information
        const filteredParts = address.split(',').filter(part => {
          return ![state, city, postcode, state_district, country].includes(part.trim());
        });
      
        const street = filteredParts.join(',').trim();
        setFormData((p) => ({
          ...p,
          city: city || state_district ||  "",
          state: state || "",
          country: country || "",
          pincode:postcode || "",
          street: street
        }));
        setSelected({lat:latitude,lng:longitude,address:addressData?.display_name})
      } else {
        // setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  console.log(value,"valueeeeeee")
  return (
    <Combobox onSelect={handleSelect} >
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="combobox-input form-control"
        placeholder={datas?.street ? datas?.street :"Search an address"}
      />
      <ComboboxPopover style={{zIndex:"9999"}} portal={false}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => <ComboboxOption key={place_id} value={description} />)}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
