import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from '../Hook/UseEffectOnce';
const Credit = ({setCredit , displayDiv , setData}) => {
  const [credits, setCredits] = useState(null);
  const navigate = useNavigate();

  UseEffectOnce(() => {
    const getHomeData = async () => {
      try {
        const data = await get("/physiotherapist", getAuthConfig());
        if (data?.status === 200) {
          setCredits(data?.data?.doc?.credits);
          setCredit(data?.data?.doc?.credits)
          setData(data?.data?.doc)
        } else {
          // Handle non-200 status, if necessary
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          // Redirect to login on unauthorized error
          navigate("/login");
        }
        // Handle other errors as necessary
      }
    };

    getHomeData();
  }, [navigate]); // The dependency array ensures useEffect runs only once on component mount

  return (
    <div style={{display:displayDiv ? "none" : "block"}}>
      {credits !== null ? (
        <span style={{fontSize:"14px"}}>Available Credits: {credits}</span>
      ) : (
        <p>Loading credits...</p>
      )}
      {/* Render other components or data as needed */}
    </div>
  );
};

export default Credit;
