import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import DoctorCard from '../../components/DoctorCard'
import { Row, Col } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { get, getAuthConfig } from '../../libs/http-hydrate'
import Auth from '../../libs/auth'
import { UseEffectOnce } from '../../Hook/UseEffectOnce'

const DoctorList = () => {
  const navigate = useNavigate();
  const [physiotherapistList, setPhysiotherapistList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0);

  UseEffectOnce(() => {
    getPhysiotherapistLit();
  }, []);
  useEffect(() =>{
    if(page >= 1) {
      getPhysiotherapistLit()
    }

  },[page])

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && physiotherapistList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [physiotherapistList?.has_more]
  );

  async function getPhysiotherapistLit() {

    try {
      const data = await get(`/physiotherapistlist?pageNumber=${page}`, getAuthConfig());
      if (data?.status === 200) {
        if(page > 0 ){
          setPhysiotherapistList((p) => ({ ...p, items: physiotherapistList.items.concat(data?.data?.data) }));
        }else{
          setPhysiotherapistList((p) => ({ ...p, items: data?.data?.data }));
        }
        setPhysiotherapistList((p) => ({ ...p, has_more: data?.data?.has_more }))

      }


    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.logout();
        navigate("/login")

      }

    }
  }

  return (
    <Layout>
 <TopBar backlink={"/dashboard"} title={"Physiotherapist"}   tab={"All Physiotherapist"} tabChild={"Physiotherapist"} />
      <div className='main-content-part'>
        <Row className='g-4'>


          {physiotherapistList?.items && physiotherapistList?.items?.length >= 0 &&
            physiotherapistList?.items?.map((item, index) => {
              return (
                <>
                  <Col lg="6" ref={lastBookElementRef}>
                    <Link
                      to='/doctorDetails' state={{id:item?._id,doctor:item}} className='linkCard'>
                      <DoctorCard doctor={item} />
                    </Link>
                  </Col>
                </>
              )

            })}

        </Row>


      </div>
    </Layout>
  )
}

export default DoctorList
