import React, { useCallback, useEffect, useRef, useState } from "react";
import "../assets/css/header.css";
import { BsSearch } from "react-icons/bs";
import notificationIcon from "../assets/images/notification.svg";
import Avatar from "react-avatar";
import profileImg from "../assets/images/Ellipse 179.svg";
import { Dropdown, Modal, ProgressBar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Auth from "../libs/auth";
import { get, getAuthConfig } from "../libs/http-hydrate";
import DoctorDummy from "../assets/images/defaultdoctor@3x.png";
import Patient from "../assets/images/defaultpatient@3x.png";
import TrustIcon from "../assets/images/TRUST ICON.png";
import moment from "moment";
import FillStar from "../assets/images/star.svg";
import { AiOutlineArrowLeft } from "react-icons/ai"; // Importing back arrow icon from react-icons
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../assets/images/RecureMe.svg";
import logout from "../assets/images/signOut.svg";
import home from "../assets/images/home.svg";
import patient from "../assets/images/patients.svg";
import point from "../assets/images/point.svg";
import homeActive from "../assets/images/homeActive.svg";
import pointActive from "../assets/images/pointActive.svg";
import heartSvg from "../assets/images/heartBlack.svg";
import heartFilled from "../assets/images/heartFilled.svg";
import patientActive from "../assets/images/patientActive.svg";
import folderSvg from "../assets/images/folder-download.svg";
import folderSvgBlack from "../assets/images/folderBlack.svg";
import MyExercise from "../assets/images/myExercise.svg";
import MyExerciseFill from "../assets/images/myExerciseFill.svg";
import CommunitySidebar from "../assets/images/communitySidebad.svg";
import CommunityFillSidebar from "../assets/images/communityFillSidebar.svg";
import ProfileCompletionModal from "../pages/AddProfileData/ProfileCompletionModal";
import PatientDummy from "../assets/images/defaultpatient@3x.png";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DoctorCommon from "../assets/images/Docotr.png";
import DoctorMale from "../assets/images/Doctor Male.png";
import DoctorFeMale from "../assets/images/Doctor Female.png";
import PatientCommon from "../assets/images/Patint.png";
import PatientFemale from "../assets/images/Patient Female.png";
import PatientMale from "../assets/images/Patient male.png";
import InternetConnectivity from "./InternetConnectivity";
import payment from "../assets/images/payment.svg";
import paymentActive from "../assets/images/paymentActive.svg";
import DaysTrailModal from "./DaysTrailModal";
import Over7DaysTrialModal from "./Over7DaysTrialModal";

const Header = () => {
  const [showOver7DaysTrialModal, setShowOver7DaysTrialModal] = useState(false);
  const location = useLocation();
  const activePage = location.pathname.replace("/", "");
  const navigate = useNavigate();
  const pointsActivePaths = [
    "points",
    "exerciseLab",
    "surgeryTemplate",
    "createExercise",
  ];

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("notificationData");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });
  const shouldShowSidebar =
    location.pathname == "/payment" ||
    location.pathname == "/paymentForm" ||
    location.pathname == "/myPlanDetails" ||
    location.pathname == "/creditPurchase" ||
    location.pathname == "/paymentStripe" ||
    location.pathname == "/paymentFormStripe";
  const handleBack = () => {
    // Implement the back functionality here
    // For example, using history from react-router
    // navigate(props?.backlink)
    navigate(-1);
  };

  const shouldShowOver7DaysTrialModal = () => {
    const excludedPaths = [
      "/payment",
      "/paymentForm",
      "/myPlanDetails",
      "/creditPurchase",
      "/paymentStripe",
      "/paymentFormStripe"
    ];
    return !excludedPaths.includes(location.pathname) && showOver7DaysTrialModal;
  };
  
  const dropdownRef = useRef(null);
  const [show, setShow] = useState(false);
  const [wlcmModal, setWlcmModal] = useState(false);
  const handleClose = () => {
    getHomeData();
    setShow(false);
  };

  const handleShow = () => setShow(true);
  const [headerData, setHeaderData] = useState(() => {
    // Fetch data from localStorage
    const storedData = localStorage.getItem("notificationData");

    // Parse JSON data if available, otherwise return an empty object
    return storedData ? JSON.parse(storedData) : {};
  });
  const [page, setPage] = useState(0);
  const [notification, setNotification] = useState({
    items: [],
    has_more: false,
  });
  const [unreadCount, setunreadCount] = useState();
  const [doctorList, setDoctorList] = useState({
    items: [],
    has_more: false,
  });
  const [docpage, setDocPage] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const user = Auth.getCurrentUser();
  const [showInternetModal, setShowInternetModal] = useState(false);

  UseEffectOnce(() => {
    getHomeData();

    if (!Auth.getCurrentUser()) {
      navigate("/login");
    }
  });
  useEffect(() => {
    if (page > 0) {
      getNotification();
    }
  }, [page]);
  useEffect(() => {
    getPatientList();
  }, [docpage, searchTerm]);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && notification?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [notification?.has_more]
  );
  function formatDateTime(timestamp) {
    return moment(timestamp).format("h:mm A z , MMM D");
  }
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  async function getHomeData() {
    if (!navigator.onLine) {
      setShowInternetModal(true);
      return;
    }

    localStorage.removeItem("notificationData");

    try {
      const data = await get("/physiotherapist", getAuthConfig());
      if (data?.status === 200) {
        //setHeaderData(data?.data?.doc);

        const docData = data?.data?.doc;
        const createdAtToday = moment(docData?.created_at).isSame(moment(), 'day');
  

              // Check if the account is 7 days old
      const accountCreationDate = moment(docData?.created_at);
      const todayDate = moment();
      const daysSinceCreation = todayDate.diff(accountCreationDate, 'days');


          if (!headerData?.name) {
          // If headerData is empty, set it to the new data
          setHeaderData(data?.data?.doc);
        } else if (!compareObjects(data?.data?.doc, headerData)) {
          // If headerData is not empty and the new data is different, update headerData
          setHeaderData(data?.data?.doc);
        }

        // if(!data?.data?.doc?.years_of_experience)
        // {
        //   Auth.logout()
        //   navigate("/login")
        // }

        
        if (data?.data?.doc) {
          localStorage.setItem(
            "notificationData",
            JSON.stringify(data.data.doc)
          );
        }
        if (data?.data?.unread_notification_count != null) {
          setunreadCount(data?.data?.unread_notification_count);
        }

                  // Check conditions for showing the DaysTrailModal
                  const hasShownDaysTrial = localStorage.getItem("hasShownDaysTrial");
                  if (docData?.profileCompleted === false && createdAtToday && !hasShownDaysTrial) {
                      setWlcmModal(true);
                      localStorage.setItem("hasShownDaysTrial", "true");
                  }
      
            // Show Over7DaysTrialModal if account is 7 days old and subscriptionId is not present
            console.log(daysSinceCreation,"daysSinceCreationdaysSinceCreation",docData?.subscriptionId)
            if (daysSinceCreation === 7 && !docData?.subscriptionId) {
              setShowOver7DaysTrialModal(true);
            }
      
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }
  // Function to compare two objects
  const compareObjects = (obj1, obj2) => {
    // Compare objects based on specific properties
    console.log(
      "obj1:",
      obj1.email,
      obj1.gender,
      obj1.mobile,
      obj1.name,
      obj1.profileCompleted,
      obj1.profile_image,
      obj1.token,
      obj1._id,
      obj1.clinicVerified
    );
    console.log(
      "obj2:",
      obj2.email,
      obj2.gender,
      obj2.mobile,
      obj2.name,
      obj2.profileCompleted,
      obj2.profile_image,
      obj2.token,
      obj2._id,
      obj2.clinicVerified
    );
    return (
      obj1.email === obj2.email &&
      obj1.gender === obj2.gender &&
      obj1.mobile === obj2.mobile &&
      obj1.name === obj2.name &&
      obj1.profileCompleted === obj2.profileCompleted &&
      obj1.profile_image === obj2.profile_image &&
      obj1.token === obj2.token &&
      obj1._id === obj2._id &&
      obj1.clinicVerified === obj2.clinicVerified
    );
  };

  async function getNotification() {
    try {
      const data = await get(
        `/notification?pageNumber=${page}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        let sortedItems = data?.data?.msg;

        // Sort the notifications by 'created_at' timestamp in descending order
        sortedItems.sort((a, b) => {
          const timestampA = new Date(a.created_at).getTime();
          const timestampB = new Date(b.created_at).getTime();
          return timestampB - timestampA;
        });

        if (page > 0) {
          setNotification((prevNotification) => ({
            ...prevNotification,
            items: prevNotification?.items.concat(sortedItems),
          }));
        } else {
          setNotification((prevNotification) => ({
            ...prevNotification,
            items: sortedItems,
          }));
        }
        // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
        setNotification((e) => ({ ...e, has_more: data?.data?.has_more }));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }

  async function getPatientList() {
    try {
      const data = await get(
        `/patientList?pageNumber=${docpage}&searchTerm=${searchTerm}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        if (docpage > 0) {
          setDoctorList((p) => ({
            ...p,
            items: doctorList?.items.concat(data?.data?.data),
          }));
        } else {
          setDoctorList((p) => ({ ...p, items: data?.data?.data }));
        }
        // setDoctorList((e) => ({ ...e, items: data?.data?.data }))
        setDoctorList((e) => ({ ...e, has_more: data?.data?.has_more }));
      }
    } catch (error) {}
  }
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);

  const handleAddPatientClick = () => {
    // if (user?.profileCompleted === false) {
    //   setShowProfileCompletionModal(true);
    // } else {
      navigate("/personalInformation");
    // }
  };

  return (
    <>
      {/* <div className="header-main"> */}
      {shouldShowSidebar && (
        <button className="back btn " onClick={handleBack}>
          <AiOutlineArrowLeft />
        </button>
      )}

      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="white"
        className="w-100 header-main"
      >
        <Navbar.Brand onClick={() => navigate("/")} className="cursor-pointer">
          <div className="w-100 text-center mobile-logo">
            <img src={logo} alt="docpy-logo" width={180} height={50} />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav "
          style={{ backgroundColor: "white" }}
        >
          <Nav className="mr-auto w-100">
            <div className="sidebar-main" style={{ height: "fit-content" }}>
              <div className="nav-part">
                <button
                  className="btn btn-add mb-3"
                  onClick={handleAddPatientClick}
                >
                  + Add Patient
                </button>

                <div className="top-navpart">
                  <Link
                    to="/dashboard"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={` ${
                        activePage === "dashboard" ||
                        activePage === "doctorList"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "dashboard" ||
                          activePage === "doctorList"
                            ? homeActive
                            : home
                        }
                        alt=""
                        className="icon"
                      />
                      <p className="text">Home</p>
                    </div>
                  </Link>
                  <Link
                    to="/allPatient"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "allPatient" ||
                        activePage === "patientDetails" ||
                        activePage === "history"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "allPatient" ||
                          activePage === "patientDetails" ||
                          activePage === "history"
                            ? patientActive
                            : patient
                        }
                        alt=""
                        className="icon"
                      />
                      <p className="text">Patient</p>
                    </div>
                  </Link>
                  <Link
                    to="/points"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        pointsActivePaths.includes(activePage)
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          pointsActivePaths.includes(activePage)
                            ? pointActive
                            : point
                        }
                        alt=""
                        className="icon"
                      />
                      <p className="text">Library</p>
                    </div>
                  </Link>
                  <Link
                    to="/selectedExercise"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "selectedExercise"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "selectedExercise"
                            ? heartFilled
                            : heartSvg
                        }
                        alt=""
                        className={`icon`}
                      />
                      <p className="text">Saved Exercise</p>
                    </div>
                  </Link>

                  <Link
                    to="/savedExercise"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "savedExercise"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "savedExercise"
                            ? folderSvg
                            : folderSvgBlack
                        }
                        alt=""
                        className={`icon ${
                          activePage === "savedExercise" ? "heart" : ""
                        }`}
                      />
                      <p className="text">Saved Protocol</p>
                    </div>
                  </Link>
                  <Link
                    to="/myExercise"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "myExercise" ||
                        activePage === "exerciseAdd"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "myExercise" ||
                          activePage === "exerciseAdd"
                            ? MyExercise
                            : MyExerciseFill
                        }
                        alt=""
                        className={`icon ${
                          activePage === "myExercise" ? "heart" : ""
                        }`}
                      />
                      <p className="text">My Exercise</p>
                    </div>
                  </Link>
                  <Link
                    to="/community"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className={`${
                        activePage === "community" || activePage === "AddBlog"
                          ? "nav-btn active"
                          : "nav-btn"
                      }`}
                    >
                      <img
                        src={
                          activePage === "community" || activePage === "AddBlog"
                            ? CommunityFillSidebar
                            : CommunitySidebar
                        }
                        alt=""
                        className={`icon ${
                          activePage === "community" ? "heart" : ""
                        }`}
                      />
                      <p className="text">Community</p>
                    </div>
                  </Link>

                  {data?.address?.country === "India" && 
              <Link
                to="/payment"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  className={`${
                    activePage === "payment" ? "nav-btn active" : "nav-btn"
                  }`}
                >
                  <img
                    src={activePage === "payment" ? paymentActive : payment}
                    alt=""
                    className={`icon ${
                      activePage === "payment" ? "heart" : ""
                    }`}
                  />
                  <p className="text">Payment</p>
                </div>
              </Link>}

              {data?.address?.country !== "India" && 
              <Link
                to="/paymentStripe"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div
                  className={`${
                    activePage === "paymentStripe" ? "nav-btn active" : "nav-btn"
                  }`}
                >
                  <img
                    src={activePage === "paymentStripe" ? paymentActive : payment}
                    alt=""
                    className={`icon ${
                      activePage === "payment" ? "heart" : ""
                    }`}
                  />
                  <p className="text">Payment</p>
                </div>
              </Link>}
                  {/* <Link
            to='/payment'
            style={{ textDecoration: "none", color: "inherit" }}>
            <div className={`${activePage === 'payment' ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'payment' ? paymentActive :  payment }  alt="" className={`icon ${activePage === 'payment' ? "heart" : ""}`}/>
              <p className='text'>Payment</p>
            </div>
          </Link> */}
                  <div
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={() => handleLogout()}
                  >
                    <div className={`nav-btn`}>
                      <img src={logout} alt="" className={`icon`} />
                      <p className="text">Logout</p>
                    </div>
                  </div>
                </div>
                {/* <div className="bottom-logoutpart">
          <button className='logout-btn' onClick={() => handleLogout()}>
            <img src={logout} alt="logout-icon" className='icon' />
            <p className='text'>Logout</p>
          </button>
        </div> */}
              </div>
            </div>

            {/* Place your navigation links here */}
            <div className="search-part w-50">
              <input
                type="text"
                placeholder="search patients"
                value={searchTerm}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchTerm(e.target.value);
                  setDropdownOpen(true);
                }}
              />
              <BsSearch size={16} className="icon-search" />
            </div>
            {isDropdownOpen && (
              <div className="dropdowns" style={{ width: "25%" }}>
                <div
                  className="dropdowns-list"
                  style={{
                    maxHeight: "55vh",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <div className="doctors-list" ref={dropdownRef}>
                    {/* Your dropdown content goes here */}
                    <div
                      className="title-block"
                      style={{ display: "inline-block", width: "100%" }}
                    >
                      <Row className="g-2 my-2 m-0">
                        {doctorList?.items &&
                          doctorList?.items.length > 0 &&
                          doctorList?.items?.map((item, index) => {
                            const istTimestamp = moment(item?.created_at)
                              .utcOffset("+05:30")
                              .format("YYYY-MM-DD HH:mm:ss");

                            return (
                              <Col lg="12">
                                <Link
                                  to="/patientDetails"
                                  state={{ patient: item }}
                                  className="linkCard"
                                >
                                  <div className="doctor-card upcomingPatientsCard">
                                    <div className="doctor-card-content p-4">
                                      <div
                                        className="image-part d-flex align-items-center"
                                        style={{
                                          width: "max-content",
                                          height: "max-content",
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.profile_image
                                              ? item?.profile_image
                                              : item?.gender === "female"
                                              ? PatientFemale
                                              : item?.gender === "male"
                                              ? PatientMale
                                              : PatientCommon
                                          }
                                          height={50}
                                          width={50}
                                          alt="Patient"
                                          className="rounded-circle"
                                        />
                                      </div>
                                      <div className="text-part w-100 ">
                                        <h5>{item?.name}</h5>
                                        <p className="gray">
                                          {item?.appoinmentFor}
                                        </p>
                                        <p>
                                          {moment(istTimestamp).format(
                                            "h:mm A"
                                          )}{" "}
                                          <span style={{ color: "#ADADAD" }}>
                                            {" "}
                                            {moment(item?.created_at).format(
                                              "MMM DD"
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </Link>
                              </Col>
                            );
                          })}

                        {doctorList?.has_more === true && (
                          <div
                            className="text-center p-3"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setDocPage(
                                (prevPageNumber) => prevPageNumber + 1
                              );
                            }}
                          >
                            Load More
                          </div>
                        )}
                        {doctorList?.items && doctorList?.items.length <= 0 && (
                          <div className="text-center p-5">
                            No Patient Found
                          </div>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <LinkContainer to="/dashboard">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer> */}
            {/* ... other navigation links */}
          </Nav>
          <Nav className="w-100">
            {/* Right-aligned items (e.g., profile, notifications) */}
            {/* <LinkContainer to="/profile">
                <NavDropdown.Item>Profile</NavDropdown.Item>
              </LinkContainer> */}

            <div className="profile-part">
              {user?.profileCompleted === false && (
                <div
                  className="rounded  text-start py-2"
                  style={{
                    width: "40%",
                    cursor: "pointer",
                    backgroundColor: "#8080800d",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/profileData");
                  }}
                >
                  <div className="d-flex">
                    <div>
                      {" "}
                      <img src={TrustIcon} height={50} />{" "}
                    </div>
                    <div>
                      <p
                        className="mb-0 mt-2"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        Optimize your presence to
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "#ff6036",
                        }}
                      >
                        attract More patients !
                      </p>
                    </div>
                  </div>
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="progressBar pt-1" style={{ flex: 1 }}>
                      <ProgressBar now={30} />
                    </div>
                    <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                      30%
                    </span>
                  </div> */}
                </div>
              )}
              <div
                className="notification-block"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  getNotification();
                  handleShow();
                }}
              >
                <img
                  src={notificationIcon}
                  alt="notification-icon"
                  width="20px"
                />
                <div className="noti-no">
                  {Number(unreadCount) > 99 ? "99+" : unreadCount}
                </div>
              </div>

              <div className="profile">
                {user?.profile_image ? (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={user?.profile_image}
                      style={{ borderRadius: "55%" }}
                      height={"40px"}
                      width={"40px"}
                      alt="User Profile"
                    />
                    {headerData?.clinicVerified === true && ( // Check if the user is verified
                      <div
                        style={{
                          position: "absolute",
                          top: "-2px",
                          right: "-5px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <circle cx="10" cy="10" r="10" fill="green" />
                          <path
                            d="M7 10 L9 12 L14 7"
                            stroke="white"
                            stroke-width="2"
                            fill="transparent"
                          />
                        </svg>

                        {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={
                        user?.gender === "female"
                          ? DoctorFeMale
                          : user?.gender === "male"
                          ? DoctorMale
                          : DoctorCommon
                      }
                      style={{ borderRadius: "55%" }}
                      height={"40px"}
                      width={"40px"}
                      alt="User Profile"
                    />
                    {headerData?.clinicVerified === true && ( // Check if the user is verified
                      <div
                        style={{
                          position: "absolute",
                          top: "-2px",
                          right: "-5px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <circle cx="10" cy="10" r="10" fill="green" />
                          <path
                            d="M7 10 L9 12 L14 7"
                            stroke="white"
                            stroke-width="2"
                            fill="transparent"
                          />
                        </svg>

                        {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                      </div>
                    )}
                  </div>
                )}
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-profile" className="btn-custom">
                    {user?.name}{" "}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        navigate("/doctorDetailsForm");
                      }}
                    >
                      Account
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        navigate("/allPatient");
                      }}
                    >
                      Patients{" "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        Auth.logout();
                        navigate("/login");
                      }}
                    >
                      Logout{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* ... other dropdown items */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ProfileCompletionModal
        show={showProfileCompletionModal}
        onHide={() => setShowProfileCompletionModal(false)}
        From={"Add Patient"}
      />
      {/* </div> */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header
          closeButton
          style={{
            justifyContent: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Modal.Title className="text-left">Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content" ref={lastBookElementRef}>
            {notification?.items && notification?.items.length <= 0 && (
              <div className="text-center"> No Records Found </div>
            )}

            {notification?.items &&
              notification?.items.length > 0 &&
              notification?.items.map((item, index) => {
                return (
                  <>
                    <div
                      className="card roundedBorder shadow-lg p-3 mt-3"
                      style={{
                        border: "none",
                        borderRadius: "14px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (item?.type === "Workout" && item?.mentionedUser) {
                          navigate("/history", {
                            state: { patient_id: { _id: item?.mentionedUser } },
                          });
                        }
                      }}
                    >
                      <p className="">
                        <strong>{item?.title}</strong>
                      </p>
                      <p className="light-text mt-0">{item?.body}</p>
                      <p>
                        {formatDateTime(item?.created_at)}

                        {/* 5:45 PM, Feb 24 */}
                      </p>
                    </div>
                  </>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
      <InternetConnectivity
        show={showInternetModal}
        onHide={() => setShowInternetModal(false)}
      />

      <DaysTrailModal show={wlcmModal} onHide={() => setWlcmModal(false)} />
      <Over7DaysTrialModal 
  show={shouldShowOver7DaysTrialModal()} 
  onHide={() => setShowOver7DaysTrialModal(false)} 
  data={data}
/>

    </>
  );
};

export default Header;
