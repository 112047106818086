import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import PatientCard from '../../components/PatientCard'
import { Row, Col } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { get, getAuthConfig } from '../../libs/http-hydrate'
import Auth from '../../libs/auth';
import SearchIcon from "../../assets/images/search.svg";
import FilterIcon from '../../assets/images/filter.svg';
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import Button from 'react-bootstrap/Button';

const PatientList = () => {
  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState({
    items: [],
    has_more: false
  })
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    searchTerm: "",
    title: ""
  })
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);

  const [bodyPartData, setBodyPart] = useState(
    {
      has_more: false,
      items: [{
        title: "Today",
      }, {
        title: "Upcoming"
      }, {
        title: "Monthly"
      }, {
        title: "Weekly"
      }],
    });

  useEffect(() => {
    if (page >= 1) {
      getPhysiotherapistLit()
    }

  }, [page])
  useEffect(() => {
    getPhysiotherapistLit();
    // organizeDataByTimeOfDay(readyData);
  }, [filter]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && patientList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [patientList?.has_more]
  );

  async function getPhysiotherapistLit() {

    try {
      setLoading(true);


      let queryParams = `pageNumber=${page}`;

      if (filter?.searchTerm) {
        queryParams += `&searchTerm=${filter?.searchTerm}`;
      }
      if (filter?.title) {
        queryParams += `&appointmentType=${filter?.title.toLowerCase()}`;
      }
      const data = await get(`/patientList?${queryParams}`, getAuthConfig());
      if (data.status === 200) {

        if (page > 0) {
          setPatientList((p) => ({ ...p, items: patientList?.items.concat(data?.data?.data) }));
        } else {
          setPatientList((p) => ({ ...p, items: data?.data?.data }));
        }
        setPatientList((p) => ({ ...p, has_more: data?.data?.has_more }))
      }

    } catch (error) {
      if (error.response.status === 401) {
        Auth.logout();
        navigate("/login")

      }

    }
    finally {
      setLoading(false);
    }
  }
  return (
    <Layout>
      <TopBar title={"All Patient"} tab={"Patient"} tabChild={"All Patient"} />
      <div className='main-content-part'>
        <div className="serchPart">
          <div className="searchInput">
            <div className="searchIcon">
              <img src={SearchIcon} alt="searchIcon" />
            </div>
            <input type="text" placeholder="Search patient" value={filter?.searchTerm} onChange={(e) => {
              e.preventDefault();
              setFilter((p) => ({ ...p, searchTerm: e?.target?.value }))
            }} />
            <div className="filterIcon" onClick={(e) => {
              e.preventDefault();
              handleShowFilter()
            }}>
              <img src={FilterIcon} alt="filterIcon" />
            </div>
          </div>
        </div>
        <Row className='g-4'>

        {loading && page === 0 ? (
            <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div>
          ) : 
          (
            <>
          {patientList?.items && patientList?.items?.length <= 0 && <div className="text-center">No Data found</div>}
          {patientList?.items && patientList?.items?.length > 0 &&
            patientList?.items?.map((item, index) => {
              return (
                <>
                  <Col xl="6" lg="12">

                  <div className='doctor-card'>
        <div className='doctor-card-content'>
            <div className='image-part d-flex justify-content-center align-content-center'>
            <img src={item?.profile ? item?.profile : PatientDummy}  crossOrigin="anonymous" height={100} alt='Patient' className='w-100 rounded-circle' />
            </div>
            <div className='text-part w-100 pe-4'>
                <h5>{item?.name}</h5>
                <p className='gray'>{item?.appoinmentFor}</p>
                  <p className='small'>Notes line</p>
                <Button className="componentBtn w-100 mt-3" >Assign Now</Button>
            </div>
        </div>
        </div>
                  </Col>
                </>)
            })
          }
          </>
          )
        }
          {loading && page !== 0 && (
            <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div>
          )}
        </Row>
      </div>
    </Layout>
  )
}

export default PatientList
