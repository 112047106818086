import React, { useEffect, useState, useRef } from "react";

import { Button, Col, Form, Nav, Row } from "react-bootstrap";

// import { makeStyles } from "@material-ui/core/styles";

import Geonames from "geonames.js";
import PropTypes from "prop-types";

const geonames = new Geonames({
    username: "thalesandrade",
    lan: "en",
    encoding: "JSON"
});



export default function GeoLocation(props) {
    const { locationTitle, geoId, onChange, isCountry } = props;
    const [options, setOptions] = useState([]);
    const [currentItem, setCurrentItem] = useState("");
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    useEffect(() => {
        try {
            const data = async () => {
                (await isCountry)
                    ? geonames.countryInfo({}).then(res => {
                        setOptions(res.geonames);
                    })
                    : geonames.children({ geonameId: geoId }).then(res => {
                        if (res.totalResultsCount) setOptions(res.geonames);
                    });
            };
            data();
        } catch (err) {
            console.error(err);
        }
    }, [geoId, isCountry]);

    const inputLabel = useRef(null);

  // Inside the handleChange function
const handleChange = e => {
    setCurrentItem(e.target.value);
    const selectedItem = options.find(option => option.geonameId == e.target.value);
    onChange({
        value: e.target.value,
        label: isCountry ? selectedItem?.countryName : selectedItem?.name
    });
};


    return (


        <>

            <Form.Select labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={currentItem}
                onChange={handleChange}
            >
                <option>-</option>


                {options.map((v, index) => (

                    <option key={index} value={v?.geonameId}>
                        {isCountry ? v?.countryName : v?.name}
                    </option>
                ))}

            </Form.Select>

        </>
    );
}

GeoLocation.propTypes = {
    locationTitle: PropTypes?.string,
    geoId: PropTypes?.node,
    isCountry: PropTypes?.bool,
    onChange: PropTypes?.func?.isRequired
};

GeoLocation.defaultProps = {
    onChange: undefined
};
