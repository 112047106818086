import { useState, useEffect, useRef, useCallback } from "react";
import React from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import SearchIcon from "../../assets/images/search.svg";
import { Row, Col, Button, Nav, Tab, Form, Collapse } from "react-bootstrap";
import "../../assets/css/foot.css";
import Ex1 from "../../assets/images/ex-1.png";
import Modal from "react-bootstrap/Modal";
import { useLocation, Link, useNavigate } from "react-router-dom";
import checkIcon from "../../assets/images/check (1).png";
import UploadIcon from "../../assets/images/upload.svg";
import FillStar from "../../assets/images/star.svg";
import Patient from "../../assets/images/patient.png";
import RightIconSvg from "../../assets/images/rightIconSVG.svg";
import ReactPlayer from "react-player";
import RemoveIcon from "../../assets/images/remove.png";
import PlayIcon from "../../assets/images/Play.svg";
import { deletes, get, getAuthConfig, post } from "../../libs/http-hydrate";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LikeComment from "../../assets/images/LikeCommunity.svg";
import LikeCommentFill from "../../assets/images/FillLikeDoctor.svg";
import FilterIcon from "../../assets/images/filter.svg";
import Auth from "../../libs/auth";
import ProfileCompletionModal from "../AddProfileData/ProfileCompletionModal";
import { AiOutlineArrowLeft } from "react-icons/ai"; // Importing back arrow icon from react-icons
import MyExerciseFill from "../../assets/images/myExerciseFill.svg";

const ExerciseLab = () => {
  const user = Auth.getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true); // New loading state
  const [selectedEx, setSelectedEx] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleOnReady = () => {
    setIsVideoLoaded(true);
  };
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);

  const [showDataModal, setshowDataModal] = useState(false);
  const handleCloseDataModal = () => setshowDataModal(false);
  const handleShowDataModal = () => setshowDataModal(true);

  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);

  const [showNote, setShowNote] = useState(false);
  const handleCloseNote = () => setShowNote(false);
  const handleShowNote = () => setShowNote(true);

  const [showSidebar, setShowSidebar] = useState(false);
  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const [showVideoModal, setshowVideoModal] = useState(false);
  const handleCloseVideoModal = () => setshowVideoModal(false);
  const handleShowVideoModal = () => setshowVideoModal(true);
  const [bodyPartData, setBodyPart] = useState({
    has_more: false,
    items: [],
  });
  let isCurrent = true; // Flag to track the liveness
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    body_part_id: "",
    title: "",
    searchTerm: "",
    patient_id: "",
  });
  const [cart, setCart] = useState([]);
  const [selectedMovementTypes, setSelectedMovementTypes] = useState({}); // Maintain selected movement types for each body part

  const [ExerciseList, setExerciseList] = useState({
    items: [],
    has_more: false,
  });
  // Create a state variable to keep track of checkbox statuses
  const [checkboxStatus, setCheckboxStatus] = useState(
    bodyPartData?.items.map(() => true) // Initialize all checkboxes as checked
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  UseEffectOnce(() => {
    getBodyPartData();
  }, []);
  useEffect(() => {
    if (bodyPartData?.items?.length > 0) {
      setFilter((p) => ({
        ...p,
        body_part_id:
          location?.state?.bodypartId || location?.state?.myExercise,
      }));
      handleAccordionItemClick(
        location?.state?.bodypartId || location?.state?.myExercise
      );
    }
  }, [location?.state?.bodypartId, bodyPartData?.items]);

  async function getBodyPartData() {
    try {
      const data = await get(
        `/bodyPartList?pageNumber=${page}`,
        getAuthConfig()
      );
      if (data?.status === 200) {
        let items = data?.data?.data;

        items = [
          { _id: "myExercise", title: "My Exercise", image: MyExerciseFill },
          ...items,
        ];

        setBodyPart((p) => ({ ...p, items, has_more: data?.data?.has_more }));

        // setBodyPart((p) => ({ ...p, items: data?.data?.data }))
        // setBodyPart((p) => ({ ...p, has_more: data?.data?.has_more }))
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }
  }
  async function getExerciseList() {
    setLoading(true); // Set loading to true before the API call

    try {
      const searchTerm = filter?.searchTerm || "";
      let queryParams = `pageNumber=${page}`;

      queryParams += `&searchTerm=${searchTerm}`;

      // Convert selectedMovementTypes into filterArray structure
      const filterArray = Object.entries(selectedMovementTypes)
        .filter(
          ([bodyPartId, movementTypes]) =>
            bodyPartId !== undefined && movementTypes != null
        ) // Ensure bodyPartId and movementTypes are defined
        .map(([bodyPartId, movementTypes]) => {
          // Filter out any undefined movement types
          const filteredMovementTypes = movementTypes.filter(
            (type) => type !== undefined
          );
          return {
            body_part_id: bodyPartId,
            movementType: filteredMovementTypes,
          };
        });

      // Add filterArray to the query parameters
      filterArray.forEach((filterItem, index) => {
        const bodyPartId = filterItem["body_part_id"];
        const movementTypes = filterItem["movementType"];

        if (bodyPartId && movementTypes.length > 0) {
          queryParams += `&filterArray[${index}][body_part_id]=${encodeURIComponent(
            bodyPartId
          )}`;
          movementTypes.forEach((movementType, subIndex) => {
            if (movementType) {
              // Ensure movementType is not undefined
              queryParams += `&filterArray[${index}][movementType][${subIndex}]=${encodeURIComponent(
                movementType
              )}`;
            }
          });
        }
      });

      if (
        filterArray.some((filter) => filter["body_part_id"] === "myExercise")
      ) {
        const myExercise = true; // or set it based on your specific condition
        queryParams += `&myExercise=${myExercise}`;
      }

      const data = await get(
        `/exerciseListNew?${queryParams}`,
        getAuthConfig()
      );
      if (isCurrent && data?.status === 200) {
        if (page > 0) {
          setExerciseList((p) => ({
            ...p,
            items: ExerciseList?.items?.concat(data?.data?.data),
            has_more: data?.data?.has_more,
          }));
        } else {
          setExerciseList((p) => ({
            ...p,
            items: data?.data?.data,
            has_more: data?.data?.has_more,
          }));
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    } finally {
      setTimeout(() =>{
        setLoading(false); // Set loading to false after the API calls
      },2000)
    }
  }
  const handleCheckboxChange = (bodyPartId, subcategory) => {
    const updatedSelectedMovementTypes = { ...selectedMovementTypes };

    if (!updatedSelectedMovementTypes[bodyPartId]) {
      updatedSelectedMovementTypes[bodyPartId] = [];
    }

    if (updatedSelectedMovementTypes[bodyPartId].includes(subcategory)) {
      // If already selected, unselect it
      updatedSelectedMovementTypes[bodyPartId] = updatedSelectedMovementTypes[
        bodyPartId
      ].filter((item) => item !== subcategory);
    } else {
      // If not selected, select it
      updatedSelectedMovementTypes[bodyPartId].push(subcategory);
    }

    setSelectedMovementTypes(updatedSelectedMovementTypes);

    // Check if the accordion item is open and add the bodyPartId to filter
    if (location?.state?.bodypartId === bodyPartId) {
      setFilter((p) => ({ ...p, body_part_id: bodyPartId }));
    }
    setPage(0);
  };

  // Function to handle "Clear All" button click
  const handleClearAll = (item) => {
    let updatedSelectedMovementTypes = { ...selectedMovementTypes };
    // If not selected, select it
    updatedSelectedMovementTypes[item?._id].splice(
      0,
      updatedSelectedMovementTypes[item?._id].length
    );
    setSelectedMovementTypes(updatedSelectedMovementTypes);
  };

  const addToCart = (item) => {
    // Check if the item is already in the cart based on its _id
    const isItemInCart = cart.some((cartItem) => cartItem._id === item._id);

    // If the item is not already in the cart, add it
    if (!isItemInCart) {
      setCart([...cart, item]);
    }
  };
  const removeFromCart = (itemToRemove) => {
    // Use filter to create a new cart array without the item to remove
    const updatedCart = cart.filter((item) => item._id !== itemToRemove._id);
    setCart(updatedCart);
  };

  const addToFavorites = async (exerciseId) => {
    try {
      const response = await post(
        "/addToFavorites",
        { exerciseId: exerciseId },
        getAuthConfig()
      );
      if (response.status === 200) {
        // Handle success, e.g., show a success message
        // toast.success("Exercise added to favorites successfully.");
        setExerciseList((prevList) => ({
          ...prevList,
          items: prevList.items.map((item) => {
            if (item._id === exerciseId) {
              return { ...item, _isFavorite: true };
            }
            return item;
          }),
        }));
      }
    } catch (err) {
      // Handle API request error, e.g., show an error message
      toast.error(err?.response?.data?.err);
    }
  };

  const RemoveFromFavorites = async (exerciseId) => {
    try {
      const response = await deletes(
        `/removeFromFavorites?exerciseId=${exerciseId}`
      );
      if (response.status === 200) {
        // Handle success, e.g., show a success message
        // toast.success("Exercise removed from favorites successfully.");
        setExerciseList((prevList) => ({
          ...prevList,
          items: prevList.items.map((item) => {
            if (item._id === exerciseId) {
              return { ...item, _isFavorite: false };
            }
            return item;
          }),
        }));
      }
    } catch (err) {
      // Handle API request error, e.g., show an error message
      toast.error(err?.response?.data?.err);
    }
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return; // Early return if loading is true
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && ExerciseList?.has_more) {
          if (page === 3) {
            if (!user?.profileCompleted) {
              setShowProfileCompletionModal(true);
            } else {
              setPage((prevPageNumber) => prevPageNumber + 1);
            }
          } else {
            setPage((prevPageNumber) => prevPageNumber + 1);
          }
        }
      });

      if (node) observer.current.observe(node);

      // Cleanup function
      return () => {
        if (observer.current) observer.current.disconnect();
      };
    },
    [page, ExerciseList.has_more, loading]
  );

  // Usage of lastBookElementRef in your JSX (e.g., in a div or another element)

  const handleAccordionItemClick = (item) => {
    setPage(0);
    // Update the selectedMovementTypes with the body part ID when the accordion item is clicked and open
    const updatedSelectedMovementTypes = { ...selectedMovementTypes };

    if (updatedSelectedMovementTypes[item]) {
      // If item is already present, remove it
      delete updatedSelectedMovementTypes[item];
    } else {
      // Initialize the subtypes as selected when a body part is selected
      updatedSelectedMovementTypes[item] = [];
      // Pre-select the subtypes
      const selectedItem = bodyPartData?.items.find((bp) => bp._id === item);
      if (selectedItem && selectedItem.movementTypes) {
        updatedSelectedMovementTypes[item] = selectedItem.movementTypes.slice();
      }
    }

    setSelectedMovementTypes(updatedSelectedMovementTypes);
  };

  useEffect(() => {
    if (location?.state?.cart) {
      setCart(location?.state?.cart);
    }
  }, [location?.state?.cart]);
  useEffect(() => {

    getExerciseList();
    return () => {
      isCurrent = false; // When the component unmounts or dependencies change, mark any ongoing operation as stale
    };
  }, [selectedMovementTypes, page, filter?.searchTerm]);

  return (
    <Layout>
      <ToastContainer />

      <div
        className="topBar"
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
      >
        <div className="theraphy-name d-flex align-items-center justify-content-between w-100 ">
          <div className="serchPart d-flex justify-content-start w-50 align-items-center ">
            <div
              className="back-button"
              style={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              <AiOutlineArrowLeft />
            </div>
            <div className="searchInput m-0">
              <div className="searchIcon" style={{ top: "8px", left: "15px" }}>
                <img src={SearchIcon} alt="searchIcon" height={20} width={20} />
              </div>
              <input
                type="text"
                placeholder="Search exercises"
                onChange={(e) => {
                  e.preventDefault();
                  setPage(0);
                  setFilter((p) => ({ ...p, searchTerm: e.target.value }));
                }}
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "14px",
                  paddingLeft: "40px",
                }}
              />
              {/* <div className='filterIcon' onClick={(e) => {
                                                // getBodyPartData();
                                                handleShowFilter();
                                            }}>
                                                <img src={FilterIcon} alt='filterIcon' />
                                            </div> */}
            </div>
            <div className="px-2">
              <button
                className="btn border mx-2  d-flex align-items-center "
                style={{
                  borderRadius: "46px",
                  fontSize: "14px",
                  width: "max-content",
                }}
                onClick={(e) => {
                  //  getBodyPartData();
                  handleShowFilter();
                }}
              >
                <img src={FilterIcon} alt="filterIcon" height={15} width={15} />
                &nbsp; Filter
              </button>
            </div>
          </div>
          {/* <h6 className="title ">{"props?.title"}</h6> */}
        </div>
        <div className="navigation">
          <label class="switch filterIcon">
            <input
              type="checkbox"
              id="toggleSwitch"
              checked={isChecked}
              onChange={handleToggle}
            />
            <span class="slider"> </span>

            <svg
              class={`${isChecked ? "activeIcon off-icon" : "off-icon"} `}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 17.998 17.993"
            >
              <g
                id="Group_3225"
                data-name="Group 3225"
                transform="translate(-9.353 -9.375)"
              >
                <path
                  id="Path_15658"
                  data-name="Path 15658"
                  d="M23.2,19.064a4.152,4.152,0,1,0,4.152,4.152A4.154,4.154,0,0,0,23.2,19.064Zm-5.541.692a.692.692,0,0,0-.692-.692H11.429A2.076,2.076,0,0,0,9.353,21.14v4.152a2.076,2.076,0,0,0,2.076,2.076h4.152a2.076,2.076,0,0,0,2.076-2.076Zm9.693-8.3a2.076,2.076,0,0,0-2.076-2.076H21.123a2.076,2.076,0,0,0-2.076,2.076v5.536a.692.692,0,0,0,.692.692h5.536A2.076,2.076,0,0,0,27.351,15.6Zm-9.693,0a2.076,2.076,0,0,0-2.076-2.076H11.429a2.076,2.076,0,0,0-2.076,2.076V15.6a2.076,2.076,0,0,0,2.076,2.076h5.536a.692.692,0,0,0,.692-.692Z"
                  fill="#ff6036"
                  fill-rule="evenodd"
                />
              </g>
            </svg>

            <svg
              class={`${!isChecked ? "activeIcon on-icon" : "on-icon"} `}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 21.67 17.994"
            >
              <g
                id="Layer_10"
                data-name="Layer 10"
                transform="translate(-3.173 -5.173)"
              >
                <path
                  id="Path_15659"
                  data-name="Path 15659"
                  d="M25.76,6.75H15.789a1.039,1.039,0,0,0,0,2.077H25.76a1.039,1.039,0,0,0,0-2.077Z"
                  transform="translate(-1.957 -0.267)"
                  fill="#fe6036"
                />
                <path
                  id="Path_15660"
                  data-name="Path 15660"
                  d="M15.789,12.827h6.648a1.039,1.039,0,1,0,0-2.077H15.789a1.039,1.039,0,0,0,0,2.077Z"
                  transform="translate(-1.957 -0.943)"
                  fill="#fe6036"
                />
                <path
                  id="Path_15661"
                  data-name="Path 15661"
                  d="M25.76,18.75H15.789a1.039,1.039,0,1,0,0,2.077H25.76a1.039,1.039,0,1,0,0-2.077Z"
                  transform="translate(-1.957 -2.295)"
                  fill="#fe6036"
                />
                <path
                  id="Path_15662"
                  data-name="Path 15662"
                  d="M22.436,22.75H15.789a1.039,1.039,0,1,0,0,2.077h6.648a1.039,1.039,0,1,0,0-2.077Z"
                  transform="translate(-1.957 -2.971)"
                  fill="#fe6036"
                />
                <path
                  id="Path_15663"
                  data-name="Path 15663"
                  d="M6.009,12.709a1.663,1.663,0,0,0,2.35,0l2.35-2.35a1.663,1.663,0,0,0,0-2.35l-2.35-2.35a1.664,1.664,0,0,0-2.35,0l-2.35,2.35a1.663,1.663,0,0,0,0,2.35Z"
                  transform="translate(0 0)"
                  fill="#fe6036"
                />
                <path
                  id="Path_15664"
                  data-name="Path 15664"
                  d="M3.659,22.359l2.35,2.35a1.663,1.663,0,0,0,2.35,0l2.35-2.35a1.663,1.663,0,0,0,0-2.35l-2.35-2.35a1.664,1.664,0,0,0-2.35,0l-2.35,2.35a1.663,1.663,0,0,0,0,2.35Z"
                  transform="translate(0 -2.028)"
                  fill="#fe6036"
                />
              </g>
            </svg>
          </label>
        </div>
      </div>
      {/* <TopBar title={location?.state?.bodyPart} tab={"Physiotherapist"} tabChild={"BodyPart"}  tabSmallChild={"Exercise"} /> */}

      <div className="main-content-part foot-page p-0" defaultActiveKey="first">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col xl={3} lg={4} className="d-none d-lg-block">
              <div className="foot-sidebar p-0 position-sticky scrollable-column">
                <div className="accordion w-100" id="regularAccordionRobots">
                  {bodyPartData?.items.map((item, index) => (
                    <div
                      key={index}
                      className={`accordion-item ${
                        location?.state?.bodypartId === item.id ? "active" : ""
                      }`}
                    >
                      <h2 className="accordion-header">
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ marginLeft: "10px" }}
                        >
                          <div
                            className="d-flex align-items-center checkbox w-100 "
                            style={{ cursor: "pointer" }}
                            //onClick={() => handleAccordionItemClick(item?._id)}
                          >
                            {" "}
                            {/* Add a div for alignment */}
                            <input
                              type="checkbox"
                              id={`checkbox_item${item._id}`} // Use item._id or a unique identifier
                              checked={
                                selectedMovementTypes[item?._id] ? true : false
                              }
                              onChange={() => handleAccordionItemClick(item?._id)}
                            />
                            <label for={`checkbox_item${item._id}`}></label>
                            <span
                              className={`ms-3  ${
                                selectedMovementTypes[item?._id]
                                  ? "the-color"
                                  : ""
                              }`}
                              onClick={() =>
                                handleAccordionItemClick(item?._id)
                              }
                            >
                              {" "}
                              {/* Add some margin to the text */}
                              {item.title}
                            </span>
                          </div>
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#regularCollapse${index}`}
                            aria-expanded={
                              location?.state?.bodypartId === item._id
                            }
                            // onClick={() => handleAccordionItemClick(item?._id)}
                          ></button>
                        </div>
                      </h2>
                      <div
                        id={`regularCollapse${index}`}
                        className={`accordion-collapse collapse ${
                          location?.state?.bodypartId === item._id ? "show" : ""
                        }`}
                      >
                        <div className="accordion-body pt-0">
                          {/* {item?.movementTypes.length > 0 &&
                                                        <h6 style={{ color: "#FF6036", cursor: "pointer" }} onClick={(e) => {
                                                            handleClearAll(item)
                                                        }}>
                                                          
                                                            Clear All
                                                        </h6> } */}
                          <Nav variant="pills" className="flex-column">
                            {item?.movementTypes?.map(
                              (subcategory, subIndex) => (
                                <Nav.Item
                                  key={subIndex}
                                  className="d-flex justify-content-start align-items-center"
                                >
                                  <div className="checkbox me-2">
                                    <input
                                      type="checkbox"
                                      id={`checkbox_it${item?._id + subIndex}`}
                                      checked={selectedMovementTypes[
                                        item?._id
                                      ]?.includes(subcategory)}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          item?._id,
                                          subcategory
                                        )
                                      } // Handle checkbox changes
                                    />
                                    <label
                                      htmlFor={`checkbox_it${
                                        item?._id + subIndex
                                      }`}
                                    ></label>
                                  </div>
                                  <Nav.Link eventKey={`sub-${subIndex}`}>
                                    {subcategory}
                                  </Nav.Link>
                                </Nav.Item>
                              )
                            )}
                          </Nav>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col xl={9} lg={8} sm={12}>
              <div className="content-data pt-2 pl-1 pr-1 scrollable-column">
                {/* <div className='serchPart'>
                                    <div className='searchInput'>
                                        <div className='searchIcon'>
                                            <img src={SearchIcon} alt='searchIcon' />
                                        </div>
                                        <input type='text' placeholder='Search exercises' onChange={(e) => {
                                            e.preventDefault();
                                            setPage(0)
                                            setFilter((p) => ({ ...p, searchTerm: e.target.value }))
                                        }} />
                                          <div className='filterIcon' onClick={(e) => {
                                                // getBodyPartData();
                                                handleShowFilter();
                                            }}>
                                                <img src={FilterIcon} alt='filterIcon' />
                                            </div>
                                    </div>
                                </div> */}

                <Row>
                  {loading && page === 0 ? (
                    <div className="preloader-whirlpool text-center align-items-center">
                      <div className="whirlpool"></div>
                    </div>
                  ) : (
                    <>
                      {ExerciseList?.items &&
                        ExerciseList?.items?.length <= 0 && (
                          <div className="text-center">No Exercise Found</div>
                        )}

                      {isChecked &&
                        ExerciseList?.items &&
                        ExerciseList?.items?.length > 0 &&
                        ExerciseList?.items?.map((item, index) => {
                          return (
                            <>
                              <Col
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                ref={lastBookElementRef}
                              >
                                <div className="exercise-card mb-2 mt-1 mx-1">
                                  <div className="img-text-part">
                                    <div className="img-block">
                                      <img
                                        src={item?.exerciseImage}
                                        height={70}
                                        width={105}
                                        alt="ex-1"
                                      />
                                    </div>
                                    <div className="text-part ps-3">
                                      <h6
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedEx(item);
                                          handleShowDataModal();
                                        }}
                                      >
                                        {item?.title}
                                      </h6>
                                      <p style={{ cursor: "pointer" }}>
                                        {item?.set} Reps
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between ">
                                    <img
                                      className="mx-2"
                                      src={PlayIcon}
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedEx(item);
                                        handleShowDataModal();
                                      }}
                                    />
                                    {item?._isFavorite === true ? (
                                      <img
                                        className="mx-2"
                                        src={LikeCommentFill}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          RemoveFromFavorites(item?._id);
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="mx-2"
                                        src={LikeComment}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          addToFavorites(item?._id);
                                        }}
                                      />
                                    )}
                                    <Button
                                      className={
                                        cart.some(
                                          (cartItem) =>
                                            cartItem._id === item._id
                                        )
                                          ? "selectedExeBtn d-block text-center mx-2"
                                          : "theme-outline-btn d-block text-center mx-2"
                                      }
                                      style={{
                                        backgroundColor: "#FDF5F2",
                                        borderRadius: "7px",
                                        borderColor: "#FDF5F2",
                                        minWidth: "30px",
                                        maxWidth: "30px",
                                        fontSize: "20px",
                                        lineHeight: "30px",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "0",
                                        border: "1px solid #ff603600",
                                      }}
                                      onClick={() => {
                                        const isItemInCart = cart.some(
                                          (cartItem) =>
                                            cartItem._id === item._id
                                        );
                                        if (isItemInCart) {
                                          removeFromCart(item);
                                        } else {
                                          addToCart(item);
                                        }
                                      }}
                                    >
                                      {" "}
                                      {cart.some(
                                        (cartItem) => cartItem._id === item._id
                                      )
                                        ? "✓"
                                        : // Render the '+' sign when the item is not in the cart
                                          "+"}{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </>
                          );
                        })}

                      {!isChecked &&
                        ExerciseList?.items &&
                        ExerciseList?.items?.length > 0 &&
                        ExerciseList?.items?.map((item, index) => {
                          return (
                            <>
                              <Col
                                xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                ref={lastBookElementRef}
                              >
                                <div className="assign-doctor-card p-0 px-3 pb-3">
                                  <div className="doctor-card-content">
                                    <div className="image-part py-3 ">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedEx(item);
                                          handleShowDataModal();
                                        }}
                                      >
                                        <div className="box-video">
                                          <div
                                            className="bg-video"
                                            style={{
                                              "background-image":
                                                "url(https://unsplash.imgix.net/photo-1425036458755-dc303a604201?fit=crop&fm=jpg&q=75&w=1000);",
                                            }}
                                          >
                                            <div className="bt-play"></div>
                                          </div>
                                          <div className="video-container">
                                            <img
                                              className="img-fluid"
                                              src={item?.exerciseImage}
                                              width="200px"
                                              height="200px"
                                              style={{ borderRadius: "14px" }}
                                            />
                                            {/* <ReactPlayer
                                                                                    url={item?.videoLink}
                                                                                    width="100%"
                                                                                    height="200px"
                                                                                    style={{ borderRadius: "14px" }}
                                                                                // controls
                                                                                /> */}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <VideoPlayer item={item} /> */}

                                      {/* <img src={Patient} height="200px" width={"100%"}  style={{borderRadius:"25%"}} alt='Patient' /> */}
                                    </div>
                                    <div
                                      className="text-part text-start "
                                      style={{
                                        cursor: "pointer",
                                        marginBottom: "0px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedEx(item);
                                        handleShowDataModal();
                                      }}
                                    >
                                      <span
                                        className="exerciseLabfont name p-1"
                                        style={{
                                          display: "inline-block",
                                          textAlign: "left",
                                          lineHeight: "1.5",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {item?.title}
                                      </span>
                                      <br />
                                      {/* <span className='exerciseLabfontSUB name p-1'>    {item?.body_part_id &&
                  item?.body_part_id?.length > 0 &&
                  item?.body_part_id.map((itemEq, ind) => (
                    <React.Fragment key={ind}>
                      {ind > 0 && " - "}
                      {itemEq?.title}
                    </React.Fragment>
                  ))}</span> */}
                                    </div>

                                    <div className="divi pt-2 pb-2">
                                      <span className="divider"></span>
                                    </div>

                                    <div className="d-flex justify-content-between ">
                                      {item?._isFavorite === true ? (
                                        <img
                                          src={LikeCommentFill}
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            RemoveFromFavorites(item?._id);
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={LikeComment}
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            addToFavorites(item?._id);
                                          }}
                                        />
                                      )}

                                      <Button
                                        className={
                                          cart.some(
                                            (cartItem) =>
                                              cartItem._id === item._id
                                          )
                                            ? "selectedExeBtn d-block text-center"
                                            : "theme-outline-btn d-block text-center"
                                        }
                                        style={{
                                          backgroundColor: "#FDF5F2",
                                          borderRadius: "7px",
                                          borderColor: "#FDF5F2",
                                          minWidth: "30px",
                                          maxWidth: "30px",
                                          fontSize: "20px",
                                          lineHeight: "30px",
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          padding: "0",
                                          border: "1px solid #ff603600",
                                        }}
                                        onClick={() => {
                                          const isItemInCart = cart.some(
                                            (cartItem) =>
                                              cartItem._id === item._id
                                          );
                                          if (isItemInCart) {
                                            removeFromCart(item);
                                          } else {
                                            addToCart(item);
                                          }
                                        }}
                                      >
                                        {" "}
                                        {cart.some(
                                          (cartItem) =>
                                            cartItem._id === item._id
                                        )
                                          ? "✓"
                                          : // Render the '+' sign when the item is not in the cart
                                            "+"}{" "}
                                      </Button>
                                      {/* <Button className={cart.some(cartItem => cartItem._id === item._id) ? 'selectedExeBtn d-block py-1': 'theme-outline-btn d-block py-1 '} style={{ backgroundColor: '#FDF5F2', borderRadius: "7px", borderColor: '#FDF5F2', minWidth: "fit-content", fontSize: "24px" }}

                                                                        onClick={() => {
                                                                            
                                                                            const isItemInCart = cart.some(cartItem => cartItem._id === item._id);
                                                                            if (isItemInCart) {
                                                                                removeFromCart(item);
                                                                            } else {
                                                                            addToCart(item)
                                                                            }
                                                                        
                                                                        }}

                                                                    >  {cart.some((cartItem) => cartItem._id === item._id) ? (
                                                                            <img src={RightIconSvg} />
                                                                    ) : (
                                                                        // Render the '+' sign when the item is not in the cart
                                                                        '+'
                                                                    )} </Button> */}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </>
                          );
                        })}
                    </>
                  )}
                  {loading && page > 0 && (
                    <div className="preloader-whirlpool text-center align-items-center">
                      <div className="whirlpool"></div>
                    </div>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      <div
        className="center-div p-2 text-center"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          setShowSidebar(true);
        }}
      >
        <Nav variant="pills" className="flex-column">
          <Nav.Item className="d-flex justify-content-start align-items-center">
            <div className="checkbox me-2">
              <input type="checkbox" id="checkbox_6" checked />
              <label for="checkbox_6"></label>
            </div>
            <Nav.Link eventKey="first" style={{ fontWeight: "500" }}>
              Excersices
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <h1 style={{ color: "#FF6036" }}>
          {cart.length < 10 ? `0${cart.length}` : cart.length}
        </h1>
      </div>
      {/* Exercises Counter modal */}
      <ProfileCompletionModal
        show={showProfileCompletionModal}
        onHide={() => setShowProfileCompletionModal(false)}
        From={"Exercise Library"}
      />
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Exercises Counter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="body-content">
            <div className="form-data w-100" style={{ maxWidth: "100%" }}>
              <div className="filed">
                <label>Exercises Count </label>
                <input type="text" className="input-field" placeholder="05" />
              </div>
              <div className="filed">
                <label>Exercises Set</label>
                <Form.Select
                  aria-label="Default select example"
                  className="mb-3"
                >
                  <option>03</option>
                  <option value="1">02</option>
                  <option value="2">03</option>
                  <option value="3">04</option>
                </Form.Select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="theme-button"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleShowFilter();
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Filter modal */}
      <Modal show={showFilter} onHide={handleCloseFilter} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="body-content">
            <ul className="filterList">
              {bodyPartData?.items &&
                bodyPartData?.items.length > 0 &&
                bodyPartData.items.map((item, index) => (
                  <li
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      handleAccordionItemClick(item?._id);
                      // setFilter((prevFilter) => ({
                      //     ...prevFilter,
                      //     body_part_id: item?._id,
                      // }));
                    }}
                  >
                    <a>
                      {item?.title}

                      {selectedMovementTypes[item?._id] && (
                        <div className="select-img">
                          <img
                            src={checkIcon}
                            alt="checkIcon"
                            style={{ display: "block" }}
                          />
                        </div>
                      )}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="theme-button"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFilter();
              // handleShowNote()
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Note Filter  */}
      <Modal show={showNote} onHide={handleCloseNote} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="body-title">Information Note</p>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Write textā€¦"
              ></textarea>
            </div>
            <p className="body-title">Add Reports</p>
            <div className="upload-part">
              <div className="form-group file-area">
                <input
                  type="file"
                  name="images"
                  id="images"
                  required="required"
                  multiple="multiple"
                />
                <div className="file-dummy">
                  <div className="success">
                    Great, your files are selected. Keep on.
                  </div>
                  <div className="default">
                    <div className="image-block mb-2">
                      <img src={UploadIcon} alt="icon" />
                    </div>
                    Add report here (png, jpg, pdf)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to={"/excersiceList"}>
            {" "}
            <Button className="theme-button">Next</Button>
          </Link>
          <Link to="/" className="footerLink">
            Skip for now
          </Link>
        </Modal.Footer>
      </Modal>

      {/* Add Note Filter  */}
      <Modal
        show={showSidebar}
        onHide={handleCloseSidebar}
        className="modal-sidebar"
        size="md"
      >
        <Modal.Header>
          <Modal.Title className="text-start">
            <div className="d-flex justify-content-between w-100">
              <Nav variant="pills" className="flex-column">
                <Nav.Item className="d-flex justify-content-start align-items-center">
                 
                  <Nav.Link eventKey="first" style={{paddingLeft:"0px"}}>
                    Exercises (
                    {cart.length < 10 ? `0${cart.length}` : cart.length})
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <button
                type="button"
                className="btn-close mt-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseSidebar();
                }}
              ></button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => {
                return (
                  <>
                    <div
                      className="exercise-card mb-3"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="img-text-part">
                        <div className="img-block">
                          <img
                            src={item?.exerciseImage}
                            height={130}
                            width={195}
                            style={{ borderRadius: "10%" }}
                            alt="ex-1"
                          />
                        </div>
                        <div className="text-part ps-3">
                          <h6>{item?.title}</h6>
                          <p style={{ cursor: "pointer" }}>
                            {item?.body_part_id &&
                              item?.body_part_id?.length > 0 &&
                              item?.body_part_id.map((itemEq, ind) => (
                                <React.Fragment key={ind}>
                                  {ind > 0 && " - "}
                                  {itemEq?.title}
                                </React.Fragment>
                              ))}{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className="checkbox me-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFromCart(item)}
                      >
                        <svg
                          width="30"
                          height="30"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="5"
                            y1="5"
                            x2="25"
                            y2="25"
                            stroke="#ADADAD"
                            stroke-width="2"
                          />
                          <line
                            x1="25"
                            y1="5"
                            x2="5"
                            y2="25"
                            stroke="#ADADAD"
                            stroke-width="2"
                          />
                        </svg>
                      </div>
                    </div>

                    <hr />
                  </>
                );
              })}

            {/* <div className='exercise-card mb-3' style={{ boxShadow: "none" }}>
                            <div className='img-text-part'>
                                <div className='img-block'>
                                    <img src={Ex1} height={130} width={180} alt='ex-1' />
                                </div>
                                <div className='text-part ps-3'>

                                    <h6>LOWER TRUNK ROTATION</h6>
                                    <p style={{ cursor: "pointer" }} >LTR - WIG - WAGS - KNEE ROCKS </p>

                                </div>
                            </div>
                            <div className="checkbox me-2" style={{ cursor: "pointer" }} >
                                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="5" y1="5" x2="25" y2="25" stroke="#ADADAD" stroke-width="2" />
                                    <line x1="25" y1="5" x2="5" y2="25" stroke="#ADADAD" stroke-width="2" />
                                </svg>



                            </div>
                        </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {cart.length > 0 && (
            <Link
              to={"/createExercise"}
              state={{
                routine: location?.state?.routine,
                cart: cart,
                bodyPart: location?.state?.bodyPart,
                bodyPartImage: location?.state?.bodyPartImage,
                patient_id: location?.state?.patient_id,
                patientDetail: location?.state?.patientDetail,
              }}
            >
              {" "}
              <Button className="theme-button">Done</Button>
            </Link>
          )}
        </Modal.Footer>
      </Modal>

      {/* Filter modal */}
      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        className="p-0 m-0 modal-content-p0"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="ml-5 withoutBorder" />

        <Modal.Body className="p-0 m-0">
          <ReactPlayer
            url={selectedEx?.videoLink}
            width="100%"
            height="400px"
            style={{ borderRadius: "14px" }}
            controls
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showDataModal}
        onHide={handleCloseDataModal}
        className=""
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ justifyContent: "flex-start" }}>
          <div className="w-1000 text-start">
            <span className="exerciseLabfont name p-1">
              {selectedEx?.title}
            </span>
            <br />
          </div>
        </Modal.Header>

        <Modal.Body className="mt-3">
          {!isVideoLoaded && (
            <div className="preloader-whirlpool text-center align-items-center">
              <div className="whirlpool"></div>
            </div>
          )}
          <ReactPlayer
            url={selectedEx?.videoLink}
            width="100%"
            height="250px"
            style={{
              borderRadius: "14px",
              display: isVideoLoaded ? "block" : "none",
            }}
            controls
            onReady={handleOnReady}
          />
          <div className="text-part text-start mt-2">
            <div className="info-section">
              <span className="label m-2">Equipment:</span>
              <span className="data m-2">
                {selectedEx?.equipment && selectedEx?.equipment?.length > 0
                  ? selectedEx.equipment.map((equip, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && " , "}
                        {equip}
                      </React.Fragment>
                    ))
                  : "No Equipment"}
              </span>
            </div>

            <div className="info-section">
              <span className="label m-2">BodyPart:</span>
              <span className="data m-2">
                {selectedEx?.body_part_id &&
                  selectedEx?.body_part_id?.length > 0 &&
                  selectedEx?.body_part_id.map((itemEq, ind) => (
                    <React.Fragment key={ind}>
                      {ind > 0 && " , "}
                      {itemEq?.title}
                    </React.Fragment>
                  ))}
              </span>
            </div>

            <div className="info-section">
              <span className="label m-2">Description:</span>
              <div className="data m-2">
                {selectedEx?.description &&
                  selectedEx?.description
                    .split(".")
                    .filter((sentence) => sentence.trim() !== "")
                    .map((sentence, index) => (
                      <React.Fragment key={index}>
                        <span className="">{index + 1}.</span>
                        <span className="exerciseLabfontSUB name p-1 pb-2">
                          {sentence.trim()}.
                        </span>
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className="theme-button btn btn-primary"
              style={{ "min-width": "300px", padding: "14px" }}
              onClick={(e) => {
                e.preventDefault();
                handleCloseDataModal();
              }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default ExerciseLab;
